@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700;800&display=swap");
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.bg-primary {
  background-color: #5d87ff !important;
}

.text-primary {
  color: #5d87ff !important;
}

.bg-accent {
  background-color: #49beff !important;
}

.text-accent {
  color: #49beff !important;
}

.bg-success {
  background-color: #13deb9 !important;
}

.text-success {
  color: #13deb9 !important;
}

.bg-warning {
  background-color: #ffae1f !important;
}

.text-warning {
  color: #ffae1f !important;
}

.bg-error {
  background-color: #fa896b !important;
}

.text-error {
  color: #fa896b !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.text-white {
  color: #ffffff !important;
}

.bg-light {
  background-color: #f2f6fa !important;
}

.text-light {
  color: #f2f6fa !important;
}

.bg-gray-100 {
  background-color: #7c8fac !important;
}

.text-gray-100 {
  color: #7c8fac !important;
}

.bg-light-error {
  background-color: #fdede8 !important;
}

.text-light-error {
  color: #fdede8 !important;
}

.bg-light-accent {
  background-color: #e8f7ff !important;
}

.text-light-accent {
  color: #e8f7ff !important;
}

.bg-light-success {
  background-color: #e6fffa !important;
}

.text-light-success {
  color: #e6fffa !important;
}

.bg-light-warning {
  background-color: #fef5e5 !important;
}

.text-light-warning {
  color: #fef5e5 !important;
}

.bg-light-primary {
  background-color: #ecf2ff !important;
}

.text-light-primary {
  color: #ecf2ff !important;
}

.fill-warning svg {
  fill: #ffae1f;
  color: #ffae1f;
}

.fill-light svg {
  fill: #f2f6fa;
  color: #f2f6fa;
}

.icon-1 {
  height: 1px !important;
  width: 1px !important;
}

.icon-2 {
  height: 2px !important;
  width: 2px !important;
}

.icon-3 {
  height: 3px !important;
  width: 3px !important;
}

.icon-4 {
  height: 4px !important;
  width: 4px !important;
}

.icon-5 {
  height: 5px !important;
  width: 5px !important;
}

.icon-6 {
  height: 6px !important;
  width: 6px !important;
}

.icon-7 {
  height: 7px !important;
  width: 7px !important;
}

.icon-8 {
  height: 8px !important;
  width: 8px !important;
}

.icon-9 {
  height: 9px !important;
  width: 9px !important;
}

.icon-10 {
  height: 10px !important;
  width: 10px !important;
}

.icon-11 {
  height: 11px !important;
  width: 11px !important;
}

.icon-12 {
  height: 12px !important;
  width: 12px !important;
}

.icon-13 {
  height: 13px !important;
  width: 13px !important;
}

.icon-14 {
  height: 14px !important;
  width: 14px !important;
}

.icon-15 {
  height: 15px !important;
  width: 15px !important;
}

.icon-16 {
  height: 16px !important;
  width: 16px !important;
}

.icon-17 {
  height: 17px !important;
  width: 17px !important;
}

.icon-18 {
  height: 18px !important;
  width: 18px !important;
}

.icon-19 {
  height: 19px !important;
  width: 19px !important;
}

.icon-20 {
  height: 20px !important;
  width: 20px !important;
}

.icon-21 {
  height: 21px !important;
  width: 21px !important;
}

.icon-22 {
  height: 22px !important;
  width: 22px !important;
}

.icon-23 {
  height: 23px !important;
  width: 23px !important;
}

.icon-24 {
  height: 24px !important;
  width: 24px !important;
}

.icon-25 {
  height: 25px !important;
  width: 25px !important;
}

.icon-26 {
  height: 26px !important;
  width: 26px !important;
}

.icon-27 {
  height: 27px !important;
  width: 27px !important;
}

.icon-28 {
  height: 28px !important;
  width: 28px !important;
}

.icon-29 {
  height: 29px !important;
  width: 29px !important;
}

.icon-30 {
  height: 30px !important;
  width: 30px !important;
}

.icon-31 {
  height: 31px !important;
  width: 31px !important;
}

.icon-32 {
  height: 32px !important;
  width: 32px !important;
}

.icon-33 {
  height: 33px !important;
  width: 33px !important;
}

.icon-34 {
  height: 34px !important;
  width: 34px !important;
}

.icon-35 {
  height: 35px !important;
  width: 35px !important;
}

.icon-36 {
  height: 36px !important;
  width: 36px !important;
}

.icon-37 {
  height: 37px !important;
  width: 37px !important;
}

.icon-38 {
  height: 38px !important;
  width: 38px !important;
}

.icon-39 {
  height: 39px !important;
  width: 39px !important;
}

.icon-40 {
  height: 40px !important;
  width: 40px !important;
}

.icon-41 {
  height: 41px !important;
  width: 41px !important;
}

.icon-42 {
  height: 42px !important;
  width: 42px !important;
}

.icon-43 {
  height: 43px !important;
  width: 43px !important;
}

.icon-44 {
  height: 44px !important;
  width: 44px !important;
}

.icon-45 {
  height: 45px !important;
  width: 45px !important;
}

.icon-46 {
  height: 46px !important;
  width: 46px !important;
}

.icon-47 {
  height: 47px !important;
  width: 47px !important;
}

.icon-48 {
  height: 48px !important;
  width: 48px !important;
}

.bg-primary {
  background: #5d87ff !important;
}

.horizontal-navbar .parentBox.pactive > a,
.horizontal-navbar .parentBox.pactive > a:hover,
.horizontal-navbar .parentBox.pactive:hover > a {
  background-color: #5d87ff !important;
}

.activeMenu {
  background-color: #5d87ff !important;
}

.mat-mdc-outlined-button.mat-primary {
  border-color: #5d87ff !important;
}
.mat-mdc-outlined-button.mat-accent {
  border-color: #44b7f7 !important;
}
.mat-mdc-outlined-button.mat-warn {
  border-color: #fa896b !important;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-option:hover:not(.mdc-list-item--disabled), .mat-mdc-option:focus.mdc-list-item, .mat-mdc-option.mat-mdc-option-active.mdc-list-item, .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #5d87ff);
}

.mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #44b7f7);
}

.mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #fa896b);
}

.mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #5d87ff;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #5d87ff;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #44b7f7;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #44b7f7;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #44b7f7;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #44b7f7;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #fa896b;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #fa896b;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-mdc-option {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Plus Jakarta Sans));
  font-size: var(--mdc-typography-body1-font-size, 14px);
  line-height: var(--mdc-typography-body1-line-height, 20px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, normal);
}

.mat-mdc-card {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-card {
  --mat-card-title-text-font: Plus Jakarta Sans;
  --mat-card-title-text-line-height: 26px;
  --mat-card-title-text-size: 18px;
  --mat-card-title-text-tracking: normal;
  --mat-card-title-text-weight: 600;
  --mat-card-subtitle-text-font: Plus Jakarta Sans;
  --mat-card-subtitle-text-line-height: 14px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: normal;
  --mat-card-subtitle-text-weight: 400;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #5d87ff;
  --mdc-linear-progress-track-color: rgba(93, 135, 255, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(93, 135, 255, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(93, 135, 255, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(93, 135, 255, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(93, 135, 255, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(93, 135, 255, 0.25));
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #44b7f7;
  --mdc-linear-progress-track-color: rgba(68, 183, 247, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(68, 183, 247, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(68, 183, 247, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(68, 183, 247, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(68, 183, 247, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(68, 183, 247, 0.25));
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #fa896b;
  --mdc-linear-progress-track-color: rgba(250, 137, 107, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(250, 137, 107, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(250, 137, 107, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(250, 137, 107, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(250, 137, 107, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(250, 137, 107, 0.25));
}
.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-font: Plus Jakarta Sans;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
.mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #5d87ff);
}
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field--filled .mdc-text-field__ripple::before,
.mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.mdc-text-field--filled:hover .mdc-text-field__ripple::before, .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #5d87ff);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #5d87ff);
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mdc-text-field--outlined .mdc-text-field__ripple::before,
.mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(93, 135, 255, 0.87);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #fa896b);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #fa896b);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #fa896b);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #fa896b);
}
.mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #fa896b);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #fa896b);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #fa896b);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #fa896b);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #fa896b);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #fa896b);
}
.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field-character-counter,
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-text-field-character-counter,
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
  .mdc-text-field--disabled .mdc-notched-outline__leading,
  .mdc-text-field--disabled .mdc-notched-outline__notch,
  .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #fa896b);
}

.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(93, 135, 255, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(68, 183, 247, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(250, 137, 107, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #44b7f7);
}
.mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #44b7f7);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(68, 183, 247, 0.87);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #fa896b);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #fa896b);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #fa896b);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #fa896b);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #fa896b);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #fa896b);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #fa896b);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #fa896b);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #fa896b);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #fa896b);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #44b7f7);
}

.mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #fa896b);
}
.mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #fa896b);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(250, 137, 107, 0.87);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #fa896b);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #fa896b);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #fa896b);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #fa896b);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #fa896b);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #fa896b);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #fa896b);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #fa896b);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #fa896b);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #fa896b);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #fa896b);
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}

[dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}

.mat-mdc-form-field-infix {
  min-height: 56px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mdc-text-field__input {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Plus Jakarta Sans));
  font-size: var(--mdc-typography-subtitle1-font-size, 21px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 500);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
.mdc-text-field__affix {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Plus Jakarta Sans));
  font-size: var(--mdc-typography-subtitle1-font-size, 21px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 500);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mdc-text-field--textarea .mdc-text-field__input {
  line-height: 1.5rem;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Plus Jakarta Sans));
  font-size: var(--mdc-typography-subtitle1-font-size, 21px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 500);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}

.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Plus Jakarta Sans));
  font-size: var(--mdc-typography-caption-font-size, 12px);
  line-height: var(--mdc-typography-caption-line-height, 20px);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}

.mat-mdc-form-field,
.mat-mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Plus Jakarta Sans));
  font-size: var(--mdc-typography-body1-font-size, 14px);
  line-height: var(--mdc-typography-body1-line-height, 20px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: calc(14px * var(--mat-mdc-form-field-floating-label-scale, 0.75));
}
.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 14px;
}

.mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #5d87ff);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #5d87ff);
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-divider::after {
  border-bottom-color: white;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(93, 135, 255, 0.87);
}
.mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(68, 183, 247, 0.87);
}
.mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(250, 137, 107, 0.87);
}
.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(250, 137, 107, 0.87);
}
.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-select-panel {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Plus Jakarta Sans));
  font-size: var(--mdc-typography-subtitle1-font-size, 21px);
  line-height: var(--mdc-typography-subtitle1-line-height, 26px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 500);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 20px;
}

.mat-mdc-select {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Plus Jakarta Sans));
  font-size: var(--mdc-typography-body1-font-size, 14px);
  line-height: var(--mdc-typography-body1-line-height, 20px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #5d87ff);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #5d87ff);
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-divider::after {
  border-bottom-color: white;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-autocomplete-panel {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Plus Jakarta Sans));
  font-size: var(--mdc-typography-subtitle1-font-size, 21px);
  line-height: var(--mdc-typography-subtitle1-line-height, 26px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 500);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 20px;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: Plus Jakarta Sans;
  --mdc-dialog-subhead-line-height: 26px;
  --mdc-dialog-subhead-size: 18px;
  --mdc-dialog-subhead-weight: 600;
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-supporting-text-font: Plus Jakarta Sans;
  --mdc-dialog-supporting-text-line-height: 20px;
  --mdc-dialog-supporting-text-size: 14px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: normal;
}

.mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #5d87ff;
  --mdc-chip-elevated-disabled-container-color: #5d87ff;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #44b7f7;
  --mdc-chip-elevated-disabled-container-color: #44b7f7;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #fa896b;
  --mdc-chip-elevated-disabled-container-color: #fa896b;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}

.mat-mdc-chip-focus-overlay {
  background: black;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  height: 32px;
}

.mat-mdc-standard-chip {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Plus Jakarta Sans));
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: var(--mdc-typography-body2-line-height, 22px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Plus Jakarta Sans));
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: var(--mdc-typography-body2-line-height, 22px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #5d87ff;
  --mdc-radio-selected-hover-icon-color: #5d87ff;
  --mdc-radio-selected-icon-color: #5d87ff;
  --mdc-radio-selected-pressed-icon-color: #5d87ff;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #5d87ff;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #44b7f7;
  --mdc-radio-selected-hover-icon-color: #44b7f7;
  --mdc-radio-selected-icon-color: #44b7f7;
  --mdc-radio-selected-pressed-icon-color: #44b7f7;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #44b7f7;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #fa896b;
  --mdc-radio-selected-hover-icon-color: #fa896b;
  --mdc-radio-selected-icon-color: #fa896b;
  --mdc-radio-selected-pressed-icon-color: #fa896b;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #fa896b;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Plus Jakarta Sans));
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: var(--mdc-typography-body2-line-height, 22px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #5d87ff;
  --mdc-slider-focus-handle-color: #5d87ff;
  --mdc-slider-hover-handle-color: #5d87ff;
  --mdc-slider-active-track-color: #5d87ff;
  --mdc-slider-inactive-track-color: #5d87ff;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #5d87ff;
  --mat-mdc-slider-ripple-color: #5d87ff;
  --mat-mdc-slider-hover-ripple-color: rgba(93, 135, 255, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(93, 135, 255, 0.2);
}
.mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #44b7f7;
  --mdc-slider-focus-handle-color: #44b7f7;
  --mdc-slider-hover-handle-color: #44b7f7;
  --mdc-slider-active-track-color: #44b7f7;
  --mdc-slider-inactive-track-color: #44b7f7;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #44b7f7;
  --mat-mdc-slider-ripple-color: #44b7f7;
  --mat-mdc-slider-hover-ripple-color: rgba(68, 183, 247, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(68, 183, 247, 0.2);
}
.mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #fa896b;
  --mdc-slider-focus-handle-color: #fa896b;
  --mdc-slider-hover-handle-color: #fa896b;
  --mdc-slider-active-track-color: #fa896b;
  --mdc-slider-inactive-track-color: #fa896b;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #fa896b;
  --mat-mdc-slider-ripple-color: #fa896b;
  --mat-mdc-slider-hover-ripple-color: rgba(250, 137, 107, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(250, 137, 107, 0.2);
}

.mat-mdc-slider {
  --mdc-slider-label-label-text-font: Plus Jakarta Sans;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 14px;
  --mdc-slider-label-label-text-tracking: normal;
  --mdc-slider-label-label-text-weight: 400;
}

.mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #5d87ff);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #5d87ff);
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-divider::after {
  border-bottom-color: white;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-menu-item[disabled],
.mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}

.mat-mdc-menu-item .mat-icon-no-color,
.mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-menu-item:hover:not([disabled]),
.mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-mdc-menu-content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Plus Jakarta Sans));
  font-size: var(--mdc-typography-subtitle1-font-size, 21px);
  line-height: var(--mdc-typography-subtitle1-line-height, 26px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 500);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 20px;
}
.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Plus Jakarta Sans));
  font-size: var(--mdc-typography-body1-font-size, 14px);
  line-height: var(--mdc-typography-body1-line-height, 20px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #5d87ff;
  --mdc-radio-selected-hover-icon-color: #5d87ff;
  --mdc-radio-selected-icon-color: #5d87ff;
  --mdc-radio-selected-pressed-icon-color: #5d87ff;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #44b7f7;
  --mdc-radio-selected-hover-icon-color: #44b7f7;
  --mdc-radio-selected-icon-color: #44b7f7;
  --mdc-radio-selected-pressed-icon-color: #44b7f7;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #fa896b;
  --mdc-radio-selected-hover-icon-color: #fa896b;
  --mdc-radio-selected-icon-color: #fa896b;
  --mdc-radio-selected-pressed-icon-color: #fa896b;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #5d87ff;
  --mdc-checkbox-selected-hover-icon-color: #5d87ff;
  --mdc-checkbox-selected-icon-color: #5d87ff;
  --mdc-checkbox-selected-pressed-icon-color: #5d87ff;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #5d87ff;
  --mdc-checkbox-selected-hover-state-layer-color: #5d87ff;
  --mdc-checkbox-selected-pressed-state-layer-color: #5d87ff;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #44b7f7;
  --mdc-checkbox-selected-hover-icon-color: #44b7f7;
  --mdc-checkbox-selected-icon-color: #44b7f7;
  --mdc-checkbox-selected-pressed-icon-color: #44b7f7;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #44b7f7;
  --mdc-checkbox-selected-hover-state-layer-color: #44b7f7;
  --mdc-checkbox-selected-pressed-state-layer-color: #44b7f7;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #fa896b;
  --mdc-checkbox-selected-hover-icon-color: #fa896b;
  --mdc-checkbox-selected-icon-color: #fa896b;
  --mdc-checkbox-selected-pressed-icon-color: #fa896b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #fa896b;
  --mdc-checkbox-selected-hover-state-layer-color: #fa896b;
  --mdc-checkbox-selected-pressed-state-layer-color: #fa896b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #5d87ff;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #5d87ff;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

.mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-font: Plus Jakarta Sans;
  --mdc-list-list-item-label-text-line-height: 20px;
  --mdc-list-list-item-label-text-size: 14px;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Plus Jakarta Sans;
  --mdc-list-list-item-supporting-text-line-height: 22px;
  --mdc-list-list-item-supporting-text-size: 16px;
  --mdc-list-list-item-supporting-text-tracking: normal;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Plus Jakarta Sans;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font-size: 21px;
  font-weight: 500;
  line-height: 26px;
  font-family: Plus Jakarta Sans;
  letter-spacing: normal;
}

.mat-mdc-paginator {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-mdc-paginator-decrement,
.mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-mdc-paginator-first,
.mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.12);
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

.mat-mdc-paginator-container {
  min-height: 56px;
}

.mat-mdc-paginator {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Plus Jakarta Sans));
  font-size: var(--mdc-typography-caption-font-size, 12px);
  line-height: var(--mdc-typography-caption-line-height, 20px);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}

.mat-mdc-paginator .mat-mdc-select-value {
  font-size: 12px;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #5d87ff;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #5d87ff;
  --mat-tab-header-active-ripple-color: #5d87ff;
  --mat-tab-header-inactive-ripple-color: #5d87ff;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #5d87ff;
  --mat-tab-header-active-hover-label-text-color: #5d87ff;
  --mat-tab-header-active-focus-indicator-color: #5d87ff;
  --mat-tab-header-active-hover-indicator-color: #5d87ff;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #44b7f7;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #44b7f7;
  --mat-tab-header-active-ripple-color: #44b7f7;
  --mat-tab-header-inactive-ripple-color: #44b7f7;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #44b7f7;
  --mat-tab-header-active-hover-label-text-color: #44b7f7;
  --mat-tab-header-active-focus-indicator-color: #44b7f7;
  --mat-tab-header-active-hover-indicator-color: #44b7f7;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #fa896b;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #fa896b;
  --mat-tab-header-active-ripple-color: #fa896b;
  --mat-tab-header-inactive-ripple-color: #fa896b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #fa896b;
  --mat-tab-header-active-hover-label-text-color: #fa896b;
  --mat-tab-header-active-focus-indicator-color: #fa896b;
  --mat-tab-header-active-hover-indicator-color: #fa896b;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #5d87ff;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #44b7f7;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #fa896b;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Plus Jakarta Sans;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-letter-spacing: normal;
  --mat-tab-header-label-text-line-height: 14px;
  --mat-tab-header-label-text-weight: 400;
}

.mat-mdc-checkbox {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #44b7f7;
  --mdc-checkbox-selected-hover-icon-color: #44b7f7;
  --mdc-checkbox-selected-icon-color: #44b7f7;
  --mdc-checkbox-selected-pressed-icon-color: #44b7f7;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #44b7f7;
  --mdc-checkbox-selected-hover-state-layer-color: #44b7f7;
  --mdc-checkbox-selected-pressed-state-layer-color: #44b7f7;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #5d87ff;
  --mdc-checkbox-selected-hover-icon-color: #5d87ff;
  --mdc-checkbox-selected-icon-color: #5d87ff;
  --mdc-checkbox-selected-pressed-icon-color: #5d87ff;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #5d87ff;
  --mdc-checkbox-selected-hover-state-layer-color: #5d87ff;
  --mdc-checkbox-selected-pressed-state-layer-color: #5d87ff;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #fa896b;
  --mdc-checkbox-selected-hover-icon-color: #fa896b;
  --mdc-checkbox-selected-icon-color: #fa896b;
  --mdc-checkbox-selected-pressed-icon-color: #fa896b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #fa896b;
  --mdc-checkbox-selected-hover-state-layer-color: #fa896b;
  --mdc-checkbox-selected-pressed-state-layer-color: #fa896b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-checkbox {
  --mdc-checkbox-state-layer-size: 40px;
}

.mat-mdc-checkbox .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Plus Jakarta Sans));
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: var(--mdc-typography-body2-line-height, 22px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #5d87ff;
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #44b7f7;
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #fa896b;
}
.mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #5d87ff;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #44b7f7;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #fa896b;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #5d87ff;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #44b7f7;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #fa896b;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #5d87ff;
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #44b7f7;
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #fa896b;
}
.mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-button, .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #5d87ff;
  --mat-mdc-button-ripple-color: rgba(93, 135, 255, 0.1);
}
.mat-mdc-button.mat-accent, .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #44b7f7;
  --mat-mdc-button-ripple-color: rgba(68, 183, 247, 0.1);
}
.mat-mdc-button.mat-warn, .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fa896b;
  --mat-mdc-button-ripple-color: rgba(250, 137, 107, 0.1);
}

.mat-mdc-raised-button, .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent, .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-warn, .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}

.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Plus Jakarta Sans));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 14px);
  font-weight: var(--mdc-typography-button-font-weight, 400);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #5d87ff;
  --mat-mdc-button-persistent-ripple-color: #5d87ff;
  --mat-mdc-button-ripple-color: rgba(93, 135, 255, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #44b7f7;
  --mat-mdc-button-persistent-ripple-color: #44b7f7;
  --mat-mdc-button-ripple-color: rgba(68, 183, 247, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #fa896b;
  --mat-mdc-button-persistent-ripple-color: #fa896b;
  --mat-mdc-button-ripple-color: rgba(250, 137, 107, 0.1);
}
.mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

.mat-mdc-fab, .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-accent, .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab.mat-warn, .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab.mat-unthemed, .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #5d87ff;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-accent, .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #44b7f7;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-warn, .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #fa896b;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab[disabled][disabled], .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}

.mdc-fab--extended {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Plus Jakarta Sans));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 14px);
  font-weight: var(--mdc-typography-button-font-weight, 400);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #44b7f7;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-font: Plus Jakarta Sans;
  --mdc-snackbar-supporting-text-line-height: 22px;
  --mdc-snackbar-supporting-text-size: 16px;
  --mdc-snackbar-supporting-text-weight: 400;
}

.mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-data-table__row {
  background-color: inherit;
}

.mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-data-table__row--selected {
  background-color: rgba(93, 135, 255, 0.04);
}

.mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-data-table__cell,
.mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-data-table__pagination-total,
.mdc-data-table__pagination-rows-per-page-label,
.mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}

[dir=rtl] .mdc-data-table__pagination-button .mdc-button__icon, .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mat-mdc-table {
  background: white;
}

.mat-mdc-table .mdc-data-table__row {
  height: 52px;
}
.mat-mdc-table .mdc-data-table__pagination {
  min-height: 52px;
}
.mat-mdc-table .mdc-data-table__header-row {
  height: 56px;
}

.mdc-data-table__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Plus Jakarta Sans));
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: var(--mdc-typography-body2-line-height, 22px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-data-table__cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Plus Jakarta Sans));
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: var(--mdc-typography-body2-line-height, 22px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-data-table__header-cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Plus Jakarta Sans));
  font-size: var(--mdc-typography-subtitle2-font-size, 14px);
  line-height: var(--mdc-typography-subtitle2-line-height, 14px);
  font-weight: var(--mdc-typography-subtitle2-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle2-text-transform, none);
}

[dir=rtl] .mdc-data-table__pagination-button .mdc-button__icon, .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #5d87ff;
}
.mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #44b7f7;
}
.mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #fa896b;
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #5d87ff;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #44b7f7;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #fa896b;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Plus Jakarta Sans;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-bottom-sheet-container {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  font-family: Plus Jakarta Sans;
  letter-spacing: normal;
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-button-toggle {
  font-family: Plus Jakarta Sans;
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(93, 135, 255, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(93, 135, 255, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(93, 135, 255, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #5d87ff;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(93, 135, 255, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(93, 135, 255, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(93, 135, 255, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(68, 183, 247, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(68, 183, 247, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(68, 183, 247, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #44b7f7;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(68, 183, 247, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(68, 183, 247, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(68, 183, 247, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(250, 137, 107, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(250, 137, 107, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(250, 137, 107, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #fa896b;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(250, 137, 107, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(250, 137, 107, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(250, 137, 107, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #5d87ff;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #44b7f7;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #fa896b;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

.mat-calendar {
  font-family: Plus Jakarta Sans;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 400;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-expansion-panel-header {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  font-family: Plus Jakarta Sans;
  letter-spacing: normal;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 16px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

.mat-icon.mat-primary {
  color: #5d87ff;
}
.mat-icon.mat-accent {
  color: #44b7f7;
}
.mat-icon.mat-warn {
  color: #fa896b;
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #5d87ff;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #44b7f7;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #fa896b;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #fa896b;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #fa896b;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Plus Jakarta Sans;
}

.mat-step-label {
  font-size: 16px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 400;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #5d87ff;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #44b7f7;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #fa896b;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  font-family: Plus Jakarta Sans;
  letter-spacing: normal;
  margin: 0;
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-tree {
  font-family: Plus Jakarta Sans;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 16px;
}

.dark-theme .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.dark-theme .mat-mdc-option:hover:not(.mdc-list-item--disabled), .dark-theme .mat-mdc-option:focus.mdc-list-item, .dark-theme .mat-mdc-option.mat-mdc-option-active.mdc-list-item, .dark-theme .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(255, 255, 255, 0.08);
}
.dark-theme .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #5d87ff);
}
.dark-theme .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #44b7f7);
}
.dark-theme .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.dark-theme .mat-pseudo-checkbox-full {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #686868;
}
.dark-theme .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.dark-theme .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #5d87ff;
}
.dark-theme .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.dark-theme .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #5d87ff;
}
.dark-theme .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.dark-theme .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #303030;
}
.dark-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.dark-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #44b7f7;
}
.dark-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.dark-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #44b7f7;
}
.dark-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.dark-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #303030;
}
.dark-theme .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.dark-theme .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #44b7f7;
}
.dark-theme .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.dark-theme .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #44b7f7;
}
.dark-theme .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.dark-theme .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #303030;
}
.dark-theme .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.dark-theme .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #fa896b;
}
.dark-theme .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.dark-theme .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #fa896b;
}
.dark-theme .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.dark-theme .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #303030;
}
.dark-theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.dark-theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #686868;
}
.dark-theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.dark-theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #686868;
}
.dark-theme .mat-app-background, .dark-theme.mat-app-background {
  background-color: #303030;
  color: white;
}
.dark-theme .mat-elevation-z0, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z1, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z2, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z3, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z4, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z5, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z6, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z7, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z8, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z9, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z10, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z11, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z12, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z13, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z14, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z15, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z16, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z17, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z18, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z19, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z20, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z21, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z22, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z23, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z24, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.dark-theme .mat-mdc-card {
  --mdc-elevated-card-container-color: #424242;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #424242;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #5d87ff;
  --mdc-linear-progress-track-color: rgba(93, 135, 255, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.dark-theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(93, 135, 255, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(93, 135, 255, 0.25));
}
@media (forced-colors: active) {
  .dark-theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .dark-theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(93, 135, 255, 0.25)'/%3E%3C/svg%3E");
  }
}
.dark-theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(93, 135, 255, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(93, 135, 255, 0.25));
}
.dark-theme .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #44b7f7;
  --mdc-linear-progress-track-color: rgba(68, 183, 247, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.dark-theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(68, 183, 247, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(68, 183, 247, 0.25));
}
@media (forced-colors: active) {
  .dark-theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .dark-theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(68, 183, 247, 0.25)'/%3E%3C/svg%3E");
  }
}
.dark-theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(68, 183, 247, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(68, 183, 247, 0.25));
}
.dark-theme .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #fa896b;
  --mdc-linear-progress-track-color: rgba(250, 137, 107, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.dark-theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(250, 137, 107, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(250, 137, 107, 0.25));
}
@media (forced-colors: active) {
  .dark-theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .dark-theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(250, 137, 107, 0.25)'/%3E%3C/svg%3E");
  }
}
.dark-theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(250, 137, 107, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(250, 137, 107, 0.25));
}
.dark-theme .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(255, 255, 255, 0.6);
}
.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(255, 255, 255, 0.87);
}
@media all {
  .dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
}
@media all {
  .dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
}
.dark-theme .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #5d87ff);
}
.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.dark-theme .mdc-text-field--filled .mdc-text-field__ripple::before,
.dark-theme .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(255, 255, 255, 0.87));
}
.dark-theme .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .dark-theme .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.dark-theme .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .dark-theme .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.dark-theme .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #4a4a4a;
}
.dark-theme .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.42);
}
.dark-theme .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #5d87ff);
}
.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.38);
}
.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #5d87ff);
}
[dir=rtl] .dark-theme .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .dark-theme .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .dark-theme .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .dark-theme .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.dark-theme .mdc-text-field--outlined .mdc-text-field__ripple::before,
.dark-theme .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .dark-theme .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .dark-theme .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.dark-theme .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(93, 135, 255, 0.87);
}
.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(255, 255, 255, 0.38);
}
@media all {
  .dark-theme .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(255, 255, 255, 0.38);
  }
}
@media all {
  .dark-theme .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.38);
  }
}
.dark-theme .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(255, 255, 255, 0.38);
}
.dark-theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.dark-theme .mdc-text-field--disabled .mdc-text-field-character-counter,
.dark-theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.dark-theme .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.dark-theme .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.dark-theme .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.dark-theme .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.dark-theme .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.06);
}
.dark-theme .mdc-text-field--disabled .mdc-notched-outline__leading,
.dark-theme .mdc-text-field--disabled .mdc-notched-outline__notch,
.dark-theme .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .dark-theme .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .dark-theme .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
  .dark-theme .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
  .dark-theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
  .dark-theme .mdc-text-field--disabled .mdc-text-field-character-counter,
  .dark-theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
  .dark-theme .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
  .dark-theme .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
  .dark-theme .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
  .dark-theme .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
  .dark-theme .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
  .dark-theme .mdc-text-field--disabled .mdc-notched-outline__leading,
  .dark-theme .mdc-text-field--disabled .mdc-notched-outline__notch,
  .dark-theme .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.dark-theme .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #464646;
}
[dir=rtl] .dark-theme .mdc-text-field--end-aligned .mdc-text-field__input, .dark-theme .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .dark-theme .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .dark-theme .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.dark-theme .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mat-mdc-form-field-focus-overlay {
  background-color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.08;
}
.dark-theme .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.24;
}
.dark-theme select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.dark-theme select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.dark-theme .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.54);
}
.dark-theme .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(93, 135, 255, 0.87);
}
.dark-theme .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(68, 183, 247, 0.87);
}
.dark-theme .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(250, 137, 107, 0.87);
}
.dark-theme .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.38);
}
.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #44b7f7);
}
.dark-theme .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #44b7f7);
}
.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(68, 183, 247, 0.87);
}
.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #44b7f7);
}
.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(250, 137, 107, 0.87);
}
.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #fa896b);
}
.dark-theme .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.dark-theme [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.dark-theme .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.dark-theme .mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #424242);
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.dark-theme .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.dark-theme .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.dark-theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.dark-theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.dark-theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.dark-theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.dark-theme .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.dark-theme .mdc-list-item--disabled .mdc-list-item__start,
.dark-theme .mdc-list-item--disabled .mdc-list-item__content,
.dark-theme .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.dark-theme .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme .mdc-list-item--selected .mdc-list-item__primary-text,
.dark-theme .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #5d87ff);
}
.dark-theme .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.dark-theme .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #5d87ff);
}
.dark-theme .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.dark-theme .mdc-list-divider::after {
  border-bottom-color: white;
}
.dark-theme .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.dark-theme .mat-mdc-select-value {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mat-mdc-select-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.dark-theme .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(255, 255, 255, 0.38);
}
.dark-theme .mat-mdc-select-arrow {
  color: rgba(255, 255, 255, 0.54);
}
.dark-theme .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(93, 135, 255, 0.87);
}
.dark-theme .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(68, 183, 247, 0.87);
}
.dark-theme .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(250, 137, 107, 0.87);
}
.dark-theme .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(250, 137, 107, 0.87);
}
.dark-theme .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(255, 255, 255, 0.38);
}
.dark-theme .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.dark-theme .mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #424242);
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.dark-theme .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.dark-theme .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.dark-theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.dark-theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.dark-theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.dark-theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.dark-theme .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.dark-theme .mdc-list-item--disabled .mdc-list-item__start,
.dark-theme .mdc-list-item--disabled .mdc-list-item__content,
.dark-theme .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.dark-theme .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme .mdc-list-item--selected .mdc-list-item__primary-text,
.dark-theme .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #5d87ff);
}
.dark-theme .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.dark-theme .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #5d87ff);
}
.dark-theme .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.dark-theme .mdc-list-divider::after {
  border-bottom-color: white;
}
.dark-theme .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.dark-theme .mat-mdc-dialog-container {
  --mdc-dialog-container-color: #424242;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
}
.dark-theme .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #595959;
  --mdc-chip-elevated-disabled-container-color: #595959;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
}
.dark-theme .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .dark-theme .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #5d87ff;
  --mdc-chip-elevated-disabled-container-color: #5d87ff;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.dark-theme .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .dark-theme .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #44b7f7;
  --mdc-chip-elevated-disabled-container-color: #44b7f7;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.dark-theme .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .dark-theme .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #fa896b;
  --mdc-chip-elevated-disabled-container-color: #fa896b;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.dark-theme .mat-mdc-chip-focus-overlay {
  background: white;
}
.dark-theme .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-unselected-icon-color: #212121;
}
.dark-theme .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.dark-theme .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.dark-theme .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #5d87ff;
  --mdc-radio-selected-hover-icon-color: #5d87ff;
  --mdc-radio-selected-icon-color: #5d87ff;
  --mdc-radio-selected-pressed-icon-color: #5d87ff;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #5d87ff;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #44b7f7;
  --mdc-radio-selected-hover-icon-color: #44b7f7;
  --mdc-radio-selected-icon-color: #44b7f7;
  --mdc-radio-selected-pressed-icon-color: #44b7f7;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #44b7f7;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #fa896b;
  --mdc-radio-selected-hover-icon-color: #fa896b;
  --mdc-radio-selected-icon-color: #fa896b;
  --mdc-radio-selected-pressed-icon-color: #fa896b;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #fa896b;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-mdc-slider {
  --mdc-slider-label-container-color: white;
  --mdc-slider-label-label-text-color: black;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mat-mdc-slider-value-indicator-opacity: 0.9;
}
.dark-theme .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #5d87ff;
  --mdc-slider-focus-handle-color: #5d87ff;
  --mdc-slider-hover-handle-color: #5d87ff;
  --mdc-slider-active-track-color: #5d87ff;
  --mdc-slider-inactive-track-color: #5d87ff;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #5d87ff;
  --mat-mdc-slider-ripple-color: #5d87ff;
  --mat-mdc-slider-hover-ripple-color: rgba(93, 135, 255, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(93, 135, 255, 0.2);
}
.dark-theme .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #44b7f7;
  --mdc-slider-focus-handle-color: #44b7f7;
  --mdc-slider-hover-handle-color: #44b7f7;
  --mdc-slider-active-track-color: #44b7f7;
  --mdc-slider-inactive-track-color: #44b7f7;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #44b7f7;
  --mat-mdc-slider-ripple-color: #44b7f7;
  --mat-mdc-slider-hover-ripple-color: rgba(68, 183, 247, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(68, 183, 247, 0.2);
}
.dark-theme .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #fa896b;
  --mdc-slider-focus-handle-color: #fa896b;
  --mdc-slider-hover-handle-color: #fa896b;
  --mdc-slider-active-track-color: #fa896b;
  --mdc-slider-inactive-track-color: #fa896b;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #fa896b;
  --mat-mdc-slider-ripple-color: #fa896b;
  --mat-mdc-slider-hover-ripple-color: rgba(250, 137, 107, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(250, 137, 107, 0.2);
}
.dark-theme .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.dark-theme .mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #424242);
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.dark-theme .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.dark-theme .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.dark-theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.dark-theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.dark-theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.dark-theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.dark-theme .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.dark-theme .mdc-list-item--disabled .mdc-list-item__start,
.dark-theme .mdc-list-item--disabled .mdc-list-item__content,
.dark-theme .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.dark-theme .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme .mdc-list-item--selected .mdc-list-item__primary-text,
.dark-theme .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #5d87ff);
}
.dark-theme .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.dark-theme .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #5d87ff);
}
.dark-theme .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.dark-theme .mdc-list-divider::after {
  border-bottom-color: white;
}
.dark-theme .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.dark-theme .mat-mdc-menu-item[disabled],
.dark-theme .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.dark-theme .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(255, 255, 255, 0.5));
}
.dark-theme .mat-mdc-menu-item .mat-icon-no-color,
.dark-theme .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.dark-theme .mat-mdc-menu-item:hover:not([disabled]),
.dark-theme .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.dark-theme .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.dark-theme .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.08);
}
.dark-theme .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: rgba(255, 255, 255, 0.7);
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
}
.dark-theme .mdc-list-item__start,
.dark-theme .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #5d87ff;
  --mdc-radio-selected-hover-icon-color: #5d87ff;
  --mdc-radio-selected-icon-color: #5d87ff;
  --mdc-radio-selected-pressed-icon-color: #5d87ff;
}
.dark-theme .mat-accent .mdc-list-item__start,
.dark-theme .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #44b7f7;
  --mdc-radio-selected-hover-icon-color: #44b7f7;
  --mdc-radio-selected-icon-color: #44b7f7;
  --mdc-radio-selected-pressed-icon-color: #44b7f7;
}
.dark-theme .mat-warn .mdc-list-item__start,
.dark-theme .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #fa896b;
  --mdc-radio-selected-hover-icon-color: #fa896b;
  --mdc-radio-selected-icon-color: #fa896b;
  --mdc-radio-selected-pressed-icon-color: #fa896b;
}
.dark-theme .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #5d87ff;
  --mdc-checkbox-selected-hover-icon-color: #5d87ff;
  --mdc-checkbox-selected-icon-color: #5d87ff;
  --mdc-checkbox-selected-pressed-icon-color: #5d87ff;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #5d87ff;
  --mdc-checkbox-selected-hover-state-layer-color: #5d87ff;
  --mdc-checkbox-selected-pressed-state-layer-color: #5d87ff;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark-theme .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #44b7f7;
  --mdc-checkbox-selected-hover-icon-color: #44b7f7;
  --mdc-checkbox-selected-icon-color: #44b7f7;
  --mdc-checkbox-selected-pressed-icon-color: #44b7f7;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #44b7f7;
  --mdc-checkbox-selected-hover-state-layer-color: #44b7f7;
  --mdc-checkbox-selected-pressed-state-layer-color: #44b7f7;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark-theme .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #fa896b;
  --mdc-checkbox-selected-hover-icon-color: #fa896b;
  --mdc-checkbox-selected-icon-color: #fa896b;
  --mdc-checkbox-selected-pressed-icon-color: #fa896b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #fa896b;
  --mdc-checkbox-selected-hover-state-layer-color: #fa896b;
  --mdc-checkbox-selected-pressed-state-layer-color: #fa896b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #5d87ff;
}
.dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #5d87ff;
}
.dark-theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.dark-theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.dark-theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.dark-theme .mat-mdc-paginator {
  background: #424242;
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mat-mdc-paginator-icon {
  fill: rgba(255, 255, 255, 0.54);
}
.dark-theme .mat-mdc-paginator-decrement,
.dark-theme .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(255, 255, 255, 0.54);
  border-right: 2px solid rgba(255, 255, 255, 0.54);
}
.dark-theme .mat-mdc-paginator-first,
.dark-theme .mat-mdc-paginator-last {
  border-top: 2px solid rgba(255, 255, 255, 0.54);
}
.dark-theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.dark-theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.dark-theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.dark-theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-mdc-tab-group, .dark-theme .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #5d87ff;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #5d87ff;
  --mat-tab-header-active-ripple-color: #5d87ff;
  --mat-tab-header-inactive-ripple-color: #5d87ff;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #5d87ff;
  --mat-tab-header-active-hover-label-text-color: #5d87ff;
  --mat-tab-header-active-focus-indicator-color: #5d87ff;
  --mat-tab-header-active-hover-indicator-color: #5d87ff;
}
.dark-theme .mat-mdc-tab-group.mat-accent, .dark-theme .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #44b7f7;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #44b7f7;
  --mat-tab-header-active-ripple-color: #44b7f7;
  --mat-tab-header-inactive-ripple-color: #44b7f7;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #44b7f7;
  --mat-tab-header-active-hover-label-text-color: #44b7f7;
  --mat-tab-header-active-focus-indicator-color: #44b7f7;
  --mat-tab-header-active-hover-indicator-color: #44b7f7;
}
.dark-theme .mat-mdc-tab-group.mat-warn, .dark-theme .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #fa896b;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #fa896b;
  --mat-tab-header-active-ripple-color: #fa896b;
  --mat-tab-header-inactive-ripple-color: #fa896b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #fa896b;
  --mat-tab-header-active-hover-label-text-color: #fa896b;
  --mat-tab-header-active-focus-indicator-color: #fa896b;
  --mat-tab-header-active-hover-indicator-color: #fa896b;
}
.dark-theme .mat-mdc-tab-group.mat-background-primary, .dark-theme .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #5d87ff;
  --mat-tab-header-with-background-foreground-color: white;
}
.dark-theme .mat-mdc-tab-group.mat-background-accent, .dark-theme .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #44b7f7;
  --mat-tab-header-with-background-foreground-color: white;
}
.dark-theme .mat-mdc-tab-group.mat-background-warn, .dark-theme .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #fa896b;
  --mat-tab-header-with-background-foreground-color: white;
}
.dark-theme .mat-mdc-checkbox {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #44b7f7;
  --mdc-checkbox-selected-hover-icon-color: #44b7f7;
  --mdc-checkbox-selected-icon-color: #44b7f7;
  --mdc-checkbox-selected-pressed-icon-color: #44b7f7;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #44b7f7;
  --mdc-checkbox-selected-hover-state-layer-color: #44b7f7;
  --mdc-checkbox-selected-pressed-state-layer-color: #44b7f7;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark-theme .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #5d87ff;
  --mdc-checkbox-selected-hover-icon-color: #5d87ff;
  --mdc-checkbox-selected-icon-color: #5d87ff;
  --mdc-checkbox-selected-pressed-icon-color: #5d87ff;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #5d87ff;
  --mdc-checkbox-selected-hover-state-layer-color: #5d87ff;
  --mdc-checkbox-selected-pressed-state-layer-color: #5d87ff;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark-theme .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #fa896b;
  --mdc-checkbox-selected-hover-icon-color: #fa896b;
  --mdc-checkbox-selected-icon-color: #fa896b;
  --mdc-checkbox-selected-pressed-icon-color: #fa896b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #fa896b;
  --mdc-checkbox-selected-hover-state-layer-color: #fa896b;
  --mdc-checkbox-selected-pressed-state-layer-color: #fa896b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark-theme .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.dark-theme .mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #fff;
}
.dark-theme .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #5d87ff;
}
.dark-theme .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #44b7f7;
}
.dark-theme .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #fa896b;
}
.dark-theme .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-text-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #424242;
  --mdc-filled-button-label-text-color: #fff;
}
.dark-theme .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #5d87ff;
  --mdc-filled-button-label-text-color: #fff;
}
.dark-theme .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #44b7f7;
  --mdc-filled-button-label-text-color: #000;
}
.dark-theme .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #fa896b;
  --mdc-filled-button-label-text-color: #000;
}
.dark-theme .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-filled-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #424242;
  --mdc-protected-button-label-text-color: #fff;
}
.dark-theme .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #5d87ff;
  --mdc-protected-button-label-text-color: #fff;
}
.dark-theme .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #44b7f7;
  --mdc-protected-button-label-text-color: #000;
}
.dark-theme .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #fa896b;
  --mdc-protected-button-label-text-color: #000;
}
.dark-theme .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-elevation: 0;
}
.dark-theme .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #fff;
}
.dark-theme .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #5d87ff;
}
.dark-theme .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #44b7f7;
}
.dark-theme .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #fa896b;
}
.dark-theme .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-mdc-button, .dark-theme .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .dark-theme .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.dark-theme .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .dark-theme .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .dark-theme .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .dark-theme .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark-theme .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .dark-theme .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark-theme .mat-mdc-button.mat-primary, .dark-theme .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #5d87ff;
  --mat-mdc-button-ripple-color: rgba(93, 135, 255, 0.1);
}
.dark-theme .mat-mdc-button.mat-accent, .dark-theme .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #44b7f7;
  --mat-mdc-button-ripple-color: rgba(68, 183, 247, 0.1);
}
.dark-theme .mat-mdc-button.mat-warn, .dark-theme .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fa896b;
  --mat-mdc-button-ripple-color: rgba(250, 137, 107, 0.1);
}
.dark-theme .mat-mdc-raised-button, .dark-theme .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .dark-theme .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.dark-theme .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .dark-theme .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .dark-theme .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .dark-theme .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark-theme .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .dark-theme .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark-theme .mat-mdc-raised-button.mat-primary, .dark-theme .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-raised-button.mat-accent, .dark-theme .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.dark-theme .mat-mdc-raised-button.mat-warn, .dark-theme .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.dark-theme .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.dark-theme .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .dark-theme .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark-theme .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark-theme .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.dark-theme .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.dark-theme .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.dark-theme .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #5d87ff;
  --mat-mdc-button-persistent-ripple-color: #5d87ff;
  --mat-mdc-button-ripple-color: rgba(93, 135, 255, 0.1);
}
.dark-theme .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #44b7f7;
  --mat-mdc-button-persistent-ripple-color: #44b7f7;
  --mat-mdc-button-ripple-color: rgba(68, 183, 247, 0.1);
}
.dark-theme .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #fa896b;
  --mat-mdc-button-persistent-ripple-color: #fa896b;
  --mat-mdc-button-ripple-color: rgba(250, 137, 107, 0.1);
}
.dark-theme .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-mdc-fab, .dark-theme .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .dark-theme .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.dark-theme .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .dark-theme .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .dark-theme .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .dark-theme .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark-theme .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .dark-theme .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark-theme .mat-mdc-fab.mat-primary, .dark-theme .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-fab.mat-accent, .dark-theme .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.dark-theme .mat-mdc-fab.mat-warn, .dark-theme .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.dark-theme .mat-mdc-fab.mat-unthemed, .dark-theme .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #424242;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.dark-theme .mat-mdc-fab.mat-primary, .dark-theme .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #5d87ff;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.dark-theme .mat-mdc-fab.mat-accent, .dark-theme .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #44b7f7;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.dark-theme .mat-mdc-fab.mat-warn, .dark-theme .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #fa896b;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.dark-theme .mat-mdc-fab[disabled][disabled], .dark-theme .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(255, 255, 255, 0.12);
  --mdc-fab-icon-color: rgba(255, 255, 255, 0.5);
  --mat-mdc-fab-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #d9d9d9;
  --mdc-snackbar-supporting-text-color: rgba(66, 66, 66, 0.87);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
}
.dark-theme .mdc-data-table {
  background-color: var(--mdc-theme-surface, #424242);
  border-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mdc-data-table__row {
  background-color: inherit;
}
.dark-theme .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #424242);
}
.dark-theme .mdc-data-table__row--selected {
  background-color: rgba(93, 135, 255, 0.04);
}
.dark-theme .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.dark-theme .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.dark-theme .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mdc-data-table__cell,
.dark-theme .mdc-data-table__header-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mdc-data-table__pagination {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(255, 255, 255, 0.04);
}
.dark-theme .mdc-data-table__header-cell {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mdc-data-table__pagination-total,
.dark-theme .mdc-data-table__pagination-rows-per-page-label,
.dark-theme .mdc-data-table__cell {
  color: rgba(255, 255, 255, 0.87);
}
[dir=rtl] .dark-theme .mdc-data-table__pagination-button .mdc-button__icon, .dark-theme .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.dark-theme .mat-mdc-table {
  background: #424242;
}
.dark-theme .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #5d87ff;
}
.dark-theme .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #44b7f7;
}
.dark-theme .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #fa896b;
}
.dark-theme .mat-badge-content {
  color: white;
  background: #5d87ff;
}
.cdk-high-contrast-active .dark-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.dark-theme .mat-badge-accent .mat-badge-content {
  background: #44b7f7;
  color: white;
}
.dark-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #fa896b;
}
.dark-theme .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.dark-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.dark-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.dark-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.dark-theme .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.dark-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.dark-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
.dark-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
.dark-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
.dark-theme .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.dark-theme .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.dark-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.dark-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.dark-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.dark-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
.dark-theme .mat-calendar-arrow {
  fill: white;
}
.dark-theme .mat-datepicker-toggle,
.dark-theme .mat-datepicker-content .mat-calendar-next-button,
.dark-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.dark-theme .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-calendar-table-header,
.dark-theme .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-calendar-body-cell-content,
.dark-theme .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.dark-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.dark-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.dark-theme .mat-calendar-body-in-range::before {
  background: rgba(93, 135, 255, 0.2);
}
.dark-theme .mat-calendar-body-comparison-identical,
.dark-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.dark-theme .mat-calendar-body-comparison-bridge-start::before,
.dark-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(93, 135, 255, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.dark-theme .mat-calendar-body-comparison-bridge-end::before,
.dark-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(93, 135, 255, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.dark-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.dark-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.dark-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.dark-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.dark-theme .mat-calendar-body-selected {
  background-color: #5d87ff;
  color: white;
}
.dark-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(93, 135, 255, 0.4);
}
.dark-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.dark-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.dark-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(93, 135, 255, 0.3);
}
@media (hover: hover) {
  .dark-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(93, 135, 255, 0.3);
  }
}
.dark-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(68, 183, 247, 0.2);
}
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.dark-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(68, 183, 247, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.dark-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(68, 183, 247, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #44b7f7;
  color: white;
}
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(68, 183, 247, 0.4);
}
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.dark-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.dark-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(68, 183, 247, 0.3);
}
@media (hover: hover) {
  .dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(68, 183, 247, 0.3);
  }
}
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(250, 137, 107, 0.2);
}
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.dark-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(250, 137, 107, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.dark-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(250, 137, 107, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #fa896b;
  color: white;
}
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(250, 137, 107, 0.4);
}
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.dark-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.dark-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(250, 137, 107, 0.3);
}
@media (hover: hover) {
  .dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(250, 137, 107, 0.3);
  }
}
.dark-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-datepicker-toggle-active {
  color: #5d87ff;
}
.dark-theme .mat-datepicker-toggle-active.mat-accent {
  color: #44b7f7;
}
.dark-theme .mat-datepicker-toggle-active.mat-warn {
  color: #fa896b;
}
.dark-theme .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-expansion-panel {
  background: #424242;
  color: white;
}
.dark-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .dark-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .dark-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .dark-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.dark-theme .mat-expansion-panel-header-title {
  color: white;
}
.dark-theme .mat-expansion-panel-header-description,
.dark-theme .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.dark-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.dark-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.dark-theme .mat-icon.mat-primary {
  color: #5d87ff;
}
.dark-theme .mat-icon.mat-accent {
  color: #44b7f7;
}
.dark-theme .mat-icon.mat-warn {
  color: #fa896b;
}
.dark-theme .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.dark-theme .mat-drawer {
  background-color: #424242;
  color: white;
}
.dark-theme .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.dark-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.dark-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.dark-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.dark-theme .mat-step-header.cdk-keyboard-focused, .dark-theme .mat-step-header.cdk-program-focused, .dark-theme .mat-step-header:hover:not([aria-disabled]), .dark-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.dark-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .dark-theme .mat-step-header:hover {
    background: none;
  }
}
.dark-theme .mat-step-header .mat-step-label,
.dark-theme .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.dark-theme .mat-step-header .mat-step-icon-selected,
.dark-theme .mat-step-header .mat-step-icon-state-done,
.dark-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #5d87ff;
  color: white;
}
.dark-theme .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.dark-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.dark-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.dark-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #44b7f7;
  color: white;
}
.dark-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.dark-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.dark-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.dark-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #fa896b;
  color: white;
}
.dark-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #fa896b;
}
.dark-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.dark-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #fa896b;
}
.dark-theme .mat-stepper-horizontal, .dark-theme .mat-stepper-vertical {
  background-color: #424242;
}
.dark-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-horizontal-stepper-header::before,
.dark-theme .mat-horizontal-stepper-header::after,
.dark-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-sort-header-arrow {
  color: #c6c6c6;
}
.dark-theme .mat-toolbar {
  background: #212121;
  color: white;
}
.dark-theme .mat-toolbar.mat-primary {
  background: #5d87ff;
  color: white;
}
.dark-theme .mat-toolbar.mat-accent {
  background: #44b7f7;
  color: white;
}
.dark-theme .mat-toolbar.mat-warn {
  background: #fa896b;
  color: white;
}
.dark-theme .mat-toolbar .mat-form-field-underline,
.dark-theme .mat-toolbar .mat-form-field-ripple,
.dark-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.dark-theme .mat-toolbar .mat-form-field-label,
.dark-theme .mat-toolbar .mat-focused .mat-form-field-label,
.dark-theme .mat-toolbar .mat-select-value,
.dark-theme .mat-toolbar .mat-select-arrow,
.dark-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.dark-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.dark-theme .mat-tree {
  background: #424242;
}
.dark-theme .mat-tree-node,
.dark-theme .mat-nested-tree-node {
  color: white;
}

.orange_theme .bg-primary {
  background: #fa896b !important;
}
.orange_theme .horizontal-navbar .parentBox.pactive > a,
.orange_theme .horizontal-navbar .parentBox.pactive > a:hover,
.orange_theme .horizontal-navbar .parentBox.pactive:hover > a {
  background-color: #fa896b !important;
}
.orange_theme .activeMenu {
  background-color: #fa896b !important;
}
.orange_theme .mat-mdc-outlined-button.mat-primary {
  border-color: #fa896b !important;
}
.orange_theme .mat-mdc-outlined-button.mat-accent {
  border-color: #0074ba !important;
}
.orange_theme .mat-mdc-outlined-button.mat-warn {
  border-color: #f44336 !important;
}
.orange_theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.orange_theme .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.orange_theme .mat-mdc-option:hover:not(.mdc-list-item--disabled), .orange_theme .mat-mdc-option:focus.mdc-list-item, .orange_theme .mat-mdc-option.mat-mdc-option-active.mdc-list-item, .orange_theme .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.orange_theme .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #fa896b);
}
.orange_theme .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #0074ba);
}
.orange_theme .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.orange_theme .mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.orange_theme .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.orange_theme .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.orange_theme .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #fa896b;
}
.orange_theme .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.orange_theme .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #fa896b;
}
.orange_theme .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.orange_theme .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.orange_theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.orange_theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #0074ba;
}
.orange_theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.orange_theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #0074ba;
}
.orange_theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.orange_theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.orange_theme .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.orange_theme .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #0074ba;
}
.orange_theme .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.orange_theme .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #0074ba;
}
.orange_theme .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.orange_theme .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.orange_theme .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.orange_theme .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.orange_theme .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.orange_theme .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.orange_theme .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.orange_theme .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.orange_theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.orange_theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.orange_theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.orange_theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}
.orange_theme .mat-app-background, .orange_theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.orange_theme .mat-elevation-z0, .orange_theme .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-elevation-z1, .orange_theme .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-elevation-z2, .orange_theme .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-elevation-z3, .orange_theme .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-elevation-z4, .orange_theme .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-elevation-z5, .orange_theme .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-elevation-z6, .orange_theme .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-elevation-z7, .orange_theme .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-elevation-z8, .orange_theme .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-elevation-z9, .orange_theme .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-elevation-z10, .orange_theme .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-elevation-z11, .orange_theme .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-elevation-z12, .orange_theme .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-elevation-z13, .orange_theme .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-elevation-z14, .orange_theme .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-elevation-z15, .orange_theme .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-elevation-z16, .orange_theme .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-elevation-z17, .orange_theme .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-elevation-z18, .orange_theme .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-elevation-z19, .orange_theme .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-elevation-z20, .orange_theme .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-elevation-z21, .orange_theme .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-elevation-z22, .orange_theme .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-elevation-z23, .orange_theme .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-elevation-z24, .orange_theme .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.orange_theme .mat-mdc-card {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}
.orange_theme .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #fa896b;
  --mdc-linear-progress-track-color: rgba(250, 137, 107, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.orange_theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(250, 137, 107, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(250, 137, 107, 0.25));
}
@media (forced-colors: active) {
  .orange_theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .orange_theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(250, 137, 107, 0.25)'/%3E%3C/svg%3E");
  }
}
.orange_theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(250, 137, 107, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(250, 137, 107, 0.25));
}
.orange_theme .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #0074ba;
  --mdc-linear-progress-track-color: rgba(0, 116, 186, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.orange_theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(0, 116, 186, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(0, 116, 186, 0.25));
}
@media (forced-colors: active) {
  .orange_theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .orange_theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(0, 116, 186, 0.25)'/%3E%3C/svg%3E");
  }
}
.orange_theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(0, 116, 186, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(0, 116, 186, 0.25));
}
.orange_theme .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.orange_theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
@media (forced-colors: active) {
  .orange_theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .orange_theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 67, 54, 0.25)'/%3E%3C/svg%3E");
  }
}
.orange_theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
.orange_theme .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
.orange_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.orange_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .orange_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  .orange_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
.orange_theme .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #fa896b);
}
.orange_theme .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.orange_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.orange_theme .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.orange_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.orange_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.orange_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.orange_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.orange_theme .mdc-text-field--filled .mdc-text-field__ripple::before,
.orange_theme .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.orange_theme .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .orange_theme .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.orange_theme .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .orange_theme .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.orange_theme .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.orange_theme .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.orange_theme .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.orange_theme .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #fa896b);
}
.orange_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.orange_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.orange_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.orange_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.orange_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.orange_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.orange_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.orange_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.orange_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #fa896b);
}
[dir=rtl] .orange_theme .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .orange_theme .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .orange_theme .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .orange_theme .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.orange_theme .mdc-text-field--outlined .mdc-text-field__ripple::before,
.orange_theme .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .orange_theme .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .orange_theme .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.orange_theme .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(250, 137, 107, 0.87);
}
.orange_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.orange_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.orange_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.orange_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.orange_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.orange_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.orange_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .orange_theme .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .orange_theme .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.orange_theme .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.orange_theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.orange_theme .mdc-text-field--disabled .mdc-text-field-character-counter,
.orange_theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.orange_theme .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.orange_theme .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.orange_theme .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.orange_theme .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.orange_theme .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.orange_theme .mdc-text-field--disabled .mdc-notched-outline__leading,
.orange_theme .mdc-text-field--disabled .mdc-notched-outline__notch,
.orange_theme .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .orange_theme .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .orange_theme .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
  .orange_theme .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
  .orange_theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
  .orange_theme .mdc-text-field--disabled .mdc-text-field-character-counter,
  .orange_theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
  .orange_theme .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
  .orange_theme .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
  .orange_theme .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
  .orange_theme .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
  .orange_theme .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
  .orange_theme .mdc-text-field--disabled .mdc-notched-outline__leading,
  .orange_theme .mdc-text-field--disabled .mdc-notched-outline__notch,
  .orange_theme .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.orange_theme .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] .orange_theme .mdc-text-field--end-aligned .mdc-text-field__input, .orange_theme .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .orange_theme .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .orange_theme .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.orange_theme .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}
.orange_theme .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}
.orange_theme .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}
.orange_theme .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.orange_theme .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(250, 137, 107, 0.87);
}
.orange_theme .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(0, 116, 186, 0.87);
}
.orange_theme .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(244, 67, 54, 0.87);
}
.orange_theme .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.orange_theme .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #0074ba);
}
.orange_theme .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #0074ba);
}
.orange_theme .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 116, 186, 0.87);
}
.orange_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.orange_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.orange_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.orange_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.orange_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.orange_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.orange_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.orange_theme .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.orange_theme .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #0074ba);
}
.orange_theme .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(244, 67, 54, 0.87);
}
.orange_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.orange_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.orange_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.orange_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.orange_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.orange_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.orange_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.orange_theme .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.orange_theme .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.orange_theme .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.orange_theme [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.orange_theme .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.orange_theme .mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.orange_theme .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.orange_theme .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.orange_theme .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.orange_theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.orange_theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.orange_theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.orange_theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.orange_theme .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.orange_theme .mdc-list-item--disabled .mdc-list-item__start,
.orange_theme .mdc-list-item--disabled .mdc-list-item__content,
.orange_theme .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.orange_theme .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.orange_theme .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.orange_theme .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.orange_theme .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.orange_theme .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.orange_theme .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.orange_theme .mdc-list-item--selected .mdc-list-item__primary-text,
.orange_theme .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #fa896b);
}
.orange_theme .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.orange_theme .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #fa896b);
}
.orange_theme .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.orange_theme .mdc-list-divider::after {
  border-bottom-color: white;
}
.orange_theme .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.orange_theme .mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.orange_theme .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.orange_theme .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.orange_theme .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(250, 137, 107, 0.87);
}
.orange_theme .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(0, 116, 186, 0.87);
}
.orange_theme .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(244, 67, 54, 0.87);
}
.orange_theme .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(244, 67, 54, 0.87);
}
.orange_theme .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.orange_theme .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.orange_theme .mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.orange_theme .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.orange_theme .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.orange_theme .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.orange_theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.orange_theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.orange_theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.orange_theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.orange_theme .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.orange_theme .mdc-list-item--disabled .mdc-list-item__start,
.orange_theme .mdc-list-item--disabled .mdc-list-item__content,
.orange_theme .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.orange_theme .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.orange_theme .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.orange_theme .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.orange_theme .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.orange_theme .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.orange_theme .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.orange_theme .mdc-list-item--selected .mdc-list-item__primary-text,
.orange_theme .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #fa896b);
}
.orange_theme .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.orange_theme .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #fa896b);
}
.orange_theme .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.orange_theme .mdc-list-divider::after {
  border-bottom-color: white;
}
.orange_theme .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
.orange_theme .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
.orange_theme .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .orange_theme .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #fa896b;
  --mdc-chip-elevated-disabled-container-color: #fa896b;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.orange_theme .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .orange_theme .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #0074ba;
  --mdc-chip-elevated-disabled-container-color: #0074ba;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.orange_theme .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .orange_theme .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.orange_theme .mat-mdc-chip-focus-overlay {
  background: black;
}
.orange_theme .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.orange_theme .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.orange_theme .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.orange_theme .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}
.orange_theme .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.orange_theme .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #fa896b;
  --mdc-radio-selected-hover-icon-color: #fa896b;
  --mdc-radio-selected-icon-color: #fa896b;
  --mdc-radio-selected-pressed-icon-color: #fa896b;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #fa896b;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.orange_theme .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #0074ba;
  --mdc-radio-selected-hover-icon-color: #0074ba;
  --mdc-radio-selected-icon-color: #0074ba;
  --mdc-radio-selected-pressed-icon-color: #0074ba;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #0074ba;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.orange_theme .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.orange_theme .mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.orange_theme .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #fa896b;
  --mdc-slider-focus-handle-color: #fa896b;
  --mdc-slider-hover-handle-color: #fa896b;
  --mdc-slider-active-track-color: #fa896b;
  --mdc-slider-inactive-track-color: #fa896b;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #fa896b;
  --mat-mdc-slider-ripple-color: #fa896b;
  --mat-mdc-slider-hover-ripple-color: rgba(250, 137, 107, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(250, 137, 107, 0.2);
}
.orange_theme .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #0074ba;
  --mdc-slider-focus-handle-color: #0074ba;
  --mdc-slider-hover-handle-color: #0074ba;
  --mdc-slider-active-track-color: #0074ba;
  --mdc-slider-inactive-track-color: #0074ba;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #0074ba;
  --mat-mdc-slider-ripple-color: #0074ba;
  --mat-mdc-slider-hover-ripple-color: rgba(0, 116, 186, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(0, 116, 186, 0.2);
}
.orange_theme .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mat-mdc-slider-ripple-color: #f44336;
  --mat-mdc-slider-hover-ripple-color: rgba(244, 67, 54, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(244, 67, 54, 0.2);
}
.orange_theme .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.orange_theme .mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.orange_theme .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.orange_theme .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.orange_theme .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.orange_theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.orange_theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.orange_theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.orange_theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.orange_theme .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.orange_theme .mdc-list-item--disabled .mdc-list-item__start,
.orange_theme .mdc-list-item--disabled .mdc-list-item__content,
.orange_theme .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.orange_theme .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.orange_theme .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.orange_theme .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.orange_theme .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.orange_theme .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.orange_theme .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.orange_theme .mdc-list-item--selected .mdc-list-item__primary-text,
.orange_theme .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #fa896b);
}
.orange_theme .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.orange_theme .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #fa896b);
}
.orange_theme .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.orange_theme .mdc-list-divider::after {
  border-bottom-color: white;
}
.orange_theme .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-mdc-menu-item[disabled],
.orange_theme .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.orange_theme .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}
.orange_theme .mat-mdc-menu-item .mat-icon-no-color,
.orange_theme .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.orange_theme .mat-mdc-menu-item:hover:not([disabled]),
.orange_theme .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.orange_theme .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.orange_theme .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.orange_theme .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}
.orange_theme .mdc-list-item__start,
.orange_theme .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #fa896b;
  --mdc-radio-selected-hover-icon-color: #fa896b;
  --mdc-radio-selected-icon-color: #fa896b;
  --mdc-radio-selected-pressed-icon-color: #fa896b;
}
.orange_theme .mat-accent .mdc-list-item__start,
.orange_theme .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #0074ba;
  --mdc-radio-selected-hover-icon-color: #0074ba;
  --mdc-radio-selected-icon-color: #0074ba;
  --mdc-radio-selected-pressed-icon-color: #0074ba;
}
.orange_theme .mat-warn .mdc-list-item__start,
.orange_theme .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}
.orange_theme .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #fa896b;
  --mdc-checkbox-selected-hover-icon-color: #fa896b;
  --mdc-checkbox-selected-icon-color: #fa896b;
  --mdc-checkbox-selected-pressed-icon-color: #fa896b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #fa896b;
  --mdc-checkbox-selected-hover-state-layer-color: #fa896b;
  --mdc-checkbox-selected-pressed-state-layer-color: #fa896b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.orange_theme .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #0074ba;
  --mdc-checkbox-selected-hover-icon-color: #0074ba;
  --mdc-checkbox-selected-icon-color: #0074ba;
  --mdc-checkbox-selected-pressed-icon-color: #0074ba;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #0074ba;
  --mdc-checkbox-selected-hover-state-layer-color: #0074ba;
  --mdc-checkbox-selected-pressed-state-layer-color: #0074ba;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.orange_theme .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.orange_theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.orange_theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #fa896b;
}
.orange_theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.orange_theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #fa896b;
}
.orange_theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.orange_theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.orange_theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.orange_theme .mat-mdc-paginator {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.orange_theme .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.54);
}
.orange_theme .mat-mdc-paginator-decrement,
.orange_theme .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.orange_theme .mat-mdc-paginator-first,
.orange_theme .mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.orange_theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.orange_theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.orange_theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.orange_theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-mdc-tab-group, .orange_theme .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #fa896b;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #fa896b;
  --mat-tab-header-active-ripple-color: #fa896b;
  --mat-tab-header-inactive-ripple-color: #fa896b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #fa896b;
  --mat-tab-header-active-hover-label-text-color: #fa896b;
  --mat-tab-header-active-focus-indicator-color: #fa896b;
  --mat-tab-header-active-hover-indicator-color: #fa896b;
}
.orange_theme .mat-mdc-tab-group.mat-accent, .orange_theme .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #0074ba;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #0074ba;
  --mat-tab-header-active-ripple-color: #0074ba;
  --mat-tab-header-inactive-ripple-color: #0074ba;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #0074ba;
  --mat-tab-header-active-hover-label-text-color: #0074ba;
  --mat-tab-header-active-focus-indicator-color: #0074ba;
  --mat-tab-header-active-hover-indicator-color: #0074ba;
}
.orange_theme .mat-mdc-tab-group.mat-warn, .orange_theme .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.orange_theme .mat-mdc-tab-group.mat-background-primary, .orange_theme .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #fa896b;
  --mat-tab-header-with-background-foreground-color: white;
}
.orange_theme .mat-mdc-tab-group.mat-background-accent, .orange_theme .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #0074ba;
  --mat-tab-header-with-background-foreground-color: white;
}
.orange_theme .mat-mdc-tab-group.mat-background-warn, .orange_theme .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}
.orange_theme .mat-mdc-checkbox {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #0074ba;
  --mdc-checkbox-selected-hover-icon-color: #0074ba;
  --mdc-checkbox-selected-icon-color: #0074ba;
  --mdc-checkbox-selected-pressed-icon-color: #0074ba;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #0074ba;
  --mdc-checkbox-selected-hover-state-layer-color: #0074ba;
  --mdc-checkbox-selected-pressed-state-layer-color: #0074ba;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.orange_theme .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #fa896b;
  --mdc-checkbox-selected-hover-icon-color: #fa896b;
  --mdc-checkbox-selected-icon-color: #fa896b;
  --mdc-checkbox-selected-pressed-icon-color: #fa896b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #fa896b;
  --mdc-checkbox-selected-hover-state-layer-color: #fa896b;
  --mdc-checkbox-selected-pressed-state-layer-color: #fa896b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.orange_theme .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.orange_theme .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.orange_theme .mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}
.orange_theme .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.orange_theme .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #fa896b;
}
.orange_theme .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #0074ba;
}
.orange_theme .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.orange_theme .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.orange_theme .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.orange_theme .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #fa896b;
  --mdc-filled-button-label-text-color: #000;
}
.orange_theme .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #0074ba;
  --mdc-filled-button-label-text-color: #fff;
}
.orange_theme .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.orange_theme .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.orange_theme .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.orange_theme .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #fa896b;
  --mdc-protected-button-label-text-color: #000;
}
.orange_theme .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #0074ba;
  --mdc-protected-button-label-text-color: #fff;
}
.orange_theme .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.orange_theme .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.orange_theme .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.orange_theme .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #fa896b;
}
.orange_theme .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #0074ba;
}
.orange_theme .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.orange_theme .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-mdc-button, .orange_theme .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.orange_theme .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .orange_theme .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.orange_theme .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .orange_theme .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .orange_theme .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .orange_theme .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.orange_theme .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .orange_theme .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.orange_theme .mat-mdc-button.mat-primary, .orange_theme .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fa896b;
  --mat-mdc-button-ripple-color: rgba(250, 137, 107, 0.1);
}
.orange_theme .mat-mdc-button.mat-accent, .orange_theme .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #0074ba;
  --mat-mdc-button-ripple-color: rgba(0, 116, 186, 0.1);
}
.orange_theme .mat-mdc-button.mat-warn, .orange_theme .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.orange_theme .mat-mdc-raised-button, .orange_theme .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.orange_theme .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .orange_theme .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.orange_theme .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .orange_theme .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .orange_theme .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .orange_theme .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.orange_theme .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .orange_theme .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.orange_theme .mat-mdc-raised-button.mat-primary, .orange_theme .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.orange_theme .mat-mdc-raised-button.mat-accent, .orange_theme .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.orange_theme .mat-mdc-raised-button.mat-warn, .orange_theme .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.orange_theme .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.orange_theme .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.orange_theme .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .orange_theme .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.orange_theme .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.orange_theme .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.orange_theme .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.orange_theme .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.orange_theme .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #fa896b;
  --mat-mdc-button-persistent-ripple-color: #fa896b;
  --mat-mdc-button-ripple-color: rgba(250, 137, 107, 0.1);
}
.orange_theme .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #0074ba;
  --mat-mdc-button-persistent-ripple-color: #0074ba;
  --mat-mdc-button-ripple-color: rgba(0, 116, 186, 0.1);
}
.orange_theme .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.orange_theme .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.orange_theme .mat-mdc-fab, .orange_theme .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.orange_theme .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .orange_theme .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.orange_theme .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .orange_theme .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .orange_theme .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .orange_theme .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.orange_theme .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .orange_theme .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.orange_theme .mat-mdc-fab.mat-primary, .orange_theme .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.orange_theme .mat-mdc-fab.mat-accent, .orange_theme .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.orange_theme .mat-mdc-fab.mat-warn, .orange_theme .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.orange_theme .mat-mdc-fab.mat-unthemed, .orange_theme .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.orange_theme .mat-mdc-fab.mat-primary, .orange_theme .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #fa896b;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.orange_theme .mat-mdc-fab.mat-accent, .orange_theme .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #0074ba;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.orange_theme .mat-mdc-fab.mat-warn, .orange_theme .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.orange_theme .mat-mdc-fab[disabled][disabled], .orange_theme .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.orange_theme .mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #0074ba;
}
.orange_theme .mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, 0.12);
}
.orange_theme .mdc-data-table__row {
  background-color: inherit;
}
.orange_theme .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}
.orange_theme .mdc-data-table__row--selected {
  background-color: rgba(250, 137, 107, 0.04);
}
.orange_theme .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.orange_theme .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.orange_theme .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.orange_theme .mdc-data-table__cell,
.orange_theme .mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.orange_theme .mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.orange_theme .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.orange_theme .mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}
.orange_theme .mdc-data-table__pagination-total,
.orange_theme .mdc-data-table__pagination-rows-per-page-label,
.orange_theme .mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}
[dir=rtl] .orange_theme .mdc-data-table__pagination-button .mdc-button__icon, .orange_theme .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.orange_theme .mat-mdc-table {
  background: white;
}
.orange_theme .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #fa896b;
}
.orange_theme .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #0074ba;
}
.orange_theme .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}
.orange_theme .mat-badge-content {
  color: white;
  background: #fa896b;
}
.cdk-high-contrast-active .orange_theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.orange_theme .mat-badge-accent .mat-badge-content {
  background: #0074ba;
  color: white;
}
.orange_theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.orange_theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.orange_theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.orange_theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.orange_theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.orange_theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.orange_theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.orange_theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.orange_theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.orange_theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.orange_theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.orange_theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.orange_theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.orange_theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.orange_theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.orange_theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.orange_theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.orange_theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.orange_theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.orange_theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.orange_theme .mat-datepicker-toggle,
.orange_theme .mat-datepicker-content .mat-calendar-next-button,
.orange_theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.orange_theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-calendar-table-header,
.orange_theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.orange_theme .mat-calendar-body-cell-content,
.orange_theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.orange_theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.orange_theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.orange_theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.orange_theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.orange_theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.orange_theme .mat-calendar-body-in-range::before {
  background: rgba(250, 137, 107, 0.2);
}
.orange_theme .mat-calendar-body-comparison-identical,
.orange_theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.orange_theme .mat-calendar-body-comparison-bridge-start::before,
.orange_theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(250, 137, 107, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.orange_theme .mat-calendar-body-comparison-bridge-end::before,
.orange_theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(250, 137, 107, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.orange_theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.orange_theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.orange_theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.orange_theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.orange_theme .mat-calendar-body-selected {
  background-color: #fa896b;
  color: white;
}
.orange_theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(250, 137, 107, 0.4);
}
.orange_theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.orange_theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.orange_theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(250, 137, 107, 0.3);
}
@media (hover: hover) {
  .orange_theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(250, 137, 107, 0.3);
  }
}
.orange_theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.orange_theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(0, 116, 186, 0.2);
}
.orange_theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.orange_theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.orange_theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.orange_theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 116, 186, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.orange_theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.orange_theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 116, 186, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.orange_theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.orange_theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.orange_theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.orange_theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.orange_theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #0074ba;
  color: white;
}
.orange_theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 116, 186, 0.4);
}
.orange_theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.orange_theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.orange_theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 116, 186, 0.3);
}
@media (hover: hover) {
  .orange_theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 116, 186, 0.3);
  }
}
.orange_theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.orange_theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.orange_theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.orange_theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.orange_theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.orange_theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.orange_theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.orange_theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.orange_theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.orange_theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.orange_theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.orange_theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.orange_theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.orange_theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.orange_theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.orange_theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .orange_theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.orange_theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-datepicker-toggle-active {
  color: #fa896b;
}
.orange_theme .mat-datepicker-toggle-active.mat-accent {
  color: #0074ba;
}
.orange_theme .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.orange_theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.orange_theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.orange_theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .orange_theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .orange_theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .orange_theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.orange_theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.orange_theme .mat-expansion-panel-header-description,
.orange_theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.orange_theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.orange_theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.orange_theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.orange_theme .mat-icon.mat-primary {
  color: #fa896b;
}
.orange_theme .mat-icon.mat-accent {
  color: #0074ba;
}
.orange_theme .mat-icon.mat-warn {
  color: #f44336;
}
.orange_theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.orange_theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.orange_theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.orange_theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.orange_theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.orange_theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.orange_theme .mat-step-header.cdk-keyboard-focused, .orange_theme .mat-step-header.cdk-program-focused, .orange_theme .mat-step-header:hover:not([aria-disabled]), .orange_theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.orange_theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .orange_theme .mat-step-header:hover {
    background: none;
  }
}
.orange_theme .mat-step-header .mat-step-label,
.orange_theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.orange_theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.orange_theme .mat-step-header .mat-step-icon-selected,
.orange_theme .mat-step-header .mat-step-icon-state-done,
.orange_theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #fa896b;
  color: white;
}
.orange_theme .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.orange_theme .mat-step-header.mat-accent .mat-step-icon-selected,
.orange_theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.orange_theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #0074ba;
  color: white;
}
.orange_theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.orange_theme .mat-step-header.mat-warn .mat-step-icon-selected,
.orange_theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.orange_theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.orange_theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.orange_theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.orange_theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.orange_theme .mat-stepper-horizontal, .orange_theme .mat-stepper-vertical {
  background-color: white;
}
.orange_theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-horizontal-stepper-header::before,
.orange_theme .mat-horizontal-stepper-header::after,
.orange_theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.orange_theme .mat-sort-header-arrow {
  color: #757575;
}
.orange_theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.orange_theme .mat-toolbar.mat-primary {
  background: #fa896b;
  color: white;
}
.orange_theme .mat-toolbar.mat-accent {
  background: #0074ba;
  color: white;
}
.orange_theme .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.orange_theme .mat-toolbar .mat-form-field-underline,
.orange_theme .mat-toolbar .mat-form-field-ripple,
.orange_theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.orange_theme .mat-toolbar .mat-form-field-label,
.orange_theme .mat-toolbar .mat-focused .mat-form-field-label,
.orange_theme .mat-toolbar .mat-select-value,
.orange_theme .mat-toolbar .mat-select-arrow,
.orange_theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.orange_theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.orange_theme .mat-tree {
  background: white;
}
.orange_theme .mat-tree-node,
.orange_theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.aqua_theme .bg-primary {
  background: #0074ba !important;
}
.aqua_theme .horizontal-navbar .parentBox.pactive > a,
.aqua_theme .horizontal-navbar .parentBox.pactive > a:hover,
.aqua_theme .horizontal-navbar .parentBox.pactive:hover > a {
  background-color: #0074ba !important;
}
.aqua_theme .activeMenu {
  background-color: #0074ba !important;
}
.aqua_theme .mat-mdc-outlined-button.mat-primary {
  border-color: #0074ba !important;
}
.aqua_theme .mat-mdc-outlined-button.mat-accent {
  border-color: #47d7bc !important;
}
.aqua_theme .mat-mdc-outlined-button.mat-warn {
  border-color: #f44336 !important;
}
.aqua_theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.aqua_theme .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.aqua_theme .mat-mdc-option:hover:not(.mdc-list-item--disabled), .aqua_theme .mat-mdc-option:focus.mdc-list-item, .aqua_theme .mat-mdc-option.mat-mdc-option-active.mdc-list-item, .aqua_theme .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.aqua_theme .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #0074ba);
}
.aqua_theme .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #47d7bc);
}
.aqua_theme .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.aqua_theme .mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.aqua_theme .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.aqua_theme .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.aqua_theme .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #0074ba;
}
.aqua_theme .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.aqua_theme .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #0074ba;
}
.aqua_theme .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.aqua_theme .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.aqua_theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.aqua_theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #47d7bc;
}
.aqua_theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.aqua_theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #47d7bc;
}
.aqua_theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.aqua_theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.aqua_theme .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.aqua_theme .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #47d7bc;
}
.aqua_theme .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.aqua_theme .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #47d7bc;
}
.aqua_theme .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.aqua_theme .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.aqua_theme .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.aqua_theme .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.aqua_theme .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.aqua_theme .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.aqua_theme .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.aqua_theme .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.aqua_theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.aqua_theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.aqua_theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.aqua_theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}
.aqua_theme .mat-app-background, .aqua_theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.aqua_theme .mat-elevation-z0, .aqua_theme .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-elevation-z1, .aqua_theme .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-elevation-z2, .aqua_theme .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-elevation-z3, .aqua_theme .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-elevation-z4, .aqua_theme .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-elevation-z5, .aqua_theme .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-elevation-z6, .aqua_theme .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-elevation-z7, .aqua_theme .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-elevation-z8, .aqua_theme .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-elevation-z9, .aqua_theme .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-elevation-z10, .aqua_theme .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-elevation-z11, .aqua_theme .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-elevation-z12, .aqua_theme .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-elevation-z13, .aqua_theme .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-elevation-z14, .aqua_theme .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-elevation-z15, .aqua_theme .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-elevation-z16, .aqua_theme .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-elevation-z17, .aqua_theme .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-elevation-z18, .aqua_theme .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-elevation-z19, .aqua_theme .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-elevation-z20, .aqua_theme .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-elevation-z21, .aqua_theme .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-elevation-z22, .aqua_theme .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-elevation-z23, .aqua_theme .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-elevation-z24, .aqua_theme .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.aqua_theme .mat-mdc-card {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}
.aqua_theme .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #0074ba;
  --mdc-linear-progress-track-color: rgba(0, 116, 186, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.aqua_theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(0, 116, 186, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(0, 116, 186, 0.25));
}
@media (forced-colors: active) {
  .aqua_theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .aqua_theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(0, 116, 186, 0.25)'/%3E%3C/svg%3E");
  }
}
.aqua_theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(0, 116, 186, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(0, 116, 186, 0.25));
}
.aqua_theme .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #47d7bc;
  --mdc-linear-progress-track-color: rgba(71, 215, 188, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.aqua_theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(71, 215, 188, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(71, 215, 188, 0.25));
}
@media (forced-colors: active) {
  .aqua_theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .aqua_theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(71, 215, 188, 0.25)'/%3E%3C/svg%3E");
  }
}
.aqua_theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(71, 215, 188, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(71, 215, 188, 0.25));
}
.aqua_theme .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.aqua_theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
@media (forced-colors: active) {
  .aqua_theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .aqua_theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 67, 54, 0.25)'/%3E%3C/svg%3E");
  }
}
.aqua_theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
.aqua_theme .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
.aqua_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.aqua_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .aqua_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  .aqua_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
.aqua_theme .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #0074ba);
}
.aqua_theme .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.aqua_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.aqua_theme .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.aqua_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.aqua_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.aqua_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.aqua_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.aqua_theme .mdc-text-field--filled .mdc-text-field__ripple::before,
.aqua_theme .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.aqua_theme .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .aqua_theme .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.aqua_theme .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .aqua_theme .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.aqua_theme .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.aqua_theme .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.aqua_theme .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.aqua_theme .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #0074ba);
}
.aqua_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.aqua_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.aqua_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.aqua_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.aqua_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.aqua_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.aqua_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.aqua_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.aqua_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #0074ba);
}
[dir=rtl] .aqua_theme .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .aqua_theme .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .aqua_theme .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .aqua_theme .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.aqua_theme .mdc-text-field--outlined .mdc-text-field__ripple::before,
.aqua_theme .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .aqua_theme .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .aqua_theme .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.aqua_theme .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 116, 186, 0.87);
}
.aqua_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.aqua_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.aqua_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.aqua_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.aqua_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.aqua_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.aqua_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .aqua_theme .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .aqua_theme .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.aqua_theme .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.aqua_theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.aqua_theme .mdc-text-field--disabled .mdc-text-field-character-counter,
.aqua_theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.aqua_theme .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.aqua_theme .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.aqua_theme .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.aqua_theme .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.aqua_theme .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.aqua_theme .mdc-text-field--disabled .mdc-notched-outline__leading,
.aqua_theme .mdc-text-field--disabled .mdc-notched-outline__notch,
.aqua_theme .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .aqua_theme .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .aqua_theme .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
  .aqua_theme .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
  .aqua_theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
  .aqua_theme .mdc-text-field--disabled .mdc-text-field-character-counter,
  .aqua_theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
  .aqua_theme .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
  .aqua_theme .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
  .aqua_theme .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
  .aqua_theme .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
  .aqua_theme .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
  .aqua_theme .mdc-text-field--disabled .mdc-notched-outline__leading,
  .aqua_theme .mdc-text-field--disabled .mdc-notched-outline__notch,
  .aqua_theme .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.aqua_theme .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] .aqua_theme .mdc-text-field--end-aligned .mdc-text-field__input, .aqua_theme .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .aqua_theme .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .aqua_theme .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.aqua_theme .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}
.aqua_theme .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}
.aqua_theme .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}
.aqua_theme .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.aqua_theme .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(0, 116, 186, 0.87);
}
.aqua_theme .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(71, 215, 188, 0.87);
}
.aqua_theme .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(244, 67, 54, 0.87);
}
.aqua_theme .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.aqua_theme .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #47d7bc);
}
.aqua_theme .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #47d7bc);
}
.aqua_theme .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(71, 215, 188, 0.87);
}
.aqua_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.aqua_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.aqua_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.aqua_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.aqua_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.aqua_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.aqua_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.aqua_theme .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.aqua_theme .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #47d7bc);
}
.aqua_theme .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(244, 67, 54, 0.87);
}
.aqua_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.aqua_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.aqua_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.aqua_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.aqua_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.aqua_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.aqua_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.aqua_theme .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.aqua_theme .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.aqua_theme .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.aqua_theme [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.aqua_theme .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.aqua_theme .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.aqua_theme .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.aqua_theme .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.aqua_theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.aqua_theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.aqua_theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.aqua_theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.aqua_theme .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.aqua_theme .mdc-list-item--disabled .mdc-list-item__start,
.aqua_theme .mdc-list-item--disabled .mdc-list-item__content,
.aqua_theme .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.aqua_theme .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.aqua_theme .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.aqua_theme .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.aqua_theme .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.aqua_theme .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.aqua_theme .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.aqua_theme .mdc-list-item--selected .mdc-list-item__primary-text,
.aqua_theme .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #0074ba);
}
.aqua_theme .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.aqua_theme .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #0074ba);
}
.aqua_theme .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.aqua_theme .mdc-list-divider::after {
  border-bottom-color: white;
}
.aqua_theme .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.aqua_theme .mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.aqua_theme .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.aqua_theme .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.aqua_theme .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(0, 116, 186, 0.87);
}
.aqua_theme .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(71, 215, 188, 0.87);
}
.aqua_theme .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(244, 67, 54, 0.87);
}
.aqua_theme .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(244, 67, 54, 0.87);
}
.aqua_theme .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.aqua_theme .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.aqua_theme .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.aqua_theme .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.aqua_theme .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.aqua_theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.aqua_theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.aqua_theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.aqua_theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.aqua_theme .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.aqua_theme .mdc-list-item--disabled .mdc-list-item__start,
.aqua_theme .mdc-list-item--disabled .mdc-list-item__content,
.aqua_theme .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.aqua_theme .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.aqua_theme .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.aqua_theme .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.aqua_theme .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.aqua_theme .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.aqua_theme .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.aqua_theme .mdc-list-item--selected .mdc-list-item__primary-text,
.aqua_theme .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #0074ba);
}
.aqua_theme .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.aqua_theme .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #0074ba);
}
.aqua_theme .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.aqua_theme .mdc-list-divider::after {
  border-bottom-color: white;
}
.aqua_theme .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
.aqua_theme .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
.aqua_theme .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .aqua_theme .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #0074ba;
  --mdc-chip-elevated-disabled-container-color: #0074ba;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.aqua_theme .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .aqua_theme .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #47d7bc;
  --mdc-chip-elevated-disabled-container-color: #47d7bc;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.aqua_theme .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .aqua_theme .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.aqua_theme .mat-mdc-chip-focus-overlay {
  background: black;
}
.aqua_theme .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.aqua_theme .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.aqua_theme .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.aqua_theme .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}
.aqua_theme .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.aqua_theme .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #0074ba;
  --mdc-radio-selected-hover-icon-color: #0074ba;
  --mdc-radio-selected-icon-color: #0074ba;
  --mdc-radio-selected-pressed-icon-color: #0074ba;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #0074ba;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.aqua_theme .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #47d7bc;
  --mdc-radio-selected-hover-icon-color: #47d7bc;
  --mdc-radio-selected-icon-color: #47d7bc;
  --mdc-radio-selected-pressed-icon-color: #47d7bc;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #47d7bc;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.aqua_theme .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.aqua_theme .mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.aqua_theme .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #0074ba;
  --mdc-slider-focus-handle-color: #0074ba;
  --mdc-slider-hover-handle-color: #0074ba;
  --mdc-slider-active-track-color: #0074ba;
  --mdc-slider-inactive-track-color: #0074ba;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #0074ba;
  --mat-mdc-slider-ripple-color: #0074ba;
  --mat-mdc-slider-hover-ripple-color: rgba(0, 116, 186, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(0, 116, 186, 0.2);
}
.aqua_theme .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #47d7bc;
  --mdc-slider-focus-handle-color: #47d7bc;
  --mdc-slider-hover-handle-color: #47d7bc;
  --mdc-slider-active-track-color: #47d7bc;
  --mdc-slider-inactive-track-color: #47d7bc;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #47d7bc;
  --mat-mdc-slider-ripple-color: #47d7bc;
  --mat-mdc-slider-hover-ripple-color: rgba(71, 215, 188, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(71, 215, 188, 0.2);
}
.aqua_theme .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mat-mdc-slider-ripple-color: #f44336;
  --mat-mdc-slider-hover-ripple-color: rgba(244, 67, 54, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(244, 67, 54, 0.2);
}
.aqua_theme .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.aqua_theme .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.aqua_theme .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.aqua_theme .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.aqua_theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.aqua_theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.aqua_theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.aqua_theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.aqua_theme .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.aqua_theme .mdc-list-item--disabled .mdc-list-item__start,
.aqua_theme .mdc-list-item--disabled .mdc-list-item__content,
.aqua_theme .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.aqua_theme .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.aqua_theme .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.aqua_theme .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.aqua_theme .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.aqua_theme .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.aqua_theme .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.aqua_theme .mdc-list-item--selected .mdc-list-item__primary-text,
.aqua_theme .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #0074ba);
}
.aqua_theme .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.aqua_theme .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #0074ba);
}
.aqua_theme .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.aqua_theme .mdc-list-divider::after {
  border-bottom-color: white;
}
.aqua_theme .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-mdc-menu-item[disabled],
.aqua_theme .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.aqua_theme .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}
.aqua_theme .mat-mdc-menu-item .mat-icon-no-color,
.aqua_theme .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.aqua_theme .mat-mdc-menu-item:hover:not([disabled]),
.aqua_theme .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.aqua_theme .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.aqua_theme .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.aqua_theme .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}
.aqua_theme .mdc-list-item__start,
.aqua_theme .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #0074ba;
  --mdc-radio-selected-hover-icon-color: #0074ba;
  --mdc-radio-selected-icon-color: #0074ba;
  --mdc-radio-selected-pressed-icon-color: #0074ba;
}
.aqua_theme .mat-accent .mdc-list-item__start,
.aqua_theme .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #47d7bc;
  --mdc-radio-selected-hover-icon-color: #47d7bc;
  --mdc-radio-selected-icon-color: #47d7bc;
  --mdc-radio-selected-pressed-icon-color: #47d7bc;
}
.aqua_theme .mat-warn .mdc-list-item__start,
.aqua_theme .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}
.aqua_theme .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #0074ba;
  --mdc-checkbox-selected-hover-icon-color: #0074ba;
  --mdc-checkbox-selected-icon-color: #0074ba;
  --mdc-checkbox-selected-pressed-icon-color: #0074ba;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #0074ba;
  --mdc-checkbox-selected-hover-state-layer-color: #0074ba;
  --mdc-checkbox-selected-pressed-state-layer-color: #0074ba;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.aqua_theme .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #47d7bc;
  --mdc-checkbox-selected-hover-icon-color: #47d7bc;
  --mdc-checkbox-selected-icon-color: #47d7bc;
  --mdc-checkbox-selected-pressed-icon-color: #47d7bc;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #47d7bc;
  --mdc-checkbox-selected-hover-state-layer-color: #47d7bc;
  --mdc-checkbox-selected-pressed-state-layer-color: #47d7bc;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.aqua_theme .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.aqua_theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.aqua_theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #0074ba;
}
.aqua_theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.aqua_theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #0074ba;
}
.aqua_theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.aqua_theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.aqua_theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.aqua_theme .mat-mdc-paginator {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.aqua_theme .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.54);
}
.aqua_theme .mat-mdc-paginator-decrement,
.aqua_theme .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.aqua_theme .mat-mdc-paginator-first,
.aqua_theme .mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.aqua_theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.aqua_theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.aqua_theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.aqua_theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-mdc-tab-group, .aqua_theme .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #0074ba;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #0074ba;
  --mat-tab-header-active-ripple-color: #0074ba;
  --mat-tab-header-inactive-ripple-color: #0074ba;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #0074ba;
  --mat-tab-header-active-hover-label-text-color: #0074ba;
  --mat-tab-header-active-focus-indicator-color: #0074ba;
  --mat-tab-header-active-hover-indicator-color: #0074ba;
}
.aqua_theme .mat-mdc-tab-group.mat-accent, .aqua_theme .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #47d7bc;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #47d7bc;
  --mat-tab-header-active-ripple-color: #47d7bc;
  --mat-tab-header-inactive-ripple-color: #47d7bc;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #47d7bc;
  --mat-tab-header-active-hover-label-text-color: #47d7bc;
  --mat-tab-header-active-focus-indicator-color: #47d7bc;
  --mat-tab-header-active-hover-indicator-color: #47d7bc;
}
.aqua_theme .mat-mdc-tab-group.mat-warn, .aqua_theme .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.aqua_theme .mat-mdc-tab-group.mat-background-primary, .aqua_theme .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #0074ba;
  --mat-tab-header-with-background-foreground-color: white;
}
.aqua_theme .mat-mdc-tab-group.mat-background-accent, .aqua_theme .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #47d7bc;
  --mat-tab-header-with-background-foreground-color: white;
}
.aqua_theme .mat-mdc-tab-group.mat-background-warn, .aqua_theme .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}
.aqua_theme .mat-mdc-checkbox {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #47d7bc;
  --mdc-checkbox-selected-hover-icon-color: #47d7bc;
  --mdc-checkbox-selected-icon-color: #47d7bc;
  --mdc-checkbox-selected-pressed-icon-color: #47d7bc;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #47d7bc;
  --mdc-checkbox-selected-hover-state-layer-color: #47d7bc;
  --mdc-checkbox-selected-pressed-state-layer-color: #47d7bc;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.aqua_theme .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #0074ba;
  --mdc-checkbox-selected-hover-icon-color: #0074ba;
  --mdc-checkbox-selected-icon-color: #0074ba;
  --mdc-checkbox-selected-pressed-icon-color: #0074ba;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #0074ba;
  --mdc-checkbox-selected-hover-state-layer-color: #0074ba;
  --mdc-checkbox-selected-pressed-state-layer-color: #0074ba;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.aqua_theme .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.aqua_theme .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.aqua_theme .mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}
.aqua_theme .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.aqua_theme .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #0074ba;
}
.aqua_theme .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #47d7bc;
}
.aqua_theme .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.aqua_theme .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.aqua_theme .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.aqua_theme .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #0074ba;
  --mdc-filled-button-label-text-color: #fff;
}
.aqua_theme .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #47d7bc;
  --mdc-filled-button-label-text-color: #000;
}
.aqua_theme .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.aqua_theme .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.aqua_theme .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.aqua_theme .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #0074ba;
  --mdc-protected-button-label-text-color: #fff;
}
.aqua_theme .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #47d7bc;
  --mdc-protected-button-label-text-color: #000;
}
.aqua_theme .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.aqua_theme .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.aqua_theme .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.aqua_theme .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #0074ba;
}
.aqua_theme .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #47d7bc;
}
.aqua_theme .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.aqua_theme .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-mdc-button, .aqua_theme .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.aqua_theme .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .aqua_theme .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.aqua_theme .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .aqua_theme .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .aqua_theme .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .aqua_theme .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.aqua_theme .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .aqua_theme .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.aqua_theme .mat-mdc-button.mat-primary, .aqua_theme .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #0074ba;
  --mat-mdc-button-ripple-color: rgba(0, 116, 186, 0.1);
}
.aqua_theme .mat-mdc-button.mat-accent, .aqua_theme .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #47d7bc;
  --mat-mdc-button-ripple-color: rgba(71, 215, 188, 0.1);
}
.aqua_theme .mat-mdc-button.mat-warn, .aqua_theme .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.aqua_theme .mat-mdc-raised-button, .aqua_theme .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.aqua_theme .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .aqua_theme .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.aqua_theme .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .aqua_theme .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .aqua_theme .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .aqua_theme .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.aqua_theme .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .aqua_theme .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.aqua_theme .mat-mdc-raised-button.mat-primary, .aqua_theme .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.aqua_theme .mat-mdc-raised-button.mat-accent, .aqua_theme .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.aqua_theme .mat-mdc-raised-button.mat-warn, .aqua_theme .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.aqua_theme .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.aqua_theme .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.aqua_theme .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .aqua_theme .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.aqua_theme .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.aqua_theme .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.aqua_theme .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.aqua_theme .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.aqua_theme .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #0074ba;
  --mat-mdc-button-persistent-ripple-color: #0074ba;
  --mat-mdc-button-ripple-color: rgba(0, 116, 186, 0.1);
}
.aqua_theme .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #47d7bc;
  --mat-mdc-button-persistent-ripple-color: #47d7bc;
  --mat-mdc-button-ripple-color: rgba(71, 215, 188, 0.1);
}
.aqua_theme .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.aqua_theme .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.aqua_theme .mat-mdc-fab, .aqua_theme .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.aqua_theme .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .aqua_theme .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.aqua_theme .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .aqua_theme .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .aqua_theme .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .aqua_theme .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.aqua_theme .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .aqua_theme .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.aqua_theme .mat-mdc-fab.mat-primary, .aqua_theme .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.aqua_theme .mat-mdc-fab.mat-accent, .aqua_theme .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.aqua_theme .mat-mdc-fab.mat-warn, .aqua_theme .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.aqua_theme .mat-mdc-fab.mat-unthemed, .aqua_theme .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.aqua_theme .mat-mdc-fab.mat-primary, .aqua_theme .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #0074ba;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.aqua_theme .mat-mdc-fab.mat-accent, .aqua_theme .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #47d7bc;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.aqua_theme .mat-mdc-fab.mat-warn, .aqua_theme .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.aqua_theme .mat-mdc-fab[disabled][disabled], .aqua_theme .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.aqua_theme .mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #47d7bc;
}
.aqua_theme .mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, 0.12);
}
.aqua_theme .mdc-data-table__row {
  background-color: inherit;
}
.aqua_theme .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}
.aqua_theme .mdc-data-table__row--selected {
  background-color: rgba(0, 116, 186, 0.04);
}
.aqua_theme .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.aqua_theme .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.aqua_theme .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.aqua_theme .mdc-data-table__cell,
.aqua_theme .mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.aqua_theme .mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.aqua_theme .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.aqua_theme .mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}
.aqua_theme .mdc-data-table__pagination-total,
.aqua_theme .mdc-data-table__pagination-rows-per-page-label,
.aqua_theme .mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}
[dir=rtl] .aqua_theme .mdc-data-table__pagination-button .mdc-button__icon, .aqua_theme .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.aqua_theme .mat-mdc-table {
  background: white;
}
.aqua_theme .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #0074ba;
}
.aqua_theme .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #47d7bc;
}
.aqua_theme .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}
.aqua_theme .mat-badge-content {
  color: white;
  background: #0074ba;
}
.cdk-high-contrast-active .aqua_theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.aqua_theme .mat-badge-accent .mat-badge-content {
  background: #47d7bc;
  color: white;
}
.aqua_theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.aqua_theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.aqua_theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.aqua_theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.aqua_theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.aqua_theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.aqua_theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.aqua_theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.aqua_theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.aqua_theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.aqua_theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.aqua_theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.aqua_theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.aqua_theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.aqua_theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.aqua_theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.aqua_theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.aqua_theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.aqua_theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.aqua_theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.aqua_theme .mat-datepicker-toggle,
.aqua_theme .mat-datepicker-content .mat-calendar-next-button,
.aqua_theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.aqua_theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-calendar-table-header,
.aqua_theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.aqua_theme .mat-calendar-body-cell-content,
.aqua_theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.aqua_theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.aqua_theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.aqua_theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.aqua_theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.aqua_theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.aqua_theme .mat-calendar-body-in-range::before {
  background: rgba(0, 116, 186, 0.2);
}
.aqua_theme .mat-calendar-body-comparison-identical,
.aqua_theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.aqua_theme .mat-calendar-body-comparison-bridge-start::before,
.aqua_theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 116, 186, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.aqua_theme .mat-calendar-body-comparison-bridge-end::before,
.aqua_theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 116, 186, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.aqua_theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.aqua_theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.aqua_theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.aqua_theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.aqua_theme .mat-calendar-body-selected {
  background-color: #0074ba;
  color: white;
}
.aqua_theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 116, 186, 0.4);
}
.aqua_theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.aqua_theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.aqua_theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 116, 186, 0.3);
}
@media (hover: hover) {
  .aqua_theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 116, 186, 0.3);
  }
}
.aqua_theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.aqua_theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(71, 215, 188, 0.2);
}
.aqua_theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.aqua_theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.aqua_theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.aqua_theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(71, 215, 188, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.aqua_theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.aqua_theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(71, 215, 188, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.aqua_theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.aqua_theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.aqua_theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.aqua_theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.aqua_theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #47d7bc;
  color: white;
}
.aqua_theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(71, 215, 188, 0.4);
}
.aqua_theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.aqua_theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.aqua_theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(71, 215, 188, 0.3);
}
@media (hover: hover) {
  .aqua_theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(71, 215, 188, 0.3);
  }
}
.aqua_theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.aqua_theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.aqua_theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.aqua_theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.aqua_theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.aqua_theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.aqua_theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.aqua_theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.aqua_theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.aqua_theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.aqua_theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.aqua_theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.aqua_theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.aqua_theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.aqua_theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.aqua_theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .aqua_theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.aqua_theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-datepicker-toggle-active {
  color: #0074ba;
}
.aqua_theme .mat-datepicker-toggle-active.mat-accent {
  color: #47d7bc;
}
.aqua_theme .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.aqua_theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.aqua_theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.aqua_theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .aqua_theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .aqua_theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .aqua_theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.aqua_theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.aqua_theme .mat-expansion-panel-header-description,
.aqua_theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.aqua_theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.aqua_theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.aqua_theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.aqua_theme .mat-icon.mat-primary {
  color: #0074ba;
}
.aqua_theme .mat-icon.mat-accent {
  color: #47d7bc;
}
.aqua_theme .mat-icon.mat-warn {
  color: #f44336;
}
.aqua_theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.aqua_theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.aqua_theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.aqua_theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.aqua_theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.aqua_theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.aqua_theme .mat-step-header.cdk-keyboard-focused, .aqua_theme .mat-step-header.cdk-program-focused, .aqua_theme .mat-step-header:hover:not([aria-disabled]), .aqua_theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.aqua_theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .aqua_theme .mat-step-header:hover {
    background: none;
  }
}
.aqua_theme .mat-step-header .mat-step-label,
.aqua_theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.aqua_theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.aqua_theme .mat-step-header .mat-step-icon-selected,
.aqua_theme .mat-step-header .mat-step-icon-state-done,
.aqua_theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #0074ba;
  color: white;
}
.aqua_theme .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.aqua_theme .mat-step-header.mat-accent .mat-step-icon-selected,
.aqua_theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.aqua_theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #47d7bc;
  color: white;
}
.aqua_theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.aqua_theme .mat-step-header.mat-warn .mat-step-icon-selected,
.aqua_theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.aqua_theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.aqua_theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.aqua_theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.aqua_theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.aqua_theme .mat-stepper-horizontal, .aqua_theme .mat-stepper-vertical {
  background-color: white;
}
.aqua_theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-horizontal-stepper-header::before,
.aqua_theme .mat-horizontal-stepper-header::after,
.aqua_theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.aqua_theme .mat-sort-header-arrow {
  color: #757575;
}
.aqua_theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.aqua_theme .mat-toolbar.mat-primary {
  background: #0074ba;
  color: white;
}
.aqua_theme .mat-toolbar.mat-accent {
  background: #47d7bc;
  color: white;
}
.aqua_theme .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.aqua_theme .mat-toolbar .mat-form-field-underline,
.aqua_theme .mat-toolbar .mat-form-field-ripple,
.aqua_theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.aqua_theme .mat-toolbar .mat-form-field-label,
.aqua_theme .mat-toolbar .mat-focused .mat-form-field-label,
.aqua_theme .mat-toolbar .mat-select-value,
.aqua_theme .mat-toolbar .mat-select-arrow,
.aqua_theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.aqua_theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.aqua_theme .mat-tree {
  background: white;
}
.aqua_theme .mat-tree-node,
.aqua_theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.purple_theme .bg-primary {
  background: #763ebd !important;
}
.purple_theme .horizontal-navbar .parentBox.pactive > a,
.purple_theme .horizontal-navbar .parentBox.pactive > a:hover,
.purple_theme .horizontal-navbar .parentBox.pactive:hover > a {
  background-color: #763ebd !important;
}
.purple_theme .activeMenu {
  background-color: #763ebd !important;
}
.purple_theme .mat-mdc-outlined-button.mat-primary {
  border-color: #763ebd !important;
}
.purple_theme .mat-mdc-outlined-button.mat-accent {
  border-color: #95cfd5 !important;
}
.purple_theme .mat-mdc-outlined-button.mat-warn {
  border-color: #f44336 !important;
}
.purple_theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.purple_theme .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.purple_theme .mat-mdc-option:hover:not(.mdc-list-item--disabled), .purple_theme .mat-mdc-option:focus.mdc-list-item, .purple_theme .mat-mdc-option.mat-mdc-option-active.mdc-list-item, .purple_theme .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.purple_theme .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #763ebd);
}
.purple_theme .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #95cfd5);
}
.purple_theme .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.purple_theme .mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.purple_theme .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.purple_theme .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.purple_theme .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #763ebd;
}
.purple_theme .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.purple_theme .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #763ebd;
}
.purple_theme .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.purple_theme .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.purple_theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.purple_theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #95cfd5;
}
.purple_theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.purple_theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #95cfd5;
}
.purple_theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.purple_theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.purple_theme .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.purple_theme .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #95cfd5;
}
.purple_theme .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.purple_theme .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #95cfd5;
}
.purple_theme .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.purple_theme .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.purple_theme .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.purple_theme .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.purple_theme .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.purple_theme .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.purple_theme .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.purple_theme .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.purple_theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.purple_theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.purple_theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.purple_theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}
.purple_theme .mat-app-background, .purple_theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.purple_theme .mat-elevation-z0, .purple_theme .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-elevation-z1, .purple_theme .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-elevation-z2, .purple_theme .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-elevation-z3, .purple_theme .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-elevation-z4, .purple_theme .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-elevation-z5, .purple_theme .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-elevation-z6, .purple_theme .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-elevation-z7, .purple_theme .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-elevation-z8, .purple_theme .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-elevation-z9, .purple_theme .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-elevation-z10, .purple_theme .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-elevation-z11, .purple_theme .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-elevation-z12, .purple_theme .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-elevation-z13, .purple_theme .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-elevation-z14, .purple_theme .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-elevation-z15, .purple_theme .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-elevation-z16, .purple_theme .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-elevation-z17, .purple_theme .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-elevation-z18, .purple_theme .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-elevation-z19, .purple_theme .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-elevation-z20, .purple_theme .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-elevation-z21, .purple_theme .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-elevation-z22, .purple_theme .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-elevation-z23, .purple_theme .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-elevation-z24, .purple_theme .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.purple_theme .mat-mdc-card {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}
.purple_theme .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #763ebd;
  --mdc-linear-progress-track-color: rgba(118, 62, 189, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.purple_theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(118, 62, 189, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(118, 62, 189, 0.25));
}
@media (forced-colors: active) {
  .purple_theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .purple_theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(118, 62, 189, 0.25)'/%3E%3C/svg%3E");
  }
}
.purple_theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(118, 62, 189, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(118, 62, 189, 0.25));
}
.purple_theme .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #95cfd5;
  --mdc-linear-progress-track-color: rgba(149, 207, 213, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.purple_theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(149, 207, 213, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(149, 207, 213, 0.25));
}
@media (forced-colors: active) {
  .purple_theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .purple_theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(149, 207, 213, 0.25)'/%3E%3C/svg%3E");
  }
}
.purple_theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(149, 207, 213, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(149, 207, 213, 0.25));
}
.purple_theme .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.purple_theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
@media (forced-colors: active) {
  .purple_theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .purple_theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 67, 54, 0.25)'/%3E%3C/svg%3E");
  }
}
.purple_theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
.purple_theme .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
.purple_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.purple_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .purple_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  .purple_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
.purple_theme .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #763ebd);
}
.purple_theme .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.purple_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.purple_theme .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.purple_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.purple_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.purple_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.purple_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.purple_theme .mdc-text-field--filled .mdc-text-field__ripple::before,
.purple_theme .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.purple_theme .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .purple_theme .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.purple_theme .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .purple_theme .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.purple_theme .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.purple_theme .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.purple_theme .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.purple_theme .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #763ebd);
}
.purple_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.purple_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.purple_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.purple_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.purple_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.purple_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.purple_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.purple_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.purple_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #763ebd);
}
[dir=rtl] .purple_theme .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .purple_theme .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .purple_theme .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .purple_theme .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.purple_theme .mdc-text-field--outlined .mdc-text-field__ripple::before,
.purple_theme .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .purple_theme .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .purple_theme .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.purple_theme .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(118, 62, 189, 0.87);
}
.purple_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.purple_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.purple_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.purple_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.purple_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.purple_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.purple_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .purple_theme .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .purple_theme .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.purple_theme .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.purple_theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.purple_theme .mdc-text-field--disabled .mdc-text-field-character-counter,
.purple_theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.purple_theme .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.purple_theme .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.purple_theme .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.purple_theme .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.purple_theme .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.purple_theme .mdc-text-field--disabled .mdc-notched-outline__leading,
.purple_theme .mdc-text-field--disabled .mdc-notched-outline__notch,
.purple_theme .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .purple_theme .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .purple_theme .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
  .purple_theme .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
  .purple_theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
  .purple_theme .mdc-text-field--disabled .mdc-text-field-character-counter,
  .purple_theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
  .purple_theme .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
  .purple_theme .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
  .purple_theme .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
  .purple_theme .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
  .purple_theme .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
  .purple_theme .mdc-text-field--disabled .mdc-notched-outline__leading,
  .purple_theme .mdc-text-field--disabled .mdc-notched-outline__notch,
  .purple_theme .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.purple_theme .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] .purple_theme .mdc-text-field--end-aligned .mdc-text-field__input, .purple_theme .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .purple_theme .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .purple_theme .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.purple_theme .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}
.purple_theme .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}
.purple_theme .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}
.purple_theme .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.purple_theme .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(118, 62, 189, 0.87);
}
.purple_theme .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(149, 207, 213, 0.87);
}
.purple_theme .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(244, 67, 54, 0.87);
}
.purple_theme .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.purple_theme .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #95cfd5);
}
.purple_theme .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #95cfd5);
}
.purple_theme .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(149, 207, 213, 0.87);
}
.purple_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.purple_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.purple_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.purple_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.purple_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.purple_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.purple_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.purple_theme .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.purple_theme .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #95cfd5);
}
.purple_theme .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(244, 67, 54, 0.87);
}
.purple_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.purple_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.purple_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.purple_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.purple_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.purple_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.purple_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.purple_theme .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.purple_theme .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.purple_theme .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.purple_theme [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.purple_theme .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.purple_theme .mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.purple_theme .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.purple_theme .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.purple_theme .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.purple_theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.purple_theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.purple_theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.purple_theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.purple_theme .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.purple_theme .mdc-list-item--disabled .mdc-list-item__start,
.purple_theme .mdc-list-item--disabled .mdc-list-item__content,
.purple_theme .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.purple_theme .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.purple_theme .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.purple_theme .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.purple_theme .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.purple_theme .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.purple_theme .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.purple_theme .mdc-list-item--selected .mdc-list-item__primary-text,
.purple_theme .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #763ebd);
}
.purple_theme .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.purple_theme .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #763ebd);
}
.purple_theme .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.purple_theme .mdc-list-divider::after {
  border-bottom-color: white;
}
.purple_theme .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.purple_theme .mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.purple_theme .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.purple_theme .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.purple_theme .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(118, 62, 189, 0.87);
}
.purple_theme .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(149, 207, 213, 0.87);
}
.purple_theme .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(244, 67, 54, 0.87);
}
.purple_theme .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(244, 67, 54, 0.87);
}
.purple_theme .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.purple_theme .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.purple_theme .mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.purple_theme .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.purple_theme .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.purple_theme .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.purple_theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.purple_theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.purple_theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.purple_theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.purple_theme .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.purple_theme .mdc-list-item--disabled .mdc-list-item__start,
.purple_theme .mdc-list-item--disabled .mdc-list-item__content,
.purple_theme .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.purple_theme .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.purple_theme .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.purple_theme .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.purple_theme .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.purple_theme .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.purple_theme .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.purple_theme .mdc-list-item--selected .mdc-list-item__primary-text,
.purple_theme .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #763ebd);
}
.purple_theme .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.purple_theme .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #763ebd);
}
.purple_theme .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.purple_theme .mdc-list-divider::after {
  border-bottom-color: white;
}
.purple_theme .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
.purple_theme .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
.purple_theme .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .purple_theme .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #763ebd;
  --mdc-chip-elevated-disabled-container-color: #763ebd;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.purple_theme .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .purple_theme .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #95cfd5;
  --mdc-chip-elevated-disabled-container-color: #95cfd5;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.purple_theme .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .purple_theme .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.purple_theme .mat-mdc-chip-focus-overlay {
  background: black;
}
.purple_theme .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.purple_theme .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.purple_theme .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.purple_theme .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}
.purple_theme .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.purple_theme .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #763ebd;
  --mdc-radio-selected-hover-icon-color: #763ebd;
  --mdc-radio-selected-icon-color: #763ebd;
  --mdc-radio-selected-pressed-icon-color: #763ebd;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #763ebd;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.purple_theme .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #95cfd5;
  --mdc-radio-selected-hover-icon-color: #95cfd5;
  --mdc-radio-selected-icon-color: #95cfd5;
  --mdc-radio-selected-pressed-icon-color: #95cfd5;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #95cfd5;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.purple_theme .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.purple_theme .mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.purple_theme .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #763ebd;
  --mdc-slider-focus-handle-color: #763ebd;
  --mdc-slider-hover-handle-color: #763ebd;
  --mdc-slider-active-track-color: #763ebd;
  --mdc-slider-inactive-track-color: #763ebd;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #763ebd;
  --mat-mdc-slider-ripple-color: #763ebd;
  --mat-mdc-slider-hover-ripple-color: rgba(118, 62, 189, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(118, 62, 189, 0.2);
}
.purple_theme .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #95cfd5;
  --mdc-slider-focus-handle-color: #95cfd5;
  --mdc-slider-hover-handle-color: #95cfd5;
  --mdc-slider-active-track-color: #95cfd5;
  --mdc-slider-inactive-track-color: #95cfd5;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #95cfd5;
  --mat-mdc-slider-ripple-color: #95cfd5;
  --mat-mdc-slider-hover-ripple-color: rgba(149, 207, 213, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(149, 207, 213, 0.2);
}
.purple_theme .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mat-mdc-slider-ripple-color: #f44336;
  --mat-mdc-slider-hover-ripple-color: rgba(244, 67, 54, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(244, 67, 54, 0.2);
}
.purple_theme .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.purple_theme .mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.purple_theme .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.purple_theme .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.purple_theme .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.purple_theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.purple_theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.purple_theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.purple_theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.purple_theme .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.purple_theme .mdc-list-item--disabled .mdc-list-item__start,
.purple_theme .mdc-list-item--disabled .mdc-list-item__content,
.purple_theme .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.purple_theme .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.purple_theme .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.purple_theme .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.purple_theme .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.purple_theme .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.purple_theme .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.purple_theme .mdc-list-item--selected .mdc-list-item__primary-text,
.purple_theme .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #763ebd);
}
.purple_theme .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.purple_theme .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #763ebd);
}
.purple_theme .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.purple_theme .mdc-list-divider::after {
  border-bottom-color: white;
}
.purple_theme .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-mdc-menu-item[disabled],
.purple_theme .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.purple_theme .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}
.purple_theme .mat-mdc-menu-item .mat-icon-no-color,
.purple_theme .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.purple_theme .mat-mdc-menu-item:hover:not([disabled]),
.purple_theme .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.purple_theme .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.purple_theme .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.purple_theme .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}
.purple_theme .mdc-list-item__start,
.purple_theme .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #763ebd;
  --mdc-radio-selected-hover-icon-color: #763ebd;
  --mdc-radio-selected-icon-color: #763ebd;
  --mdc-radio-selected-pressed-icon-color: #763ebd;
}
.purple_theme .mat-accent .mdc-list-item__start,
.purple_theme .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #95cfd5;
  --mdc-radio-selected-hover-icon-color: #95cfd5;
  --mdc-radio-selected-icon-color: #95cfd5;
  --mdc-radio-selected-pressed-icon-color: #95cfd5;
}
.purple_theme .mat-warn .mdc-list-item__start,
.purple_theme .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}
.purple_theme .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #763ebd;
  --mdc-checkbox-selected-hover-icon-color: #763ebd;
  --mdc-checkbox-selected-icon-color: #763ebd;
  --mdc-checkbox-selected-pressed-icon-color: #763ebd;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #763ebd;
  --mdc-checkbox-selected-hover-state-layer-color: #763ebd;
  --mdc-checkbox-selected-pressed-state-layer-color: #763ebd;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.purple_theme .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #95cfd5;
  --mdc-checkbox-selected-hover-icon-color: #95cfd5;
  --mdc-checkbox-selected-icon-color: #95cfd5;
  --mdc-checkbox-selected-pressed-icon-color: #95cfd5;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #95cfd5;
  --mdc-checkbox-selected-hover-state-layer-color: #95cfd5;
  --mdc-checkbox-selected-pressed-state-layer-color: #95cfd5;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.purple_theme .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.purple_theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.purple_theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #763ebd;
}
.purple_theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.purple_theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #763ebd;
}
.purple_theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.purple_theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.purple_theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.purple_theme .mat-mdc-paginator {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.purple_theme .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.54);
}
.purple_theme .mat-mdc-paginator-decrement,
.purple_theme .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.purple_theme .mat-mdc-paginator-first,
.purple_theme .mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.purple_theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.purple_theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.purple_theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.purple_theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-mdc-tab-group, .purple_theme .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #763ebd;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #763ebd;
  --mat-tab-header-active-ripple-color: #763ebd;
  --mat-tab-header-inactive-ripple-color: #763ebd;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #763ebd;
  --mat-tab-header-active-hover-label-text-color: #763ebd;
  --mat-tab-header-active-focus-indicator-color: #763ebd;
  --mat-tab-header-active-hover-indicator-color: #763ebd;
}
.purple_theme .mat-mdc-tab-group.mat-accent, .purple_theme .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #95cfd5;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #95cfd5;
  --mat-tab-header-active-ripple-color: #95cfd5;
  --mat-tab-header-inactive-ripple-color: #95cfd5;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #95cfd5;
  --mat-tab-header-active-hover-label-text-color: #95cfd5;
  --mat-tab-header-active-focus-indicator-color: #95cfd5;
  --mat-tab-header-active-hover-indicator-color: #95cfd5;
}
.purple_theme .mat-mdc-tab-group.mat-warn, .purple_theme .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.purple_theme .mat-mdc-tab-group.mat-background-primary, .purple_theme .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #763ebd;
  --mat-tab-header-with-background-foreground-color: white;
}
.purple_theme .mat-mdc-tab-group.mat-background-accent, .purple_theme .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #95cfd5;
  --mat-tab-header-with-background-foreground-color: white;
}
.purple_theme .mat-mdc-tab-group.mat-background-warn, .purple_theme .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}
.purple_theme .mat-mdc-checkbox {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #95cfd5;
  --mdc-checkbox-selected-hover-icon-color: #95cfd5;
  --mdc-checkbox-selected-icon-color: #95cfd5;
  --mdc-checkbox-selected-pressed-icon-color: #95cfd5;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #95cfd5;
  --mdc-checkbox-selected-hover-state-layer-color: #95cfd5;
  --mdc-checkbox-selected-pressed-state-layer-color: #95cfd5;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.purple_theme .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #763ebd;
  --mdc-checkbox-selected-hover-icon-color: #763ebd;
  --mdc-checkbox-selected-icon-color: #763ebd;
  --mdc-checkbox-selected-pressed-icon-color: #763ebd;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #763ebd;
  --mdc-checkbox-selected-hover-state-layer-color: #763ebd;
  --mdc-checkbox-selected-pressed-state-layer-color: #763ebd;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.purple_theme .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.purple_theme .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.purple_theme .mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}
.purple_theme .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.purple_theme .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #763ebd;
}
.purple_theme .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #95cfd5;
}
.purple_theme .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.purple_theme .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.purple_theme .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.purple_theme .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #763ebd;
  --mdc-filled-button-label-text-color: #fff;
}
.purple_theme .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #95cfd5;
  --mdc-filled-button-label-text-color: #000;
}
.purple_theme .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.purple_theme .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.purple_theme .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.purple_theme .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #763ebd;
  --mdc-protected-button-label-text-color: #fff;
}
.purple_theme .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #95cfd5;
  --mdc-protected-button-label-text-color: #000;
}
.purple_theme .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.purple_theme .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.purple_theme .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.purple_theme .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #763ebd;
}
.purple_theme .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #95cfd5;
}
.purple_theme .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.purple_theme .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-mdc-button, .purple_theme .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.purple_theme .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .purple_theme .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.purple_theme .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .purple_theme .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .purple_theme .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .purple_theme .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.purple_theme .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .purple_theme .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.purple_theme .mat-mdc-button.mat-primary, .purple_theme .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #763ebd;
  --mat-mdc-button-ripple-color: rgba(118, 62, 189, 0.1);
}
.purple_theme .mat-mdc-button.mat-accent, .purple_theme .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #95cfd5;
  --mat-mdc-button-ripple-color: rgba(149, 207, 213, 0.1);
}
.purple_theme .mat-mdc-button.mat-warn, .purple_theme .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.purple_theme .mat-mdc-raised-button, .purple_theme .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.purple_theme .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .purple_theme .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.purple_theme .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .purple_theme .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .purple_theme .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .purple_theme .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.purple_theme .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .purple_theme .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.purple_theme .mat-mdc-raised-button.mat-primary, .purple_theme .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.purple_theme .mat-mdc-raised-button.mat-accent, .purple_theme .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.purple_theme .mat-mdc-raised-button.mat-warn, .purple_theme .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.purple_theme .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.purple_theme .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.purple_theme .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .purple_theme .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.purple_theme .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.purple_theme .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.purple_theme .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.purple_theme .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.purple_theme .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #763ebd;
  --mat-mdc-button-persistent-ripple-color: #763ebd;
  --mat-mdc-button-ripple-color: rgba(118, 62, 189, 0.1);
}
.purple_theme .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #95cfd5;
  --mat-mdc-button-persistent-ripple-color: #95cfd5;
  --mat-mdc-button-ripple-color: rgba(149, 207, 213, 0.1);
}
.purple_theme .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.purple_theme .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.purple_theme .mat-mdc-fab, .purple_theme .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.purple_theme .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .purple_theme .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.purple_theme .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .purple_theme .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .purple_theme .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .purple_theme .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.purple_theme .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .purple_theme .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.purple_theme .mat-mdc-fab.mat-primary, .purple_theme .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.purple_theme .mat-mdc-fab.mat-accent, .purple_theme .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.purple_theme .mat-mdc-fab.mat-warn, .purple_theme .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.purple_theme .mat-mdc-fab.mat-unthemed, .purple_theme .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.purple_theme .mat-mdc-fab.mat-primary, .purple_theme .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #763ebd;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.purple_theme .mat-mdc-fab.mat-accent, .purple_theme .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #95cfd5;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.purple_theme .mat-mdc-fab.mat-warn, .purple_theme .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.purple_theme .mat-mdc-fab[disabled][disabled], .purple_theme .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.purple_theme .mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #95cfd5;
}
.purple_theme .mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, 0.12);
}
.purple_theme .mdc-data-table__row {
  background-color: inherit;
}
.purple_theme .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}
.purple_theme .mdc-data-table__row--selected {
  background-color: rgba(118, 62, 189, 0.04);
}
.purple_theme .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.purple_theme .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.purple_theme .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.purple_theme .mdc-data-table__cell,
.purple_theme .mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.purple_theme .mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.purple_theme .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.purple_theme .mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}
.purple_theme .mdc-data-table__pagination-total,
.purple_theme .mdc-data-table__pagination-rows-per-page-label,
.purple_theme .mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}
[dir=rtl] .purple_theme .mdc-data-table__pagination-button .mdc-button__icon, .purple_theme .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.purple_theme .mat-mdc-table {
  background: white;
}
.purple_theme .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #763ebd;
}
.purple_theme .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #95cfd5;
}
.purple_theme .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}
.purple_theme .mat-badge-content {
  color: white;
  background: #763ebd;
}
.cdk-high-contrast-active .purple_theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.purple_theme .mat-badge-accent .mat-badge-content {
  background: #95cfd5;
  color: white;
}
.purple_theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.purple_theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.purple_theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.purple_theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.purple_theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.purple_theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.purple_theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.purple_theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.purple_theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.purple_theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.purple_theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.purple_theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.purple_theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.purple_theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.purple_theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.purple_theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.purple_theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.purple_theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.purple_theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.purple_theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.purple_theme .mat-datepicker-toggle,
.purple_theme .mat-datepicker-content .mat-calendar-next-button,
.purple_theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.purple_theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-calendar-table-header,
.purple_theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.purple_theme .mat-calendar-body-cell-content,
.purple_theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.purple_theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.purple_theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.purple_theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.purple_theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.purple_theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.purple_theme .mat-calendar-body-in-range::before {
  background: rgba(118, 62, 189, 0.2);
}
.purple_theme .mat-calendar-body-comparison-identical,
.purple_theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.purple_theme .mat-calendar-body-comparison-bridge-start::before,
.purple_theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(118, 62, 189, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.purple_theme .mat-calendar-body-comparison-bridge-end::before,
.purple_theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(118, 62, 189, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.purple_theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.purple_theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.purple_theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.purple_theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.purple_theme .mat-calendar-body-selected {
  background-color: #763ebd;
  color: white;
}
.purple_theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(118, 62, 189, 0.4);
}
.purple_theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.purple_theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.purple_theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(118, 62, 189, 0.3);
}
@media (hover: hover) {
  .purple_theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(118, 62, 189, 0.3);
  }
}
.purple_theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.purple_theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(149, 207, 213, 0.2);
}
.purple_theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.purple_theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.purple_theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.purple_theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(149, 207, 213, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.purple_theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.purple_theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(149, 207, 213, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.purple_theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.purple_theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.purple_theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.purple_theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.purple_theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #95cfd5;
  color: white;
}
.purple_theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(149, 207, 213, 0.4);
}
.purple_theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.purple_theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.purple_theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(149, 207, 213, 0.3);
}
@media (hover: hover) {
  .purple_theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(149, 207, 213, 0.3);
  }
}
.purple_theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.purple_theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.purple_theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.purple_theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.purple_theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.purple_theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.purple_theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.purple_theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.purple_theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.purple_theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.purple_theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.purple_theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.purple_theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.purple_theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.purple_theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.purple_theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .purple_theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.purple_theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-datepicker-toggle-active {
  color: #763ebd;
}
.purple_theme .mat-datepicker-toggle-active.mat-accent {
  color: #95cfd5;
}
.purple_theme .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.purple_theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.purple_theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.purple_theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .purple_theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .purple_theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .purple_theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.purple_theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.purple_theme .mat-expansion-panel-header-description,
.purple_theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.purple_theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.purple_theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.purple_theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.purple_theme .mat-icon.mat-primary {
  color: #763ebd;
}
.purple_theme .mat-icon.mat-accent {
  color: #95cfd5;
}
.purple_theme .mat-icon.mat-warn {
  color: #f44336;
}
.purple_theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.purple_theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.purple_theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.purple_theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.purple_theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.purple_theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.purple_theme .mat-step-header.cdk-keyboard-focused, .purple_theme .mat-step-header.cdk-program-focused, .purple_theme .mat-step-header:hover:not([aria-disabled]), .purple_theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.purple_theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .purple_theme .mat-step-header:hover {
    background: none;
  }
}
.purple_theme .mat-step-header .mat-step-label,
.purple_theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.purple_theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.purple_theme .mat-step-header .mat-step-icon-selected,
.purple_theme .mat-step-header .mat-step-icon-state-done,
.purple_theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #763ebd;
  color: white;
}
.purple_theme .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.purple_theme .mat-step-header.mat-accent .mat-step-icon-selected,
.purple_theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.purple_theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #95cfd5;
  color: white;
}
.purple_theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.purple_theme .mat-step-header.mat-warn .mat-step-icon-selected,
.purple_theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.purple_theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.purple_theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.purple_theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.purple_theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.purple_theme .mat-stepper-horizontal, .purple_theme .mat-stepper-vertical {
  background-color: white;
}
.purple_theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-horizontal-stepper-header::before,
.purple_theme .mat-horizontal-stepper-header::after,
.purple_theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.purple_theme .mat-sort-header-arrow {
  color: #757575;
}
.purple_theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.purple_theme .mat-toolbar.mat-primary {
  background: #763ebd;
  color: white;
}
.purple_theme .mat-toolbar.mat-accent {
  background: #95cfd5;
  color: white;
}
.purple_theme .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.purple_theme .mat-toolbar .mat-form-field-underline,
.purple_theme .mat-toolbar .mat-form-field-ripple,
.purple_theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.purple_theme .mat-toolbar .mat-form-field-label,
.purple_theme .mat-toolbar .mat-focused .mat-form-field-label,
.purple_theme .mat-toolbar .mat-select-value,
.purple_theme .mat-toolbar .mat-select-arrow,
.purple_theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.purple_theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.purple_theme .mat-tree {
  background: white;
}
.purple_theme .mat-tree-node,
.purple_theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.green_theme .bg-primary {
  background: #0a7ea4 !important;
}
.green_theme .horizontal-navbar .parentBox.pactive > a,
.green_theme .horizontal-navbar .parentBox.pactive > a:hover,
.green_theme .horizontal-navbar .parentBox.pactive:hover > a {
  background-color: #0a7ea4 !important;
}
.green_theme .activeMenu {
  background-color: #0a7ea4 !important;
}
.green_theme .mat-mdc-outlined-button.mat-primary {
  border-color: #0a7ea4 !important;
}
.green_theme .mat-mdc-outlined-button.mat-accent {
  border-color: #ccda4e !important;
}
.green_theme .mat-mdc-outlined-button.mat-warn {
  border-color: #f44336 !important;
}
.green_theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.green_theme .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.green_theme .mat-mdc-option:hover:not(.mdc-list-item--disabled), .green_theme .mat-mdc-option:focus.mdc-list-item, .green_theme .mat-mdc-option.mat-mdc-option-active.mdc-list-item, .green_theme .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.green_theme .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #0a7ea4);
}
.green_theme .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #ccda4e);
}
.green_theme .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #f44336);
}
.green_theme .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.green_theme .mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.green_theme .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.green_theme .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.green_theme .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #0a7ea4;
}
.green_theme .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.green_theme .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #0a7ea4;
}
.green_theme .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.green_theme .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.green_theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.green_theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ccda4e;
}
.green_theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.green_theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ccda4e;
}
.green_theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.green_theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.green_theme .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.green_theme .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ccda4e;
}
.green_theme .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.green_theme .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ccda4e;
}
.green_theme .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.green_theme .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.green_theme .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.green_theme .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.green_theme .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.green_theme .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.green_theme .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.green_theme .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.green_theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.green_theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.green_theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.green_theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}
.green_theme .mat-app-background, .green_theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.green_theme .mat-elevation-z0, .green_theme .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.green_theme .mat-elevation-z1, .green_theme .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.green_theme .mat-elevation-z2, .green_theme .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.green_theme .mat-elevation-z3, .green_theme .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.green_theme .mat-elevation-z4, .green_theme .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.green_theme .mat-elevation-z5, .green_theme .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.green_theme .mat-elevation-z6, .green_theme .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.green_theme .mat-elevation-z7, .green_theme .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.green_theme .mat-elevation-z8, .green_theme .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.green_theme .mat-elevation-z9, .green_theme .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.green_theme .mat-elevation-z10, .green_theme .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.green_theme .mat-elevation-z11, .green_theme .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.green_theme .mat-elevation-z12, .green_theme .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.green_theme .mat-elevation-z13, .green_theme .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.green_theme .mat-elevation-z14, .green_theme .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.green_theme .mat-elevation-z15, .green_theme .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.green_theme .mat-elevation-z16, .green_theme .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.green_theme .mat-elevation-z17, .green_theme .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.green_theme .mat-elevation-z18, .green_theme .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.green_theme .mat-elevation-z19, .green_theme .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.green_theme .mat-elevation-z20, .green_theme .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.green_theme .mat-elevation-z21, .green_theme .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.green_theme .mat-elevation-z22, .green_theme .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.green_theme .mat-elevation-z23, .green_theme .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.green_theme .mat-elevation-z24, .green_theme .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.green_theme .mat-mdc-card {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}
.green_theme .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #0a7ea4;
  --mdc-linear-progress-track-color: rgba(10, 126, 164, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.green_theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(10, 126, 164, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(10, 126, 164, 0.25));
}
@media (forced-colors: active) {
  .green_theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .green_theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(10, 126, 164, 0.25)'/%3E%3C/svg%3E");
  }
}
.green_theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(10, 126, 164, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(10, 126, 164, 0.25));
}
.green_theme .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #ccda4e;
  --mdc-linear-progress-track-color: rgba(204, 218, 78, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.green_theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(204, 218, 78, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(204, 218, 78, 0.25));
}
@media (forced-colors: active) {
  .green_theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .green_theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(204, 218, 78, 0.25)'/%3E%3C/svg%3E");
  }
}
.green_theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(204, 218, 78, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(204, 218, 78, 0.25));
}
.green_theme .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.green_theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
@media (forced-colors: active) {
  .green_theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .green_theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 67, 54, 0.25)'/%3E%3C/svg%3E");
  }
}
.green_theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
.green_theme .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
.green_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.green_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .green_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  .green_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
.green_theme .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #0a7ea4);
}
.green_theme .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.green_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.green_theme .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.green_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.green_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.green_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.green_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.green_theme .mdc-text-field--filled .mdc-text-field__ripple::before,
.green_theme .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.green_theme .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .green_theme .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.green_theme .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .green_theme .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.green_theme .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.green_theme .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.green_theme .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.green_theme .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #0a7ea4);
}
.green_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.green_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.green_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.green_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.green_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.green_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.green_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.green_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.green_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #0a7ea4);
}
[dir=rtl] .green_theme .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .green_theme .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .green_theme .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .green_theme .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.green_theme .mdc-text-field--outlined .mdc-text-field__ripple::before,
.green_theme .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .green_theme .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .green_theme .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.green_theme .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(10, 126, 164, 0.87);
}
.green_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.green_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.green_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.green_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.green_theme .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.green_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.green_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.green_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.green_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.green_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.green_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.green_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.green_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.green_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.green_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.green_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.green_theme .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .green_theme .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .green_theme .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.green_theme .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.green_theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.green_theme .mdc-text-field--disabled .mdc-text-field-character-counter,
.green_theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.green_theme .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.green_theme .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.green_theme .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.green_theme .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.green_theme .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.green_theme .mdc-text-field--disabled .mdc-notched-outline__leading,
.green_theme .mdc-text-field--disabled .mdc-notched-outline__notch,
.green_theme .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .green_theme .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .green_theme .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
  .green_theme .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
  .green_theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
  .green_theme .mdc-text-field--disabled .mdc-text-field-character-counter,
  .green_theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
  .green_theme .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
  .green_theme .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
  .green_theme .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
  .green_theme .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
  .green_theme .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
  .green_theme .mdc-text-field--disabled .mdc-notched-outline__leading,
  .green_theme .mdc-text-field--disabled .mdc-notched-outline__notch,
  .green_theme .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.green_theme .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] .green_theme .mdc-text-field--end-aligned .mdc-text-field__input, .green_theme .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .green_theme .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .green_theme .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.green_theme .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #f44336);
}
.green_theme .mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}
.green_theme .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}
.green_theme .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}
.green_theme .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.green_theme .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(10, 126, 164, 0.87);
}
.green_theme .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(204, 218, 78, 0.87);
}
.green_theme .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(244, 67, 54, 0.87);
}
.green_theme .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.green_theme .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #ccda4e);
}
.green_theme .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #ccda4e);
}
.green_theme .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(204, 218, 78, 0.87);
}
.green_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.green_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.green_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.green_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.green_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.green_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.green_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.green_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.green_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.green_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.green_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.green_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.green_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.green_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.green_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.green_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.green_theme .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.green_theme .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.green_theme .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #ccda4e);
}
.green_theme .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.green_theme .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.green_theme .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(244, 67, 54, 0.87);
}
.green_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.green_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.green_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.green_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.green_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.green_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.green_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.green_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.green_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.green_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.green_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.green_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.green_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.green_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.green_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.green_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.green_theme .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.green_theme .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.green_theme .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.green_theme .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.green_theme [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.green_theme .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.green_theme .mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.green_theme .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.green_theme .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.green_theme .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.green_theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.green_theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.green_theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.green_theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.green_theme .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.green_theme .mdc-list-item--disabled .mdc-list-item__start,
.green_theme .mdc-list-item--disabled .mdc-list-item__content,
.green_theme .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.green_theme .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.green_theme .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.green_theme .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.green_theme .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.green_theme .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.green_theme .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.green_theme .mdc-list-item--selected .mdc-list-item__primary-text,
.green_theme .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #0a7ea4);
}
.green_theme .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.green_theme .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #0a7ea4);
}
.green_theme .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.green_theme .mdc-list-divider::after {
  border-bottom-color: white;
}
.green_theme .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.green_theme .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.green_theme .mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.green_theme .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.green_theme .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.green_theme .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(10, 126, 164, 0.87);
}
.green_theme .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(204, 218, 78, 0.87);
}
.green_theme .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(244, 67, 54, 0.87);
}
.green_theme .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(244, 67, 54, 0.87);
}
.green_theme .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.green_theme .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.green_theme .mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.green_theme .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.green_theme .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.green_theme .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.green_theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.green_theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.green_theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.green_theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.green_theme .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.green_theme .mdc-list-item--disabled .mdc-list-item__start,
.green_theme .mdc-list-item--disabled .mdc-list-item__content,
.green_theme .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.green_theme .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.green_theme .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.green_theme .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.green_theme .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.green_theme .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.green_theme .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.green_theme .mdc-list-item--selected .mdc-list-item__primary-text,
.green_theme .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #0a7ea4);
}
.green_theme .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.green_theme .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #0a7ea4);
}
.green_theme .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.green_theme .mdc-list-divider::after {
  border-bottom-color: white;
}
.green_theme .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.green_theme .mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
.green_theme .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
.green_theme .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .green_theme .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #0a7ea4;
  --mdc-chip-elevated-disabled-container-color: #0a7ea4;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.green_theme .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .green_theme .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #ccda4e;
  --mdc-chip-elevated-disabled-container-color: #ccda4e;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.green_theme .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .green_theme .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.green_theme .mat-mdc-chip-focus-overlay {
  background: black;
}
.green_theme .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.green_theme .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.green_theme .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.green_theme .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}
.green_theme .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.green_theme .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #0a7ea4;
  --mdc-radio-selected-hover-icon-color: #0a7ea4;
  --mdc-radio-selected-icon-color: #0a7ea4;
  --mdc-radio-selected-pressed-icon-color: #0a7ea4;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #0a7ea4;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.green_theme .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ccda4e;
  --mdc-radio-selected-hover-icon-color: #ccda4e;
  --mdc-radio-selected-icon-color: #ccda4e;
  --mdc-radio-selected-pressed-icon-color: #ccda4e;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #ccda4e;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.green_theme .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.green_theme .mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.green_theme .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #0a7ea4;
  --mdc-slider-focus-handle-color: #0a7ea4;
  --mdc-slider-hover-handle-color: #0a7ea4;
  --mdc-slider-active-track-color: #0a7ea4;
  --mdc-slider-inactive-track-color: #0a7ea4;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #0a7ea4;
  --mat-mdc-slider-ripple-color: #0a7ea4;
  --mat-mdc-slider-hover-ripple-color: rgba(10, 126, 164, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(10, 126, 164, 0.2);
}
.green_theme .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #ccda4e;
  --mdc-slider-focus-handle-color: #ccda4e;
  --mdc-slider-hover-handle-color: #ccda4e;
  --mdc-slider-active-track-color: #ccda4e;
  --mdc-slider-inactive-track-color: #ccda4e;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #ccda4e;
  --mat-mdc-slider-ripple-color: #ccda4e;
  --mat-mdc-slider-hover-ripple-color: rgba(204, 218, 78, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(204, 218, 78, 0.2);
}
.green_theme .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mat-mdc-slider-ripple-color: #f44336;
  --mat-mdc-slider-hover-ripple-color: rgba(244, 67, 54, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(244, 67, 54, 0.2);
}
.green_theme .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.green_theme .mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.green_theme .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.green_theme .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.green_theme .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.green_theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.green_theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.green_theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.green_theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.green_theme .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.green_theme .mdc-list-item--disabled .mdc-list-item__start,
.green_theme .mdc-list-item--disabled .mdc-list-item__content,
.green_theme .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.green_theme .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.green_theme .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.green_theme .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.green_theme .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.green_theme .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.green_theme .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.green_theme .mdc-list-item--selected .mdc-list-item__primary-text,
.green_theme .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #0a7ea4);
}
.green_theme .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.green_theme .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #0a7ea4);
}
.green_theme .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.green_theme .mdc-list-divider::after {
  border-bottom-color: white;
}
.green_theme .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.green_theme .mat-mdc-menu-item[disabled],
.green_theme .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.green_theme .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}
.green_theme .mat-mdc-menu-item .mat-icon-no-color,
.green_theme .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.green_theme .mat-mdc-menu-item:hover:not([disabled]),
.green_theme .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.green_theme .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.green_theme .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.green_theme .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}
.green_theme .mdc-list-item__start,
.green_theme .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #0a7ea4;
  --mdc-radio-selected-hover-icon-color: #0a7ea4;
  --mdc-radio-selected-icon-color: #0a7ea4;
  --mdc-radio-selected-pressed-icon-color: #0a7ea4;
}
.green_theme .mat-accent .mdc-list-item__start,
.green_theme .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ccda4e;
  --mdc-radio-selected-hover-icon-color: #ccda4e;
  --mdc-radio-selected-icon-color: #ccda4e;
  --mdc-radio-selected-pressed-icon-color: #ccda4e;
}
.green_theme .mat-warn .mdc-list-item__start,
.green_theme .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}
.green_theme .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #0a7ea4;
  --mdc-checkbox-selected-hover-icon-color: #0a7ea4;
  --mdc-checkbox-selected-icon-color: #0a7ea4;
  --mdc-checkbox-selected-pressed-icon-color: #0a7ea4;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #0a7ea4;
  --mdc-checkbox-selected-hover-state-layer-color: #0a7ea4;
  --mdc-checkbox-selected-pressed-state-layer-color: #0a7ea4;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.green_theme .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #ccda4e;
  --mdc-checkbox-selected-hover-icon-color: #ccda4e;
  --mdc-checkbox-selected-icon-color: #ccda4e;
  --mdc-checkbox-selected-pressed-icon-color: #ccda4e;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ccda4e;
  --mdc-checkbox-selected-hover-state-layer-color: #ccda4e;
  --mdc-checkbox-selected-pressed-state-layer-color: #ccda4e;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.green_theme .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.green_theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.green_theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #0a7ea4;
}
.green_theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.green_theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #0a7ea4;
}
.green_theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.green_theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.green_theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.green_theme .mat-mdc-paginator {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.green_theme .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.54);
}
.green_theme .mat-mdc-paginator-decrement,
.green_theme .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.green_theme .mat-mdc-paginator-first,
.green_theme .mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.green_theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.green_theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.green_theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.green_theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, 0.12);
}
.green_theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.12);
}
.green_theme .mat-mdc-tab-group, .green_theme .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #0a7ea4;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #0a7ea4;
  --mat-tab-header-active-ripple-color: #0a7ea4;
  --mat-tab-header-inactive-ripple-color: #0a7ea4;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #0a7ea4;
  --mat-tab-header-active-hover-label-text-color: #0a7ea4;
  --mat-tab-header-active-focus-indicator-color: #0a7ea4;
  --mat-tab-header-active-hover-indicator-color: #0a7ea4;
}
.green_theme .mat-mdc-tab-group.mat-accent, .green_theme .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #ccda4e;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #ccda4e;
  --mat-tab-header-active-ripple-color: #ccda4e;
  --mat-tab-header-inactive-ripple-color: #ccda4e;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ccda4e;
  --mat-tab-header-active-hover-label-text-color: #ccda4e;
  --mat-tab-header-active-focus-indicator-color: #ccda4e;
  --mat-tab-header-active-hover-indicator-color: #ccda4e;
}
.green_theme .mat-mdc-tab-group.mat-warn, .green_theme .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.green_theme .mat-mdc-tab-group.mat-background-primary, .green_theme .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #0a7ea4;
  --mat-tab-header-with-background-foreground-color: white;
}
.green_theme .mat-mdc-tab-group.mat-background-accent, .green_theme .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #ccda4e;
  --mat-tab-header-with-background-foreground-color: white;
}
.green_theme .mat-mdc-tab-group.mat-background-warn, .green_theme .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}
.green_theme .mat-mdc-checkbox {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #ccda4e;
  --mdc-checkbox-selected-hover-icon-color: #ccda4e;
  --mdc-checkbox-selected-icon-color: #ccda4e;
  --mdc-checkbox-selected-pressed-icon-color: #ccda4e;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ccda4e;
  --mdc-checkbox-selected-hover-state-layer-color: #ccda4e;
  --mdc-checkbox-selected-pressed-state-layer-color: #ccda4e;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.green_theme .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #0a7ea4;
  --mdc-checkbox-selected-hover-icon-color: #0a7ea4;
  --mdc-checkbox-selected-icon-color: #0a7ea4;
  --mdc-checkbox-selected-pressed-icon-color: #0a7ea4;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #0a7ea4;
  --mdc-checkbox-selected-hover-state-layer-color: #0a7ea4;
  --mdc-checkbox-selected-pressed-state-layer-color: #0a7ea4;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.green_theme .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.green_theme .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.green_theme .mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}
.green_theme .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.green_theme .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #0a7ea4;
}
.green_theme .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #ccda4e;
}
.green_theme .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.green_theme .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.green_theme .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.green_theme .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #0a7ea4;
  --mdc-filled-button-label-text-color: #fff;
}
.green_theme .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #ccda4e;
  --mdc-filled-button-label-text-color: #000;
}
.green_theme .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.green_theme .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.green_theme .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.green_theme .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #0a7ea4;
  --mdc-protected-button-label-text-color: #fff;
}
.green_theme .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #ccda4e;
  --mdc-protected-button-label-text-color: #000;
}
.green_theme .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.green_theme .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.green_theme .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.green_theme .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.green_theme .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #0a7ea4;
}
.green_theme .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #ccda4e;
}
.green_theme .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.green_theme .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.green_theme .mat-mdc-button, .green_theme .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.green_theme .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .green_theme .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.green_theme .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .green_theme .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .green_theme .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .green_theme .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.green_theme .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .green_theme .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.green_theme .mat-mdc-button.mat-primary, .green_theme .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #0a7ea4;
  --mat-mdc-button-ripple-color: rgba(10, 126, 164, 0.1);
}
.green_theme .mat-mdc-button.mat-accent, .green_theme .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #ccda4e;
  --mat-mdc-button-ripple-color: rgba(204, 218, 78, 0.1);
}
.green_theme .mat-mdc-button.mat-warn, .green_theme .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.green_theme .mat-mdc-raised-button, .green_theme .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.green_theme .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .green_theme .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.green_theme .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .green_theme .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .green_theme .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .green_theme .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.green_theme .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .green_theme .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.green_theme .mat-mdc-raised-button.mat-primary, .green_theme .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.green_theme .mat-mdc-raised-button.mat-accent, .green_theme .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.green_theme .mat-mdc-raised-button.mat-warn, .green_theme .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.green_theme .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.green_theme .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.green_theme .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .green_theme .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.green_theme .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.green_theme .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.green_theme .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.green_theme .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.green_theme .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #0a7ea4;
  --mat-mdc-button-persistent-ripple-color: #0a7ea4;
  --mat-mdc-button-ripple-color: rgba(10, 126, 164, 0.1);
}
.green_theme .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #ccda4e;
  --mat-mdc-button-persistent-ripple-color: #ccda4e;
  --mat-mdc-button-ripple-color: rgba(204, 218, 78, 0.1);
}
.green_theme .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.green_theme .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.green_theme .mat-mdc-fab, .green_theme .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.green_theme .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .green_theme .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.green_theme .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .green_theme .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .green_theme .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .green_theme .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.green_theme .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .green_theme .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.green_theme .mat-mdc-fab.mat-primary, .green_theme .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.green_theme .mat-mdc-fab.mat-accent, .green_theme .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.green_theme .mat-mdc-fab.mat-warn, .green_theme .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.green_theme .mat-mdc-fab.mat-unthemed, .green_theme .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.green_theme .mat-mdc-fab.mat-primary, .green_theme .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #0a7ea4;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.green_theme .mat-mdc-fab.mat-accent, .green_theme .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #ccda4e;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.green_theme .mat-mdc-fab.mat-warn, .green_theme .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.green_theme .mat-mdc-fab[disabled][disabled], .green_theme .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.green_theme .mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #ccda4e;
}
.green_theme .mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, 0.12);
}
.green_theme .mdc-data-table__row {
  background-color: inherit;
}
.green_theme .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}
.green_theme .mdc-data-table__row--selected {
  background-color: rgba(10, 126, 164, 0.04);
}
.green_theme .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.green_theme .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.green_theme .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.green_theme .mdc-data-table__cell,
.green_theme .mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.green_theme .mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.green_theme .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.green_theme .mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}
.green_theme .mdc-data-table__pagination-total,
.green_theme .mdc-data-table__pagination-rows-per-page-label,
.green_theme .mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}
[dir=rtl] .green_theme .mdc-data-table__pagination-button .mdc-button__icon, .green_theme .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.green_theme .mat-mdc-table {
  background: white;
}
.green_theme .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #0a7ea4;
}
.green_theme .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #ccda4e;
}
.green_theme .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}
.green_theme .mat-badge-content {
  color: white;
  background: #0a7ea4;
}
.cdk-high-contrast-active .green_theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.green_theme .mat-badge-accent .mat-badge-content {
  background: #ccda4e;
  color: white;
}
.green_theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.green_theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.green_theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.green_theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.green_theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.green_theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.green_theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.green_theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.green_theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.green_theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.green_theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.green_theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.green_theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.green_theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.green_theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.green_theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.green_theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.green_theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.green_theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.green_theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.green_theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.green_theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.green_theme .mat-datepicker-toggle,
.green_theme .mat-datepicker-content .mat-calendar-next-button,
.green_theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.green_theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.green_theme .mat-calendar-table-header,
.green_theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.green_theme .mat-calendar-body-cell-content,
.green_theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.green_theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.green_theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.green_theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.green_theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.green_theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.green_theme .mat-calendar-body-in-range::before {
  background: rgba(10, 126, 164, 0.2);
}
.green_theme .mat-calendar-body-comparison-identical,
.green_theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.green_theme .mat-calendar-body-comparison-bridge-start::before,
.green_theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(10, 126, 164, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.green_theme .mat-calendar-body-comparison-bridge-end::before,
.green_theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(10, 126, 164, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.green_theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.green_theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.green_theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.green_theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.green_theme .mat-calendar-body-selected {
  background-color: #0a7ea4;
  color: white;
}
.green_theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(10, 126, 164, 0.4);
}
.green_theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.green_theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.green_theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(10, 126, 164, 0.3);
}
@media (hover: hover) {
  .green_theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(10, 126, 164, 0.3);
  }
}
.green_theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.green_theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(204, 218, 78, 0.2);
}
.green_theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.green_theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.green_theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.green_theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(204, 218, 78, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.green_theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.green_theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(204, 218, 78, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.green_theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.green_theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.green_theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.green_theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.green_theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ccda4e;
  color: white;
}
.green_theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(204, 218, 78, 0.4);
}
.green_theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.green_theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.green_theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(204, 218, 78, 0.3);
}
@media (hover: hover) {
  .green_theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(204, 218, 78, 0.3);
  }
}
.green_theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.green_theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.green_theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.green_theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.green_theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.green_theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.green_theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.green_theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.green_theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.green_theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.green_theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.green_theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.green_theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.green_theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.green_theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.green_theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .green_theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.green_theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.green_theme .mat-datepicker-toggle-active {
  color: #0a7ea4;
}
.green_theme .mat-datepicker-toggle-active.mat-accent {
  color: #ccda4e;
}
.green_theme .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.green_theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.green_theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.green_theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.green_theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.green_theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.green_theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.green_theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .green_theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .green_theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .green_theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.green_theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.green_theme .mat-expansion-panel-header-description,
.green_theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.green_theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.green_theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.green_theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.green_theme .mat-icon.mat-primary {
  color: #0a7ea4;
}
.green_theme .mat-icon.mat-accent {
  color: #ccda4e;
}
.green_theme .mat-icon.mat-warn {
  color: #f44336;
}
.green_theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.green_theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.green_theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.green_theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.green_theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.green_theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.green_theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.green_theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.green_theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.green_theme .mat-step-header.cdk-keyboard-focused, .green_theme .mat-step-header.cdk-program-focused, .green_theme .mat-step-header:hover:not([aria-disabled]), .green_theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.green_theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .green_theme .mat-step-header:hover {
    background: none;
  }
}
.green_theme .mat-step-header .mat-step-label,
.green_theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.green_theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.green_theme .mat-step-header .mat-step-icon-selected,
.green_theme .mat-step-header .mat-step-icon-state-done,
.green_theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #0a7ea4;
  color: white;
}
.green_theme .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.green_theme .mat-step-header.mat-accent .mat-step-icon-selected,
.green_theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.green_theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ccda4e;
  color: white;
}
.green_theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.green_theme .mat-step-header.mat-warn .mat-step-icon-selected,
.green_theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.green_theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.green_theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.green_theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.green_theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.green_theme .mat-stepper-horizontal, .green_theme .mat-stepper-vertical {
  background-color: white;
}
.green_theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.green_theme .mat-horizontal-stepper-header::before,
.green_theme .mat-horizontal-stepper-header::after,
.green_theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.green_theme .mat-sort-header-arrow {
  color: #757575;
}
.green_theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.green_theme .mat-toolbar.mat-primary {
  background: #0a7ea4;
  color: white;
}
.green_theme .mat-toolbar.mat-accent {
  background: #ccda4e;
  color: white;
}
.green_theme .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.green_theme .mat-toolbar .mat-form-field-underline,
.green_theme .mat-toolbar .mat-form-field-ripple,
.green_theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.green_theme .mat-toolbar .mat-form-field-label,
.green_theme .mat-toolbar .mat-focused .mat-form-field-label,
.green_theme .mat-toolbar .mat-select-value,
.green_theme .mat-toolbar .mat-select-arrow,
.green_theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.green_theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.green_theme .mat-tree {
  background: white;
}
.green_theme .mat-tree-node,
.green_theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.cyan_theme .bg-primary {
  background: #01c0c8 !important;
}
.cyan_theme .horizontal-navbar .parentBox.pactive > a,
.cyan_theme .horizontal-navbar .parentBox.pactive > a:hover,
.cyan_theme .horizontal-navbar .parentBox.pactive:hover > a {
  background-color: #01c0c8 !important;
}
.cyan_theme .activeMenu {
  background-color: #01c0c8 !important;
}
.cyan_theme .mat-mdc-outlined-button.mat-primary {
  border-color: #01c0c8 !important;
}
.cyan_theme .mat-mdc-outlined-button.mat-accent {
  border-color: #fb9678 !important;
}
.cyan_theme .mat-mdc-outlined-button.mat-warn {
  border-color: #f44336 !important;
}
.cyan_theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.cyan_theme .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.cyan_theme .mat-mdc-option:hover:not(.mdc-list-item--disabled), .cyan_theme .mat-mdc-option:focus.mdc-list-item, .cyan_theme .mat-mdc-option.mat-mdc-option-active.mdc-list-item, .cyan_theme .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.cyan_theme .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #01c0c8);
}
.cyan_theme .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #fb9678);
}
.cyan_theme .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.cyan_theme .mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.cyan_theme .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.cyan_theme .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.cyan_theme .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #01c0c8;
}
.cyan_theme .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.cyan_theme .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #01c0c8;
}
.cyan_theme .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.cyan_theme .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.cyan_theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.cyan_theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #fb9678;
}
.cyan_theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.cyan_theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #fb9678;
}
.cyan_theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.cyan_theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.cyan_theme .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.cyan_theme .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #fb9678;
}
.cyan_theme .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.cyan_theme .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #fb9678;
}
.cyan_theme .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.cyan_theme .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.cyan_theme .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.cyan_theme .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.cyan_theme .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.cyan_theme .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.cyan_theme .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.cyan_theme .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.cyan_theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.cyan_theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.cyan_theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.cyan_theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}
.cyan_theme .mat-app-background, .cyan_theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.cyan_theme .mat-elevation-z0, .cyan_theme .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-elevation-z1, .cyan_theme .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-elevation-z2, .cyan_theme .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-elevation-z3, .cyan_theme .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-elevation-z4, .cyan_theme .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-elevation-z5, .cyan_theme .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-elevation-z6, .cyan_theme .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-elevation-z7, .cyan_theme .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-elevation-z8, .cyan_theme .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-elevation-z9, .cyan_theme .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-elevation-z10, .cyan_theme .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-elevation-z11, .cyan_theme .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-elevation-z12, .cyan_theme .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-elevation-z13, .cyan_theme .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-elevation-z14, .cyan_theme .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-elevation-z15, .cyan_theme .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-elevation-z16, .cyan_theme .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-elevation-z17, .cyan_theme .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-elevation-z18, .cyan_theme .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-elevation-z19, .cyan_theme .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-elevation-z20, .cyan_theme .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-elevation-z21, .cyan_theme .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-elevation-z22, .cyan_theme .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-elevation-z23, .cyan_theme .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-elevation-z24, .cyan_theme .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.cyan_theme .mat-mdc-card {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}
.cyan_theme .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #01c0c8;
  --mdc-linear-progress-track-color: rgba(1, 192, 200, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.cyan_theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(1, 192, 200, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(1, 192, 200, 0.25));
}
@media (forced-colors: active) {
  .cyan_theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .cyan_theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(1, 192, 200, 0.25)'/%3E%3C/svg%3E");
  }
}
.cyan_theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(1, 192, 200, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(1, 192, 200, 0.25));
}
.cyan_theme .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #fb9678;
  --mdc-linear-progress-track-color: rgba(251, 150, 120, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.cyan_theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(251, 150, 120, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(251, 150, 120, 0.25));
}
@media (forced-colors: active) {
  .cyan_theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .cyan_theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(251, 150, 120, 0.25)'/%3E%3C/svg%3E");
  }
}
.cyan_theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(251, 150, 120, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(251, 150, 120, 0.25));
}
.cyan_theme .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.cyan_theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
@media (forced-colors: active) {
  .cyan_theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .cyan_theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 67, 54, 0.25)'/%3E%3C/svg%3E");
  }
}
.cyan_theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
.cyan_theme .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
.cyan_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.cyan_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .cyan_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  .cyan_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
.cyan_theme .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #01c0c8);
}
.cyan_theme .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.cyan_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.cyan_theme .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.cyan_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.cyan_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.cyan_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.cyan_theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.cyan_theme .mdc-text-field--filled .mdc-text-field__ripple::before,
.cyan_theme .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.cyan_theme .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .cyan_theme .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.cyan_theme .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .cyan_theme .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.cyan_theme .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.cyan_theme .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.cyan_theme .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.cyan_theme .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #01c0c8);
}
.cyan_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.cyan_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.cyan_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.cyan_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.cyan_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.cyan_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.cyan_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.cyan_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.cyan_theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #01c0c8);
}
[dir=rtl] .cyan_theme .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .cyan_theme .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .cyan_theme .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .cyan_theme .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.cyan_theme .mdc-text-field--outlined .mdc-text-field__ripple::before,
.cyan_theme .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .cyan_theme .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .cyan_theme .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.cyan_theme .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(1, 192, 200, 0.87);
}
.cyan_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.cyan_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.cyan_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.cyan_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.cyan_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.cyan_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.cyan_theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .cyan_theme .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .cyan_theme .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.cyan_theme .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.cyan_theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.cyan_theme .mdc-text-field--disabled .mdc-text-field-character-counter,
.cyan_theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.cyan_theme .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.cyan_theme .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.cyan_theme .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.cyan_theme .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.cyan_theme .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.cyan_theme .mdc-text-field--disabled .mdc-notched-outline__leading,
.cyan_theme .mdc-text-field--disabled .mdc-notched-outline__notch,
.cyan_theme .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .cyan_theme .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .cyan_theme .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
  .cyan_theme .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
  .cyan_theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
  .cyan_theme .mdc-text-field--disabled .mdc-text-field-character-counter,
  .cyan_theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
  .cyan_theme .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
  .cyan_theme .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
  .cyan_theme .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
  .cyan_theme .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
  .cyan_theme .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
  .cyan_theme .mdc-text-field--disabled .mdc-notched-outline__leading,
  .cyan_theme .mdc-text-field--disabled .mdc-notched-outline__notch,
  .cyan_theme .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.cyan_theme .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] .cyan_theme .mdc-text-field--end-aligned .mdc-text-field__input, .cyan_theme .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .cyan_theme .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .cyan_theme .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.cyan_theme .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}
.cyan_theme .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}
.cyan_theme .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}
.cyan_theme .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.cyan_theme .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(1, 192, 200, 0.87);
}
.cyan_theme .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(251, 150, 120, 0.87);
}
.cyan_theme .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(244, 67, 54, 0.87);
}
.cyan_theme .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.cyan_theme .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #fb9678);
}
.cyan_theme .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #fb9678);
}
.cyan_theme .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(251, 150, 120, 0.87);
}
.cyan_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.cyan_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.cyan_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.cyan_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.cyan_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.cyan_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.cyan_theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.cyan_theme .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.cyan_theme .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #fb9678);
}
.cyan_theme .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(244, 67, 54, 0.87);
}
.cyan_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.cyan_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.cyan_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.cyan_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.cyan_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.cyan_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.cyan_theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.cyan_theme .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.cyan_theme .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.cyan_theme .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.cyan_theme [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.cyan_theme .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.cyan_theme .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.cyan_theme .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.cyan_theme .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.cyan_theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.cyan_theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.cyan_theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.cyan_theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.cyan_theme .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.cyan_theme .mdc-list-item--disabled .mdc-list-item__start,
.cyan_theme .mdc-list-item--disabled .mdc-list-item__content,
.cyan_theme .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.cyan_theme .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.cyan_theme .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.cyan_theme .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.cyan_theme .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.cyan_theme .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.cyan_theme .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.cyan_theme .mdc-list-item--selected .mdc-list-item__primary-text,
.cyan_theme .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #01c0c8);
}
.cyan_theme .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.cyan_theme .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #01c0c8);
}
.cyan_theme .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.cyan_theme .mdc-list-divider::after {
  border-bottom-color: white;
}
.cyan_theme .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.cyan_theme .mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.cyan_theme .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.cyan_theme .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.cyan_theme .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(1, 192, 200, 0.87);
}
.cyan_theme .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(251, 150, 120, 0.87);
}
.cyan_theme .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(244, 67, 54, 0.87);
}
.cyan_theme .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(244, 67, 54, 0.87);
}
.cyan_theme .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.cyan_theme .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.cyan_theme .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.cyan_theme .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.cyan_theme .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.cyan_theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.cyan_theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.cyan_theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.cyan_theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.cyan_theme .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.cyan_theme .mdc-list-item--disabled .mdc-list-item__start,
.cyan_theme .mdc-list-item--disabled .mdc-list-item__content,
.cyan_theme .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.cyan_theme .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.cyan_theme .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.cyan_theme .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.cyan_theme .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.cyan_theme .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.cyan_theme .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.cyan_theme .mdc-list-item--selected .mdc-list-item__primary-text,
.cyan_theme .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #01c0c8);
}
.cyan_theme .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.cyan_theme .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #01c0c8);
}
.cyan_theme .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.cyan_theme .mdc-list-divider::after {
  border-bottom-color: white;
}
.cyan_theme .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
.cyan_theme .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
.cyan_theme .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .cyan_theme .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #01c0c8;
  --mdc-chip-elevated-disabled-container-color: #01c0c8;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.cyan_theme .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .cyan_theme .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #fb9678;
  --mdc-chip-elevated-disabled-container-color: #fb9678;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.cyan_theme .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .cyan_theme .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.cyan_theme .mat-mdc-chip-focus-overlay {
  background: black;
}
.cyan_theme .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.cyan_theme .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.cyan_theme .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.cyan_theme .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}
.cyan_theme .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.cyan_theme .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #01c0c8;
  --mdc-radio-selected-hover-icon-color: #01c0c8;
  --mdc-radio-selected-icon-color: #01c0c8;
  --mdc-radio-selected-pressed-icon-color: #01c0c8;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #01c0c8;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.cyan_theme .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #fb9678;
  --mdc-radio-selected-hover-icon-color: #fb9678;
  --mdc-radio-selected-icon-color: #fb9678;
  --mdc-radio-selected-pressed-icon-color: #fb9678;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #fb9678;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.cyan_theme .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.cyan_theme .mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.cyan_theme .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #01c0c8;
  --mdc-slider-focus-handle-color: #01c0c8;
  --mdc-slider-hover-handle-color: #01c0c8;
  --mdc-slider-active-track-color: #01c0c8;
  --mdc-slider-inactive-track-color: #01c0c8;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #01c0c8;
  --mat-mdc-slider-ripple-color: #01c0c8;
  --mat-mdc-slider-hover-ripple-color: rgba(1, 192, 200, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(1, 192, 200, 0.2);
}
.cyan_theme .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #fb9678;
  --mdc-slider-focus-handle-color: #fb9678;
  --mdc-slider-hover-handle-color: #fb9678;
  --mdc-slider-active-track-color: #fb9678;
  --mdc-slider-inactive-track-color: #fb9678;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #fb9678;
  --mat-mdc-slider-ripple-color: #fb9678;
  --mat-mdc-slider-hover-ripple-color: rgba(251, 150, 120, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(251, 150, 120, 0.2);
}
.cyan_theme .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mat-mdc-slider-ripple-color: #f44336;
  --mat-mdc-slider-hover-ripple-color: rgba(244, 67, 54, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(244, 67, 54, 0.2);
}
.cyan_theme .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.cyan_theme .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.cyan_theme .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.cyan_theme .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.cyan_theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.cyan_theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.cyan_theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.cyan_theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.cyan_theme .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.cyan_theme .mdc-list-item--disabled .mdc-list-item__start,
.cyan_theme .mdc-list-item--disabled .mdc-list-item__content,
.cyan_theme .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.cyan_theme .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.cyan_theme .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.cyan_theme .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.cyan_theme .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.cyan_theme .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.cyan_theme .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.cyan_theme .mdc-list-item--selected .mdc-list-item__primary-text,
.cyan_theme .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #01c0c8);
}
.cyan_theme .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.cyan_theme .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #01c0c8);
}
.cyan_theme .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.cyan_theme .mdc-list-divider::after {
  border-bottom-color: white;
}
.cyan_theme .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-mdc-menu-item[disabled],
.cyan_theme .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.cyan_theme .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}
.cyan_theme .mat-mdc-menu-item .mat-icon-no-color,
.cyan_theme .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.cyan_theme .mat-mdc-menu-item:hover:not([disabled]),
.cyan_theme .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.cyan_theme .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.cyan_theme .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.cyan_theme .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}
.cyan_theme .mdc-list-item__start,
.cyan_theme .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #01c0c8;
  --mdc-radio-selected-hover-icon-color: #01c0c8;
  --mdc-radio-selected-icon-color: #01c0c8;
  --mdc-radio-selected-pressed-icon-color: #01c0c8;
}
.cyan_theme .mat-accent .mdc-list-item__start,
.cyan_theme .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #fb9678;
  --mdc-radio-selected-hover-icon-color: #fb9678;
  --mdc-radio-selected-icon-color: #fb9678;
  --mdc-radio-selected-pressed-icon-color: #fb9678;
}
.cyan_theme .mat-warn .mdc-list-item__start,
.cyan_theme .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}
.cyan_theme .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #01c0c8;
  --mdc-checkbox-selected-hover-icon-color: #01c0c8;
  --mdc-checkbox-selected-icon-color: #01c0c8;
  --mdc-checkbox-selected-pressed-icon-color: #01c0c8;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #01c0c8;
  --mdc-checkbox-selected-hover-state-layer-color: #01c0c8;
  --mdc-checkbox-selected-pressed-state-layer-color: #01c0c8;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.cyan_theme .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #fb9678;
  --mdc-checkbox-selected-hover-icon-color: #fb9678;
  --mdc-checkbox-selected-icon-color: #fb9678;
  --mdc-checkbox-selected-pressed-icon-color: #fb9678;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #fb9678;
  --mdc-checkbox-selected-hover-state-layer-color: #fb9678;
  --mdc-checkbox-selected-pressed-state-layer-color: #fb9678;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.cyan_theme .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.cyan_theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.cyan_theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #01c0c8;
}
.cyan_theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.cyan_theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #01c0c8;
}
.cyan_theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.cyan_theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.cyan_theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.cyan_theme .mat-mdc-paginator {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.cyan_theme .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.54);
}
.cyan_theme .mat-mdc-paginator-decrement,
.cyan_theme .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.cyan_theme .mat-mdc-paginator-first,
.cyan_theme .mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.cyan_theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.cyan_theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.cyan_theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.cyan_theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-mdc-tab-group, .cyan_theme .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #01c0c8;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #01c0c8;
  --mat-tab-header-active-ripple-color: #01c0c8;
  --mat-tab-header-inactive-ripple-color: #01c0c8;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #01c0c8;
  --mat-tab-header-active-hover-label-text-color: #01c0c8;
  --mat-tab-header-active-focus-indicator-color: #01c0c8;
  --mat-tab-header-active-hover-indicator-color: #01c0c8;
}
.cyan_theme .mat-mdc-tab-group.mat-accent, .cyan_theme .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #fb9678;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #fb9678;
  --mat-tab-header-active-ripple-color: #fb9678;
  --mat-tab-header-inactive-ripple-color: #fb9678;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #fb9678;
  --mat-tab-header-active-hover-label-text-color: #fb9678;
  --mat-tab-header-active-focus-indicator-color: #fb9678;
  --mat-tab-header-active-hover-indicator-color: #fb9678;
}
.cyan_theme .mat-mdc-tab-group.mat-warn, .cyan_theme .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.cyan_theme .mat-mdc-tab-group.mat-background-primary, .cyan_theme .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #01c0c8;
  --mat-tab-header-with-background-foreground-color: white;
}
.cyan_theme .mat-mdc-tab-group.mat-background-accent, .cyan_theme .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #fb9678;
  --mat-tab-header-with-background-foreground-color: white;
}
.cyan_theme .mat-mdc-tab-group.mat-background-warn, .cyan_theme .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}
.cyan_theme .mat-mdc-checkbox {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #fb9678;
  --mdc-checkbox-selected-hover-icon-color: #fb9678;
  --mdc-checkbox-selected-icon-color: #fb9678;
  --mdc-checkbox-selected-pressed-icon-color: #fb9678;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #fb9678;
  --mdc-checkbox-selected-hover-state-layer-color: #fb9678;
  --mdc-checkbox-selected-pressed-state-layer-color: #fb9678;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.cyan_theme .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #01c0c8;
  --mdc-checkbox-selected-hover-icon-color: #01c0c8;
  --mdc-checkbox-selected-icon-color: #01c0c8;
  --mdc-checkbox-selected-pressed-icon-color: #01c0c8;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #01c0c8;
  --mdc-checkbox-selected-hover-state-layer-color: #01c0c8;
  --mdc-checkbox-selected-pressed-state-layer-color: #01c0c8;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.cyan_theme .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.cyan_theme .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.cyan_theme .mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}
.cyan_theme .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.cyan_theme .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #01c0c8;
}
.cyan_theme .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #fb9678;
}
.cyan_theme .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.cyan_theme .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.cyan_theme .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.cyan_theme .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #01c0c8;
  --mdc-filled-button-label-text-color: #000;
}
.cyan_theme .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #fb9678;
  --mdc-filled-button-label-text-color: #000;
}
.cyan_theme .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.cyan_theme .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.cyan_theme .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.cyan_theme .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #01c0c8;
  --mdc-protected-button-label-text-color: #000;
}
.cyan_theme .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #fb9678;
  --mdc-protected-button-label-text-color: #000;
}
.cyan_theme .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.cyan_theme .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.cyan_theme .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.cyan_theme .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #01c0c8;
}
.cyan_theme .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #fb9678;
}
.cyan_theme .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.cyan_theme .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-mdc-button, .cyan_theme .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.cyan_theme .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .cyan_theme .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.cyan_theme .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .cyan_theme .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .cyan_theme .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .cyan_theme .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.cyan_theme .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .cyan_theme .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.cyan_theme .mat-mdc-button.mat-primary, .cyan_theme .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #01c0c8;
  --mat-mdc-button-ripple-color: rgba(1, 192, 200, 0.1);
}
.cyan_theme .mat-mdc-button.mat-accent, .cyan_theme .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fb9678;
  --mat-mdc-button-ripple-color: rgba(251, 150, 120, 0.1);
}
.cyan_theme .mat-mdc-button.mat-warn, .cyan_theme .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.cyan_theme .mat-mdc-raised-button, .cyan_theme .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.cyan_theme .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .cyan_theme .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.cyan_theme .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .cyan_theme .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .cyan_theme .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .cyan_theme .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.cyan_theme .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .cyan_theme .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.cyan_theme .mat-mdc-raised-button.mat-primary, .cyan_theme .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.cyan_theme .mat-mdc-raised-button.mat-accent, .cyan_theme .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.cyan_theme .mat-mdc-raised-button.mat-warn, .cyan_theme .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.cyan_theme .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.cyan_theme .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.cyan_theme .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .cyan_theme .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.cyan_theme .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.cyan_theme .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.cyan_theme .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.cyan_theme .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.cyan_theme .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #01c0c8;
  --mat-mdc-button-persistent-ripple-color: #01c0c8;
  --mat-mdc-button-ripple-color: rgba(1, 192, 200, 0.1);
}
.cyan_theme .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #fb9678;
  --mat-mdc-button-persistent-ripple-color: #fb9678;
  --mat-mdc-button-ripple-color: rgba(251, 150, 120, 0.1);
}
.cyan_theme .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.cyan_theme .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.cyan_theme .mat-mdc-fab, .cyan_theme .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.cyan_theme .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .cyan_theme .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.cyan_theme .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .cyan_theme .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .cyan_theme .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .cyan_theme .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.cyan_theme .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .cyan_theme .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.cyan_theme .mat-mdc-fab.mat-primary, .cyan_theme .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.cyan_theme .mat-mdc-fab.mat-accent, .cyan_theme .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.cyan_theme .mat-mdc-fab.mat-warn, .cyan_theme .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.cyan_theme .mat-mdc-fab.mat-unthemed, .cyan_theme .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.cyan_theme .mat-mdc-fab.mat-primary, .cyan_theme .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #01c0c8;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.cyan_theme .mat-mdc-fab.mat-accent, .cyan_theme .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #fb9678;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.cyan_theme .mat-mdc-fab.mat-warn, .cyan_theme .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.cyan_theme .mat-mdc-fab[disabled][disabled], .cyan_theme .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.cyan_theme .mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #fb9678;
}
.cyan_theme .mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, 0.12);
}
.cyan_theme .mdc-data-table__row {
  background-color: inherit;
}
.cyan_theme .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}
.cyan_theme .mdc-data-table__row--selected {
  background-color: rgba(1, 192, 200, 0.04);
}
.cyan_theme .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.cyan_theme .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.cyan_theme .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.cyan_theme .mdc-data-table__cell,
.cyan_theme .mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.cyan_theme .mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.cyan_theme .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.cyan_theme .mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}
.cyan_theme .mdc-data-table__pagination-total,
.cyan_theme .mdc-data-table__pagination-rows-per-page-label,
.cyan_theme .mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}
[dir=rtl] .cyan_theme .mdc-data-table__pagination-button .mdc-button__icon, .cyan_theme .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.cyan_theme .mat-mdc-table {
  background: white;
}
.cyan_theme .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #01c0c8;
}
.cyan_theme .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #fb9678;
}
.cyan_theme .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}
.cyan_theme .mat-badge-content {
  color: white;
  background: #01c0c8;
}
.cdk-high-contrast-active .cyan_theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.cyan_theme .mat-badge-accent .mat-badge-content {
  background: #fb9678;
  color: white;
}
.cyan_theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.cyan_theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.cyan_theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.cyan_theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.cyan_theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.cyan_theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.cyan_theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.cyan_theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.cyan_theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.cyan_theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.cyan_theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.cyan_theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.cyan_theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.cyan_theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.cyan_theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.cyan_theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.cyan_theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.cyan_theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.cyan_theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.cyan_theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.cyan_theme .mat-datepicker-toggle,
.cyan_theme .mat-datepicker-content .mat-calendar-next-button,
.cyan_theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.cyan_theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-calendar-table-header,
.cyan_theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.cyan_theme .mat-calendar-body-cell-content,
.cyan_theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.cyan_theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.cyan_theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.cyan_theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.cyan_theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.cyan_theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.cyan_theme .mat-calendar-body-in-range::before {
  background: rgba(1, 192, 200, 0.2);
}
.cyan_theme .mat-calendar-body-comparison-identical,
.cyan_theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.cyan_theme .mat-calendar-body-comparison-bridge-start::before,
.cyan_theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(1, 192, 200, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.cyan_theme .mat-calendar-body-comparison-bridge-end::before,
.cyan_theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(1, 192, 200, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.cyan_theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.cyan_theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.cyan_theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.cyan_theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.cyan_theme .mat-calendar-body-selected {
  background-color: #01c0c8;
  color: white;
}
.cyan_theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(1, 192, 200, 0.4);
}
.cyan_theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.cyan_theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cyan_theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(1, 192, 200, 0.3);
}
@media (hover: hover) {
  .cyan_theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(1, 192, 200, 0.3);
  }
}
.cyan_theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.cyan_theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(251, 150, 120, 0.2);
}
.cyan_theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.cyan_theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.cyan_theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.cyan_theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(251, 150, 120, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.cyan_theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.cyan_theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(251, 150, 120, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.cyan_theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.cyan_theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.cyan_theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.cyan_theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.cyan_theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #fb9678;
  color: white;
}
.cyan_theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(251, 150, 120, 0.4);
}
.cyan_theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.cyan_theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cyan_theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(251, 150, 120, 0.3);
}
@media (hover: hover) {
  .cyan_theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(251, 150, 120, 0.3);
  }
}
.cyan_theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.cyan_theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.cyan_theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.cyan_theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.cyan_theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.cyan_theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.cyan_theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.cyan_theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.cyan_theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.cyan_theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.cyan_theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.cyan_theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.cyan_theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.cyan_theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.cyan_theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cyan_theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .cyan_theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.cyan_theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-datepicker-toggle-active {
  color: #01c0c8;
}
.cyan_theme .mat-datepicker-toggle-active.mat-accent {
  color: #fb9678;
}
.cyan_theme .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.cyan_theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.cyan_theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.cyan_theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .cyan_theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .cyan_theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .cyan_theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.cyan_theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.cyan_theme .mat-expansion-panel-header-description,
.cyan_theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.cyan_theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.cyan_theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.cyan_theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.cyan_theme .mat-icon.mat-primary {
  color: #01c0c8;
}
.cyan_theme .mat-icon.mat-accent {
  color: #fb9678;
}
.cyan_theme .mat-icon.mat-warn {
  color: #f44336;
}
.cyan_theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.cyan_theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.cyan_theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.cyan_theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.cyan_theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.cyan_theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.cyan_theme .mat-step-header.cdk-keyboard-focused, .cyan_theme .mat-step-header.cdk-program-focused, .cyan_theme .mat-step-header:hover:not([aria-disabled]), .cyan_theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.cyan_theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .cyan_theme .mat-step-header:hover {
    background: none;
  }
}
.cyan_theme .mat-step-header .mat-step-label,
.cyan_theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.cyan_theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.cyan_theme .mat-step-header .mat-step-icon-selected,
.cyan_theme .mat-step-header .mat-step-icon-state-done,
.cyan_theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #01c0c8;
  color: white;
}
.cyan_theme .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.cyan_theme .mat-step-header.mat-accent .mat-step-icon-selected,
.cyan_theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.cyan_theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #fb9678;
  color: white;
}
.cyan_theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.cyan_theme .mat-step-header.mat-warn .mat-step-icon-selected,
.cyan_theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.cyan_theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.cyan_theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.cyan_theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.cyan_theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.cyan_theme .mat-stepper-horizontal, .cyan_theme .mat-stepper-vertical {
  background-color: white;
}
.cyan_theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-horizontal-stepper-header::before,
.cyan_theme .mat-horizontal-stepper-header::after,
.cyan_theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.cyan_theme .mat-sort-header-arrow {
  color: #757575;
}
.cyan_theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.cyan_theme .mat-toolbar.mat-primary {
  background: #01c0c8;
  color: white;
}
.cyan_theme .mat-toolbar.mat-accent {
  background: #fb9678;
  color: white;
}
.cyan_theme .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.cyan_theme .mat-toolbar .mat-form-field-underline,
.cyan_theme .mat-toolbar .mat-form-field-ripple,
.cyan_theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.cyan_theme .mat-toolbar .mat-form-field-label,
.cyan_theme .mat-toolbar .mat-focused .mat-form-field-label,
.cyan_theme .mat-toolbar .mat-select-value,
.cyan_theme .mat-toolbar .mat-select-arrow,
.cyan_theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.cyan_theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.cyan_theme .mat-tree {
  background: white;
}
.cyan_theme .mat-tree-node,
.cyan_theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.light-theme .blue_theme .mat-mdc-raised-button.mat-primary,
.light-theme .blue_theme .mat-mdc-raised-button.mat-accent,
.light-theme .blue_theme .mat-mdc-raised-button.mat-warn,
.light-theme .blue_theme .mat-mdc-unelevated-button.mat-primary,
.light-theme .blue_theme .mat-mdc-unelevated-button.mat-accent,
.light-theme .blue_theme .mat-mdc-unelevated-button.mat-warn,
.light-theme .blue_theme .mdc-fab.mat-primary,
.light-theme .blue_theme .mdc-fab.mat-accent,
.light-theme .blue_theme .mdc-fab.mat-warn,
.light-theme .purple_theme .mat-mdc-raised-button.mat-primary,
.light-theme .purple_theme .mat-mdc-raised-button.mat-accent,
.light-theme .purple_theme .mat-mdc-raised-button.mat-warn,
.light-theme .purple_theme .mat-mdc-unelevated-button.mat-primary,
.light-theme .purple_theme .mat-mdc-unelevated-button.mat-accent,
.light-theme .purple_theme .mat-mdc-unelevated-button.mat-warn,
.light-theme .purple_theme .mdc-fab.mat-primary,
.light-theme .purple_theme .mdc-fab.mat-accent,
.light-theme .purple_theme .mdc-fab.mat-warn,
.light-theme .orange_theme .mat-mdc-raised-button.mat-primary,
.light-theme .orange_theme .mat-mdc-raised-button.mat-accent,
.light-theme .orange_theme .mat-mdc-raised-button.mat-warn,
.light-theme .orange_theme .mat-mdc-unelevated-button.mat-primary,
.light-theme .orange_theme .mat-mdc-unelevated-button.mat-accent,
.light-theme .orange_theme .mat-mdc-unelevated-button.mat-warn,
.light-theme .orange_theme .mdc-fab.mat-primary,
.light-theme .orange_theme .mdc-fab.mat-accent,
.light-theme .orange_theme .mdc-fab.mat-warn,
.light-theme .green_theme .mat-mdc-raised-button.mat-primary,
.light-theme .green_theme .mat-mdc-raised-button.mat-accent,
.light-theme .green_theme .mat-mdc-raised-button.mat-warn,
.light-theme .green_theme .mat-mdc-unelevated-button.mat-primary,
.light-theme .green_theme .mat-mdc-unelevated-button.mat-accent,
.light-theme .green_theme .mat-mdc-unelevated-button.mat-warn,
.light-theme .green_theme .mdc-fab.mat-primary,
.light-theme .green_theme .mdc-fab.mat-accent,
.light-theme .green_theme .mdc-fab.mat-warn,
.light-theme .aqua_theme .mat-mdc-raised-button.mat-primary,
.light-theme .aqua_theme .mat-mdc-raised-button.mat-accent,
.light-theme .aqua_theme .mat-mdc-raised-button.mat-warn,
.light-theme .aqua_theme .mat-mdc-unelevated-button.mat-primary,
.light-theme .aqua_theme .mat-mdc-unelevated-button.mat-accent,
.light-theme .aqua_theme .mat-mdc-unelevated-button.mat-warn,
.light-theme .aqua_theme .mdc-fab.mat-primary,
.light-theme .aqua_theme .mdc-fab.mat-accent,
.light-theme .aqua_theme .mdc-fab.mat-warn,
.light-theme .cyan_theme .mat-mdc-raised-button.mat-primary,
.light-theme .cyan_theme .mat-mdc-raised-button.mat-accent,
.light-theme .cyan_theme .mat-mdc-raised-button.mat-warn,
.light-theme .cyan_theme .mat-mdc-unelevated-button.mat-primary,
.light-theme .cyan_theme .mat-mdc-unelevated-button.mat-accent,
.light-theme .cyan_theme .mat-mdc-unelevated-button.mat-warn,
.light-theme .cyan_theme .mdc-fab.mat-primary,
.light-theme .cyan_theme .mdc-fab.mat-accent,
.light-theme .cyan_theme .mdc-fab.mat-warn,
.dark-theme .mat-mdc-raised-button.mat-primary,
.dark-theme .mat-mdc-raised-button.mat-accent,
.dark-theme .mat-mdc-raised-button.mat-warn,
.dark-theme .mat-mdc-unelevated-button.mat-primary,
.dark-theme .mat-mdc-unelevated-button.mat-accent,
.dark-theme .mat-mdc-unelevated-button.mat-warn,
.dark-theme .mdc-fab.mat-primary,
.dark-theme .mdc-fab.mat-accent,
.dark-theme .mdc-fab.mat-warn {
  color: white;
}
.light-theme .blue_theme .mdc-button,
.light-theme .purple_theme .mdc-button,
.light-theme .orange_theme .mdc-button,
.light-theme .green_theme .mdc-button,
.light-theme .aqua_theme .mdc-button,
.light-theme .cyan_theme .mdc-button,
.dark-theme .mdc-button {
  border-radius: 7px;
}
.light-theme .blue_theme .mat-mdc-raised-button,
.light-theme .purple_theme .mat-mdc-raised-button,
.light-theme .orange_theme .mat-mdc-raised-button,
.light-theme .green_theme .mat-mdc-raised-button,
.light-theme .aqua_theme .mat-mdc-raised-button,
.light-theme .cyan_theme .mat-mdc-raised-button,
.dark-theme .mat-mdc-raised-button {
  box-shadow: rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
}
.light-theme .blue_theme .mat-mdc-fab,
.light-theme .blue_theme .mat-mdc-mini-fab,
.light-theme .blue_theme .mat-mdc-fab:hover,
.light-theme .blue_theme .mat-mdc-fab:focus,
.light-theme .blue_theme .mat-mdc-mini-fab:hover,
.light-theme .blue_theme .mat-mdc-mini-fab:focus,
.light-theme .purple_theme .mat-mdc-fab,
.light-theme .purple_theme .mat-mdc-mini-fab,
.light-theme .purple_theme .mat-mdc-fab:hover,
.light-theme .purple_theme .mat-mdc-fab:focus,
.light-theme .purple_theme .mat-mdc-mini-fab:hover,
.light-theme .purple_theme .mat-mdc-mini-fab:focus,
.light-theme .orange_theme .mat-mdc-fab,
.light-theme .orange_theme .mat-mdc-mini-fab,
.light-theme .orange_theme .mat-mdc-fab:hover,
.light-theme .orange_theme .mat-mdc-fab:focus,
.light-theme .orange_theme .mat-mdc-mini-fab:hover,
.light-theme .orange_theme .mat-mdc-mini-fab:focus,
.light-theme .green_theme .mat-mdc-fab,
.light-theme .green_theme .mat-mdc-mini-fab,
.light-theme .green_theme .mat-mdc-fab:hover,
.light-theme .green_theme .mat-mdc-fab:focus,
.light-theme .green_theme .mat-mdc-mini-fab:hover,
.light-theme .green_theme .mat-mdc-mini-fab:focus,
.light-theme .aqua_theme .mat-mdc-fab,
.light-theme .aqua_theme .mat-mdc-mini-fab,
.light-theme .aqua_theme .mat-mdc-fab:hover,
.light-theme .aqua_theme .mat-mdc-fab:focus,
.light-theme .aqua_theme .mat-mdc-mini-fab:hover,
.light-theme .aqua_theme .mat-mdc-mini-fab:focus,
.light-theme .cyan_theme .mat-mdc-fab,
.light-theme .cyan_theme .mat-mdc-mini-fab,
.light-theme .cyan_theme .mat-mdc-fab:hover,
.light-theme .cyan_theme .mat-mdc-fab:focus,
.light-theme .cyan_theme .mat-mdc-mini-fab:hover,
.light-theme .cyan_theme .mat-mdc-mini-fab:focus,
.dark-theme .mat-mdc-fab,
.dark-theme .mat-mdc-mini-fab,
.dark-theme .mat-mdc-fab:hover,
.dark-theme .mat-mdc-fab:focus,
.dark-theme .mat-mdc-mini-fab:hover,
.dark-theme .mat-mdc-mini-fab:focus {
  box-shadow: none;
}
.light-theme .blue_theme .mat-button-toggle-button,
.light-theme .purple_theme .mat-button-toggle-button,
.light-theme .orange_theme .mat-button-toggle-button,
.light-theme .green_theme .mat-button-toggle-button,
.light-theme .aqua_theme .mat-button-toggle-button,
.light-theme .cyan_theme .mat-button-toggle-button,
.dark-theme .mat-button-toggle-button {
  font-size: 14px;
}
@media (min-width: 768px) {
  .light-theme .blue_theme .flex-sm-row,
  .light-theme .purple_theme .flex-sm-row,
  .light-theme .orange_theme .flex-sm-row,
  .light-theme .green_theme .flex-sm-row,
  .light-theme .aqua_theme .flex-sm-row,
  .light-theme .cyan_theme .flex-sm-row,
  .dark-theme .flex-sm-row {
    flex-direction: row !important;
  }
}
.light-theme .blue_theme .flex-column,
.light-theme .purple_theme .flex-column,
.light-theme .orange_theme .flex-column,
.light-theme .green_theme .flex-column,
.light-theme .aqua_theme .flex-column,
.light-theme .cyan_theme .flex-column,
.dark-theme .flex-column {
  flex-direction: column;
}
.light-theme .blue_theme .mat-drawer,
.light-theme .purple_theme .mat-drawer,
.light-theme .orange_theme .mat-drawer,
.light-theme .green_theme .mat-drawer,
.light-theme .aqua_theme .mat-drawer,
.light-theme .cyan_theme .mat-drawer,
.dark-theme .mat-drawer {
  background-color: white;
}
.light-theme .blue_theme .mat-drawer-container,
.light-theme .purple_theme .mat-drawer-container,
.light-theme .orange_theme .mat-drawer-container,
.light-theme .green_theme .mat-drawer-container,
.light-theme .aqua_theme .mat-drawer-container,
.light-theme .cyan_theme .mat-drawer-container,
.dark-theme .mat-drawer-container {
  background-color: white;
}
.light-theme .blue_theme .mdc-text-field--outlined .mdc-notched-outline__leading,
.light-theme .blue_theme .mdc-text-field--outlined .mdc-notched-outline__trailing,
.light-theme .blue_theme .mdc-text-field--outlined .mdc-notched-outline__notch,
.light-theme .purple_theme .mdc-text-field--outlined .mdc-notched-outline__leading,
.light-theme .purple_theme .mdc-text-field--outlined .mdc-notched-outline__trailing,
.light-theme .purple_theme .mdc-text-field--outlined .mdc-notched-outline__notch,
.light-theme .orange_theme .mdc-text-field--outlined .mdc-notched-outline__leading,
.light-theme .orange_theme .mdc-text-field--outlined .mdc-notched-outline__trailing,
.light-theme .orange_theme .mdc-text-field--outlined .mdc-notched-outline__notch,
.light-theme .green_theme .mdc-text-field--outlined .mdc-notched-outline__leading,
.light-theme .green_theme .mdc-text-field--outlined .mdc-notched-outline__trailing,
.light-theme .green_theme .mdc-text-field--outlined .mdc-notched-outline__notch,
.light-theme .aqua_theme .mdc-text-field--outlined .mdc-notched-outline__leading,
.light-theme .aqua_theme .mdc-text-field--outlined .mdc-notched-outline__trailing,
.light-theme .aqua_theme .mdc-text-field--outlined .mdc-notched-outline__notch,
.light-theme .cyan_theme .mdc-text-field--outlined .mdc-notched-outline__leading,
.light-theme .cyan_theme .mdc-text-field--outlined .mdc-notched-outline__trailing,
.light-theme .cyan_theme .mdc-text-field--outlined .mdc-notched-outline__notch,
.dark-theme .mdc-text-field--outlined .mdc-notched-outline__leading,
.dark-theme .mdc-text-field--outlined .mdc-notched-outline__trailing,
.dark-theme .mdc-text-field--outlined .mdc-notched-outline__notch {
  border-color: #e5eaef;
}
.light-theme .blue_theme .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading,
.light-theme .purple_theme .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading,
.light-theme .orange_theme .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading,
.light-theme .green_theme .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading,
.light-theme .aqua_theme .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading,
.light-theme .cyan_theme .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading,
.dark-theme .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
.light-theme .blue_theme .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing,
.light-theme .purple_theme .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing,
.light-theme .orange_theme .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing,
.light-theme .green_theme .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing,
.light-theme .aqua_theme .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing,
.light-theme .cyan_theme .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing,
.dark-theme .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
.light-theme .blue_theme .mdc-form-field > label,
.light-theme .purple_theme .mdc-form-field > label,
.light-theme .orange_theme .mdc-form-field > label,
.light-theme .green_theme .mdc-form-field > label,
.light-theme .aqua_theme .mdc-form-field > label,
.light-theme .cyan_theme .mdc-form-field > label,
.dark-theme .mdc-form-field > label {
  font-size: 14px;
}
.light-theme .blue_theme .hide-hint .mat-mdc-form-field-subscript-wrapper,
.light-theme .purple_theme .hide-hint .mat-mdc-form-field-subscript-wrapper,
.light-theme .orange_theme .hide-hint .mat-mdc-form-field-subscript-wrapper,
.light-theme .green_theme .hide-hint .mat-mdc-form-field-subscript-wrapper,
.light-theme .aqua_theme .hide-hint .mat-mdc-form-field-subscript-wrapper,
.light-theme .cyan_theme .hide-hint .mat-mdc-form-field-subscript-wrapper,
.dark-theme .hide-hint .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.light-theme .blue_theme .mdc-checkbox__background,
.light-theme .purple_theme .mdc-checkbox__background,
.light-theme .orange_theme .mdc-checkbox__background,
.light-theme .green_theme .mdc-checkbox__background,
.light-theme .aqua_theme .mdc-checkbox__background,
.light-theme .cyan_theme .mdc-checkbox__background,
.dark-theme .mdc-checkbox__background {
  border: 1px solid #dfe5ef !important;
  width: 19px;
  height: 19px;
  border-radius: 3px;
}
.light-theme .blue_theme .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark,
.light-theme .purple_theme .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark,
.light-theme .orange_theme .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark,
.light-theme .green_theme .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark,
.light-theme .aqua_theme .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark,
.light-theme .cyan_theme .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark,
.dark-theme .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #ffffff !important;
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 15px !important;
  height: 15px !important;
  top: unset;
  bottom: unset;
}
.light-theme .blue_theme .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark,
.light-theme .purple_theme .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark,
.light-theme .orange_theme .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark,
.light-theme .green_theme .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark,
.light-theme .aqua_theme .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark,
.light-theme .cyan_theme .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark,
.dark-theme .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  border-color: #ffffff !important;
}
.light-theme .blue_theme .mat-mdc-checkbox-checked .mdc-checkbox__background,
.light-theme .purple_theme .mat-mdc-checkbox-checked .mdc-checkbox__background,
.light-theme .orange_theme .mat-mdc-checkbox-checked .mdc-checkbox__background,
.light-theme .green_theme .mat-mdc-checkbox-checked .mdc-checkbox__background,
.light-theme .aqua_theme .mat-mdc-checkbox-checked .mdc-checkbox__background,
.light-theme .cyan_theme .mat-mdc-checkbox-checked .mdc-checkbox__background,
.dark-theme .mat-mdc-checkbox-checked .mdc-checkbox__background {
  border: 0 !important;
}
.light-theme .blue_theme .mdc-radio__outer-circle,
.light-theme .purple_theme .mdc-radio__outer-circle,
.light-theme .orange_theme .mdc-radio__outer-circle,
.light-theme .green_theme .mdc-radio__outer-circle,
.light-theme .aqua_theme .mdc-radio__outer-circle,
.light-theme .cyan_theme .mdc-radio__outer-circle,
.dark-theme .mdc-radio__outer-circle {
  border-width: 1px;
}
.light-theme .blue_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle,
.light-theme .purple_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle,
.light-theme .orange_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle,
.light-theme .green_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle,
.light-theme .aqua_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle,
.light-theme .cyan_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle,
.dark-theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: #e5eaef;
}
.light-theme .blue_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
.light-theme .purple_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
.light-theme .orange_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
.light-theme .green_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
.light-theme .aqua_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
.light-theme .cyan_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
.dark-theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
  border-width: 10px;
}
.light-theme .blue_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle,
.light-theme .purple_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle,
.light-theme .orange_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle,
.light-theme .green_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle,
.light-theme .aqua_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle,
.light-theme .cyan_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle,
.dark-theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: #ffffff;
}
.light-theme .blue_theme .topbar,
.light-theme .purple_theme .topbar,
.light-theme .orange_theme .topbar,
.light-theme .green_theme .topbar,
.light-theme .aqua_theme .topbar,
.light-theme .cyan_theme .topbar,
.dark-theme .topbar {
  background-color: white;
}

.light-theme .mat-mdc-card,
.dark-theme .mat-mdc-card {
  margin-bottom: 30px;
  border-radius: 7px;
}
.light-theme .mat-mdc-card-header,
.dark-theme .mat-mdc-card-header {
  padding: 24px 24px 0;
}
.light-theme .mat-mdc-card-content,
.dark-theme .mat-mdc-card-content {
  padding: 0 24px;
}
.light-theme .mat-mdc-card,
.dark-theme .mat-mdc-card {
  background-color: white;
}
.light-theme .cardWithShadow,
.dark-theme .cardWithShadow {
  box-shadow: rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
}
.light-theme .mat-mdc-card-title,
.dark-theme .mat-mdc-card-title {
  line-height: 1.6rem;
}
.light-theme .mdc-card__actions,
.dark-theme .mdc-card__actions {
  padding: 24px;
}
.light-theme .theme-card.mat-mdc-card .mat-mdc-card-header,
.dark-theme .theme-card.mat-mdc-card .mat-mdc-card-header {
  padding: 16px 24px;
}
.light-theme .theme-card.mat-mdc-card .mat-mdc-card-content,
.dark-theme .theme-card.mat-mdc-card .mat-mdc-card-content {
  padding: 24px;
}
.light-theme .card-hover,
.dark-theme .card-hover {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.light-theme .card-hover:hover,
.dark-theme .card-hover:hover {
  scale: 1.01;
  transition: all 0.1s ease-in 0s;
}
.light-theme html .mat-mdc-chip,
.dark-theme html .mat-mdc-chip {
  height: 24px;
  font-size: 14px;
  background-color: #f2f6fa;
}
.light-theme .cardBorder .mdc-card,
.dark-theme .cardBorder .mdc-card {
  box-shadow: none !important;
  border: 1px solid #e5eaef !important;
}
.light-theme .cardBorder .mdc-card.shadow-none,
.dark-theme .cardBorder .mdc-card.shadow-none {
  border: 0 !important;
}
.light-theme .mat-subtitle-2,
.light-theme .mat-subtitle-1,
.light-theme .mat-headline-5,
.light-theme .mat-headline-6,
.light-theme .mat-subtitle-1,
.light-theme .mat-subtitle-2,
.light-theme .mat-body-1,
.light-theme .mat-body-2,
.dark-theme .mat-subtitle-2,
.dark-theme .mat-subtitle-1,
.dark-theme .mat-headline-5,
.dark-theme .mat-headline-6,
.dark-theme .mat-subtitle-1,
.dark-theme .mat-subtitle-2,
.dark-theme .mat-body-1,
.dark-theme .mat-body-2 {
  color: #2a3547;
}
.light-theme .mat-headline-5,
.dark-theme .mat-headline-5 {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  font-family: Plus Jakarta Sans;
  letter-spacing: normal;
  margin: 0;
  color: #2a3547;
}
.light-theme .mat-headline-6,
.dark-theme .mat-headline-6 {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  font-family: Plus Jakarta Sans;
  letter-spacing: normal;
  margin: 0;
  color: #2a3547;
}
.light-theme .mat-subtitle-2,
.dark-theme .mat-subtitle-2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  font-family: Plus Jakarta Sans;
  letter-spacing: normal;
  margin: 0;
  color: #2a3547;
}
.light-theme .mat-subtitle-1,
.dark-theme .mat-subtitle-1 {
  font-size: 21px;
  font-weight: 500;
  line-height: 26px;
  font-family: Plus Jakarta Sans;
  letter-spacing: normal;
  margin: 0;
  color: #2a3547;
}
.light-theme .mat-body-1,
.dark-theme .mat-body-1 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Plus Jakarta Sans;
  letter-spacing: normal;
  margin: 0;
  color: #2a3547;
}
.light-theme .mat-body-2,
.dark-theme .mat-body-2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  font-family: Plus Jakarta Sans;
  letter-spacing: normal;
  margin: 0;
  color: #2a3547;
}
.light-theme .mdc-list-item__primary-text,
.light-theme .mat-drawer-container,
.light-theme .mat-drawer,
.dark-theme .mdc-list-item__primary-text,
.dark-theme .mat-drawer-container,
.dark-theme .mat-drawer {
  color: #2a3547;
}

.mat-drawer-side {
  border-right: 1px solid #e5eaef;
}

.mat-divider {
  border-top-color: #e5eaef;
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
  padding: 16px;
}

.mdc-data-table__cell, .mdc-data-table__header-cell {
  border-bottom-color: #e5eaef;
}

.mat-mdc-menu-item:hover:not([disabled]) {
  background: #f6f9fc;
}

.dark-theme .mat-headline-5,
.dark-theme .mat-subtitle-1,
.dark-theme .mat-headline-6,
.dark-theme .mat-button-toggle-button,
.dark-theme .mat-drawer-container,
.dark-theme .mdc-data-table__header-cell,
.dark-theme .breadcrumb-item a {
  color: #eaeff4;
}
.dark-theme .mat-subtitle-2,
.dark-theme .mat-body-1,
.dark-theme .mat-body-2,
.dark-theme .ngx-pagination a,
.dark-theme .ngx-pagination button,
.dark-theme .mdc-data-table__pagination-total,
.dark-theme .mdc-data-table__pagination-rows-per-page-label,
.dark-theme .mdc-data-table__cell,
.dark-theme .mat-mdc-icon-button,
.dark-theme .mdc-list-item__secondary-text {
  color: #7c8fac;
}
.dark-theme .mat-drawer {
  color: #7c8fac !important;
  background-color: #2a3447;
}
.dark-theme .mat-drawer-container {
  background-color: #2a3447;
}
.dark-theme .mat-mdc-card,
.dark-theme .mat-mdc-table,
.dark-theme .cal-week-view,
.dark-theme .cal-week-view .cal-hour-odd,
.dark-theme .mat-datepicker-content,
.dark-theme .mat-stepper-horizontal,
.dark-theme .mat-stepper-vertical {
  background-color: #2a3447;
}
.dark-theme .bg-white,
.dark-theme .mdc-menu-surface {
  background-color: #2a3447 !important;
}
.dark-theme .topbar {
  background-color: #2a3447;
}
.dark-theme .cardWithShadow {
  box-shadow: rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.02) 0px 12px 24px -4px;
}
.dark-theme .timeline .timeline-item .timline-border {
  background-color: #333f55;
}
.dark-theme .mat-mdc-paginator,
.dark-theme .todo-item .edit-view,
.dark-theme .mat-button-toggle-appearance-standard,
.dark-theme .mat-tree,
.dark-theme .mat-expansion-panel {
  background: #2a3447 !important;
}
.dark-theme .cal-month-view .cal-cell-row:hover,
.dark-theme .cal-month-view .cal-cell-row .cal-cell:hover,
.dark-theme .cal-month-view .cal-cell.cal-has-events.cal-open,
.dark-theme .cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover,
.dark-theme .cal-week-view .cal-day-headers .cal-header:hover,
.dark-theme .cal-week-view .cal-day-headers .cal-drag-over,
.dark-theme .bg-extra-light {
  background-color: #333f55;
}
.dark-theme .or-border:after,
.dark-theme .or-border:before {
  background: #333f55;
}
.dark-theme .theme-select .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading,
.dark-theme .theme-select .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #333f55;
}
.dark-theme .cal-month-view .cal-day-cell:not(:last-child) {
  border-right-color: #333f55;
}
.dark-theme .cal-month-view .cal-days .cal-cell-row,
.dark-theme .cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
.dark-theme .cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
  border-bottom-color: #333f55;
}
.dark-theme .b-1 {
  border: 1px solid #333f55 !important;
}
.dark-theme .mdc-checkbox__background {
  border: 1px solid #465670 !important;
}
.dark-theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: #465670;
}
.dark-theme .mdc-text-field--outlined .mdc-notched-outline__leading,
.dark-theme .mdc-text-field--outlined .mdc-notched-outline__trailing,
.dark-theme .mdc-text-field--outlined .mdc-notched-outline__notch,
.dark-theme .cal-month-view .cal-days,
.dark-theme .cal-week-view,
.dark-theme .cal-week-view .cal-day-headers,
.dark-theme .cal-week-view .cal-time-events,
.dark-theme .cal-week-view .cal-day-column,
.dark-theme .cal-week-view .cal-day-headers .cal-header:first-child,
.dark-theme .cal-week-view .cal-day-headers .cal-header:not(:last-child),
.dark-theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: #465670 !important;
}
.dark-theme .b-b-1 {
  border-bottom: 1px solid #333f55 !important;
}
.dark-theme .b-t-1 {
  border-top: 1px solid #333f55 !important;
}
.dark-theme .b-r-1 {
  border-right: 1px solid #333f55 !important;
}
.dark-theme .b-l-1 {
  border-left: 1px solid #333f55;
}
.dark-theme .bg-light-primary {
  background-color: #253662 !important;
}
.dark-theme .bg-light-accent {
  background-color: #1C455D !important;
}
.dark-theme .bg-light-error {
  background-color: #4B313D !important;
}
.dark-theme .bg-light-success {
  background-color: #1B3C48 !important;
}
.dark-theme .bg-light-warning {
  background-color: #4D3A2A !important;
}
.dark-theme .todos .taskboard-task {
  background: #253662 !important;
}
.dark-theme .inprogress .taskboard-task {
  background: #4D3A2A !important;
}
.dark-theme .completed .taskboard-task {
  background: #1B3C48 !important;
}
.dark-theme .onhold .taskboard-task {
  background: #4B313D !important;
}
.dark-theme .mat-mdc-dialog-container {
  --mdc-dialog-container-color: #2a3447;
}
.dark-theme .customizer-button-group .mat-button-toggle-appearance-standard.mat-button-toggle-checked i-tabler.fill-icon {
  fill: #253662;
}
.dark-theme .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #333f55;
}
.dark-theme .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #333f55;
}
.dark-theme .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #333f55;
}
.dark-theme .mat-mdc-button,
.dark-theme .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #7c8fac !important;
}
.dark-theme .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #7c8fac !important;
}
.dark-theme .mdc-list-item__primary-text {
  color: #7c8fac !important;
}
.dark-theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.dark-theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: #7c8fac !important;
}
.dark-theme .mat-mdc-select-value {
  color: rgba(255, 255, 255, 0.87) !important;
}
.dark-theme .mat-mdc-select-arrow svg {
  fill: rgba(255, 255, 255, 0.87) !important;
}
.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label,
.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(255, 255, 255, 0.6) !important;
}
.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label .mat-mdc-icon-button[disabled][disabled],
.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(255, 255, 255, 0.5) !important;
  --mdc-icon-button-disabled-icon-color: rgba(
    255,
    255,
    255,
    0.5
  ) !important;
}

html.dark-theme .cardBorder .mdc-card {
  box-shadow: none !important;
  border: 1px solid #333f55 !important;
}
html.dark-theme .cardBorder .mdc-card.shadow-none {
  border: 0 !important;
}
html.dark-theme .sidebarNav-horizontal .horizontal-navbar .parentBox:hover > .activeMenu,
html.dark-theme .sidebarNav-horizontal .horizontal-navbar .parentBox .activeMenu {
  color: #ffffff;
}
html.dark-theme .sidebarNav-horizontal .horizontal-navbar .parentBox .menuLink {
  color: #7c8fac;
}
html.dark-theme .sidebarNav-horizontal .horizontal-navbar .parentBox .childBox {
  background-color: #2a3447;
}

html.dark-theme .bg-gredient::before {
  opacity: 0.05;
}
html.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder,
html.dark-theme .mdc-text-field__input::placeholder,
html.dark-theme .mat-mdc-tab .mdc-tab__text-label,
html.dark-theme .mat-mdc-tab-link .mdc-tab__text-label,
html.dark-theme .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label,
html.dark-theme .mat-mdc-checkbox-disabled label {
  color: rgba(255, 255, 255, 0.6) !important;
}
html.dark-theme .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
html.dark-theme .mat-expansion-panel-header-description,
html.dark-theme .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
html.dark-theme .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(255, 255, 255, 0.38);
}
html.dark-theme .mat-calendar-table-header,
html.dark-theme .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
html.dark-theme .mat-calendar-arrow {
  fill: white;
}
html.dark-theme .mat-mdc-radio-button .mdc-form-field,
html.dark-theme .mat-calendar-body-cell-content,
html.dark-theme .mat-date-range-input-separator,
html.dark-theme .mat-expansion-panel-header-title,
html.dark-theme .mat-step-header .mat-step-label.mat-step-label-active,
html.dark-theme .mat-expansion-panel-header-title {
  color: white;
}
html.dark-theme .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
}
html.dark-theme .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
}
html.dark-theme .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
}
html.dark-theme .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
}
html.dark-theme .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
}
html.dark-theme .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
html.dark-theme .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #fff;
}
html.dark-theme .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-label-text-color: #fff;
}
html.dark-theme .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-label-text-color: #fff;
}
html.dark-theme .mdc-data-table__cell,
html.dark-theme .mdc-data-table__header-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
html.dark-theme .mat-mdc-checkbox .mdc-form-field,
html.dark-theme .mat-mdc-paginator,
html.dark-theme .mat-mdc-slide-toggle .mdc-form-field {
  color: rgba(255, 255, 255, 0.87) !important;
}
html.dark-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
html.dark-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #333f55;
}
html.dark-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #333f55;
}
html.dark-theme .bg-primary {
  background-color: #5d87ff !important;
}
html.dark-theme .text-primary {
  color: #5d87ff !important;
}
html.dark-theme .bg-accent {
  background-color: #49beff !important;
}
html.dark-theme .text-accent {
  color: #49beff !important;
}
html.dark-theme .bg-success {
  background-color: #13deb9 !important;
}
html.dark-theme .text-success {
  color: #13deb9 !important;
}
html.dark-theme .bg-warning {
  background-color: #ffae1f !important;
}
html.dark-theme .text-warning {
  color: #ffae1f !important;
}
html.dark-theme .bg-error {
  background-color: #fa896b !important;
}
html.dark-theme .text-error {
  color: #fa896b !important;
}

*,
:after,
:before {
  box-sizing: border-box;
}

body {
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #2a3547;
  font-size: 14px;
  line-height: 1.334rem;
}

html .mat-drawer-container {
  background-color: #ffffff;
}

.mainWrapper {
  display: flex;
  min-height: 100vh;
  width: 100%;
  flex: 1;
  height: 100%;
}

.pageWrapper {
  padding: 24px;
  min-height: calc(100vh - 70px);
  margin: 0 auto;
}
.pageWrapper.maxWidth {
  max-width: 100%;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.vh-30 {
  height: 30vh !important;
}

.shadow-none {
  box-shadow: none !important;
}

.rounded {
  border-radius: 7px !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 25px !important;
}

.overflow-hidden {
  overflow: hidden;
}

.text-decoration-none {
  text-decoration: none;
}

.position-relative {
  position: relative;
}

.table-responsive {
  overflow-x: auto;
}
.table-responsive td,
.table-responsive mat-cell {
  white-space: nowrap;
  padding: 16px;
}

.op-5 {
  opacity: 0.5;
}

.cursor-pointer {
  cursor: pointer !important;
}

.avatar-group img {
  border: 2px solid white;
  margin-right: -5px;
}
.avatar-group img:last-child {
  margin-right: 0;
}

.text-hover-primary:hover .hover-text {
  color: #5d87ff;
}

.filter-sidebar {
  width: 290px;
}

.sidebarNav {
  width: 270px;
  flex-shrink: 0;
  transition: width 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  position: absolute;
  overflow-x: hidden;
}

.branding {
  padding: 5px;
  display: flex;
  justify-content: center;
}

.size-image {
  width: 60px;
  height: 60px;
}

.sidebarNav-mini .branding {
  width: 65px;
  overflow: hidden;
  display: block;
}
.sidebarNav-mini .sidebarNav {
  width: 80px;
}
.sidebarNav-mini .sidebarNav .profile-bar {
  display: none;
}
.sidebarNav-mini .sidebarNav .sidebar-list .menu-list-item {
  padding: 8px 17px;
}
.sidebarNav-mini .sidebarNav .sidebar-list .menu-list-item .mdc-list-item__content {
  display: none;
}
.sidebarNav-mini .sidebarNav .sidebar-list .menu-list-item .mdc-list-item__start {
  margin-left: 6px !important;
  margin-right: 8px !important;
}
.sidebarNav-mini .sidebarNav:hover {
  width: 270px;
}
.sidebarNav-mini .sidebarNav:hover .branding {
  width: 100% !important;
  display: flex;
  justify-content: center;
  padding: 5px;
}
.sidebarNav-mini .sidebarNav:hover .profile-bar {
  display: block;
}
.sidebarNav-mini .sidebarNav:hover .sidebar-list .menu-list-item {
  padding: 8px 10px;
}
.sidebarNav-mini .sidebarNav:hover .sidebar-list .menu-list-item .mdc-list-item__content {
  display: inline;
}
.sidebarNav-mini .sidebarNav:hover .sidebar-list .menu-list-item .mdc-list-item__start {
  margin-left: 0 !important;
}
.sidebarNav-mini .sidebarNav:hover .sidebar-list.mdc-list {
  padding: 0 24px;
}
.sidebarNav-mini .sidebarNav:hover .sidebar-list.mdc-list .mdc-list-group__subheader {
  text-align: left;
}
.sidebarNav-mini .hideMenu {
  overflow: hidden;
  width: 80px;
}
.sidebarNav-mini .sidebar-list.mdc-list {
  padding: 0 12px;
}
.sidebarNav-mini .sidebar-list.mdc-list .mdc-list-group__subheader {
  text-align: center;
}
.sidebarNav-mini .contentWrapper {
  transition: width 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

@media (min-width: 1024px) {
  .sidebarNav-mini .contentWrapper {
    margin-left: 80px !important;
  }
}
.customizerBtn {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 9;
}

.sidebar-list.mdc-list {
  padding: 0 24px;
}
.sidebar-list.mdc-list .mdc-list-group__subheader {
  margin: 12px 0;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
  margin-top: 24px;
}
.sidebar-list.mdc-list .menu-list-item {
  border-radius: 7px;
  height: 45px;
  padding: 8px 10px !important;
  margin-bottom: 2px;
}
.sidebar-list.mdc-list .menu-list-item.twoline {
  height: 60px;
  align-items: center;
}
.sidebar-list.mdc-list .menu-list-item:before, .sidebar-list.mdc-list .menu-list-item:focus {
  z-index: -1;
}
.sidebar-list.mdc-list .menu-list-item.disabled {
  opacity: 0.38;
}
.sidebar-list.mdc-list .menu-list-item .item-chip {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.sidebar-list.mdc-list .menu-list-item.activeMenu .mdc-list-item__primary-text {
  color: #ffffff !important;
}
.sidebar-list.mdc-list .menu-list-item.activeMenu .mat-mdc-list-item-icon {
  color: #ffffff !important;
}
.sidebar-list.mdc-list .menu-list-item .mdc-list-item__start {
  margin-right: 14px;
  margin-left: 0 !important;
  width: 20px;
  height: 20px;
  fill: transparent !important;
  color: #2a3547;
}
.sidebar-list.mdc-list .menu-list-item .mdc-list-item__primary-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar-list.mdc-list .menu-list-item .mdc-list-item__primary-text .arrow-icon {
  display: flex;
}
.sidebar-list.mdc-list .menu-list-item .mdc-list-item__primary-text .arrow-icon .mat-icon {
  font-size: 18px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

html .topbar {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 9;
  height: 70px;
}

.topbar-dd {
  min-width: 360px !important;
}

.apps-dd {
  min-width: 830px !important;
  overflow: unset !important;
}
.apps-dd .mat-mdc-menu-content {
  padding: 0;
}

.upgrade-bg {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
}

.object-cover {
  object-fit: cover;
}

@media (min-width: 768px) {
  .search-dialog {
    width: 600px;
  }
}
.ps__rail-y {
  right: 0;
  left: unset !important;
}

.customizer-button-group.two-row {
  display: inline-block;
}
.customizer-button-group.two-row.theme-colors .mat-button-toggle-appearance-standard {
  margin-left: 16px;
}
.customizer-button-group.two-row.theme-colors .mat-button-toggle-appearance-standard:first-child {
  margin-left: 0;
}
.customizer-button-group.two-row .mat-button-toggle-appearance-standard {
  width: 80px;
  height: 65px;
  float: left;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.customizer-button-group.two-row .mat-button-toggle-appearance-standard .mat-button-toggle-button {
  display: flex;
  justify-content: center;
}
.customizer-button-group.two-row .mat-button-toggle-appearance-standard:nth-child(4) {
  margin-left: 0;
}
.customizer-button-group.two-row .mat-button-toggle-appearance-standard.mat-button-toggle-checked .theme-circle .theme-icon {
  display: flex;
  color: white;
}
.customizer-button-group.two-row .mat-button-toggle-appearance-standard .theme-circle {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.customizer-button-group.two-row .mat-button-toggle-appearance-standard .theme-circle .theme-icon {
  display: none;
}
.customizer-button-group.two-row .mat-button-toggle-appearance-standard .theme-circle.orange_theme {
  background-color: rgb(250, 137, 107);
}
.customizer-button-group.two-row .mat-button-toggle-appearance-standard .theme-circle.blue_theme {
  background-color: rgb(93, 135, 255);
}
.customizer-button-group.two-row .mat-button-toggle-appearance-standard .theme-circle.aqua_theme {
  background-color: rgb(0, 116, 186);
}
.customizer-button-group.two-row .mat-button-toggle-appearance-standard .theme-circle.purple_theme {
  background-color: rgb(118, 62, 189);
}
.customizer-button-group.two-row .mat-button-toggle-appearance-standard .theme-circle.green_theme {
  background-color: rgb(10, 126, 164);
}
.customizer-button-group.two-row .mat-button-toggle-appearance-standard .theme-circle.cyan_theme {
  background-color: rgb(1, 192, 200);
}
.customizer-button-group .mat-button-toggle-appearance-standard {
  padding: 9px;
  border: 0;
  box-shadow: rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  border-radius: 7px;
  border-left: 0 !important;
  transition: all 0.1s ease-in 0s;
}
.customizer-button-group .mat-button-toggle-appearance-standard:hover {
  transform: scale(1.05);
}
.customizer-button-group .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
  background-color: transparent;
  color: #2a3547;
}
.customizer-button-group .mat-button-toggle-appearance-standard.mat-button-toggle-checked i-tabler.fill-icon {
  color: #5d87ff;
  fill: #ecf2ff;
}
.customizer-button-group.mat-button-toggle-group {
  overflow: unset;
  border-radius: 0;
}
.customizer-button-group .mat-button-toggle-standalone.mat-button-toggle-appearance-standard, .customizer-button-group.mat-button-toggle-group-appearance-standard {
  border: 0 !important;
}

.breadcrumb-icon {
  position: absolute;
  top: -20px;
  right: 19px;
}

.breadcrumb-item:first-child {
  margin-left: -20px;
}
.breadcrumb-item.active {
  margin-left: 20px;
}
.breadcrumb-item a {
  text-decoration: none;
  color: #2a3547;
  font-size: 14px;
}

.cursor-pointer {
  cursor: pointer;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.offset-3 {
  margin-left: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.offset-6 {
  margin-left: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.offset-9 {
  margin-left: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.offset-12 {
  margin-left: 100%;
}

@media (min-width: 600px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .offset-sm-12 {
    margin-left: 100%;
  }
}
@media (min-width: 960px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .offset-md-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1280px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .offset-lg-12 {
    margin-left: 100%;
  }
}
@media (min-width: 2100px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .offset-xl-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1200px) {
  body .d-lg-flex {
    display: flex !important;
  }
  body .d-lg-none {
    display: none !important;
  }
  body .align-items-lg-center {
    align-items: center !important;
  }
}
@media (min-width: 768px) {
  body .d-sm-flex {
    display: flex !important;
  }
}
@media (max-width: 767px) {
  .p-xs-6 {
    padding: 0 6px !important;
  }
}
.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-1-auto {
  flex: 1 1 0%;
}

.lh-lg {
  line-height: 36px !important;
}

.lh-sm {
  line-height: 20px !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-col {
  flex-direction: column !important;
}

.flex-col-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.m-0 {
  margin: 0 !important;
}

.m-2 {
  margin: 2px !important;
}

.m-4 {
  margin: 4px !important;
}

.m-6 {
  margin: 7px !important;
}

.m-8 {
  margin: 8px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-12 {
  margin: 12px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-16 {
  margin: 16px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-24 {
  margin: 24px !important;
}

.m-30 {
  margin: 30px !important;
}

.m-32 {
  margin: 32px !important;
}

.m-48 {
  margin: 48px !important;
}

.m-auto {
  margin: auto !important;
}

.m-x-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.m-x-2 {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.m-x-4 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.m-x-6 {
  margin-left: 7px !important;
  margin-right: 7px !important;
}

.m-x-8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.m-x-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.m-x-12 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.m-x-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.m-x-16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.m-x-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.m-x-24 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.m-x-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.m-x-32 {
  margin-left: 32px !important;
  margin-right: 32px !important;
}

.m-x-48 {
  margin-left: 48px !important;
  margin-right: 48px !important;
}

.m-x-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.m-y-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-y-2 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.m-y-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.m-y-6 {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}

.m-y-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.m-y-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.m-y-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.m-y-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.m-y-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.m-y-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.m-y-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.m-y-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.m-y-32 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.m-y-48 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.m-y-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-t-2 {
  margin-top: 2px !important;
}

.m-t-4 {
  margin-top: 4px !important;
}

.m-t-6 {
  margin-top: 7px !important;
}

.m-t-8 {
  margin-top: 8px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-12 {
  margin-top: 12px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-16 {
  margin-top: 16px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-24 {
  margin-top: 24px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-32 {
  margin-top: 32px !important;
}

.m-t-48 {
  margin-top: 48px !important;
}

.m-t-auto {
  margin-top: auto !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-b-2 {
  margin-bottom: 2px !important;
}

.m-b-4 {
  margin-bottom: 4px !important;
}

.m-b-6 {
  margin-bottom: 7px !important;
}

.m-b-8 {
  margin-bottom: 8px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-12 {
  margin-bottom: 12px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-16 {
  margin-bottom: 16px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-24 {
  margin-bottom: 24px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-b-32 {
  margin-bottom: 32px !important;
}

.m-b-48 {
  margin-bottom: 48px !important;
}

.m-b-auto {
  margin-bottom: auto !important;
}

.m--2 {
  margin: -2px !important;
}

.m--4 {
  margin: -4px !important;
}

.m--6 {
  margin: -7px !important;
}

.m--8 {
  margin: -8px !important;
}

.m--10 {
  margin: -10px !important;
}

.m--12 {
  margin: -12px !important;
}

.m--15 {
  margin: -15px !important;
}

.m--16 {
  margin: -16px !important;
}

.m--20 {
  margin: -20px !important;
}

.m--24 {
  margin: -24px !important;
}

.m--30 {
  margin: -30px !important;
}

.m--32 {
  margin: -32px !important;
}

.m--48 {
  margin: -48px !important;
}

.m-x--2 {
  margin-left: -2px !important;
  margin-right: -2px !important;
}

.m-x--4 {
  margin-left: -4px !important;
  margin-right: -4px !important;
}

.m-x--6 {
  margin-left: -7px !important;
  margin-right: -7px !important;
}

.m-x--8 {
  margin-left: -8px !important;
  margin-right: -8px !important;
}

.m-x--10 {
  margin-left: -10px !important;
  margin-right: -10px !important;
}

.m-x--12 {
  margin-left: -12px !important;
  margin-right: -12px !important;
}

.m-x--15 {
  margin-left: -15px !important;
  margin-right: -15px !important;
}

.m-x--16 {
  margin-left: -16px !important;
  margin-right: -16px !important;
}

.m-x--20 {
  margin-left: -20px !important;
  margin-right: -20px !important;
}

.m-x--24 {
  margin-left: -24px !important;
  margin-right: -24px !important;
}

.m-x--30 {
  margin-left: -30px !important;
  margin-right: -30px !important;
}

.m-x--32 {
  margin-left: -32px !important;
  margin-right: -32px !important;
}

.m-x--48 {
  margin-left: -48px !important;
  margin-right: -48px !important;
}

.m-y--2 {
  margin-top: -2px !important;
  margin-bottom: -2px !important;
}

.m-y--4 {
  margin-top: -4px !important;
  margin-bottom: -4px !important;
}

.m-y--6 {
  margin-top: -7px !important;
  margin-bottom: -7px !important;
}

.m-y--8 {
  margin-top: -8px !important;
  margin-bottom: -8px !important;
}

.m-y--10 {
  margin-top: -10px !important;
  margin-bottom: -10px !important;
}

.m-y--12 {
  margin-top: -12px !important;
  margin-bottom: -12px !important;
}

.m-y--15 {
  margin-top: -15px !important;
  margin-bottom: -15px !important;
}

.m-y--16 {
  margin-top: -16px !important;
  margin-bottom: -16px !important;
}

.m-y--20 {
  margin-top: -20px !important;
  margin-bottom: -20px !important;
}

.m-y--24 {
  margin-top: -24px !important;
  margin-bottom: -24px !important;
}

.m-y--30 {
  margin-top: -30px !important;
  margin-bottom: -30px !important;
}

.m-y--32 {
  margin-top: -32px !important;
  margin-bottom: -32px !important;
}

.m-y--48 {
  margin-top: -48px !important;
  margin-bottom: -48px !important;
}

.m-t--2 {
  margin-top: -2px !important;
}

.m-t--4 {
  margin-top: -4px !important;
}

.m-t--6 {
  margin-top: -7px !important;
}

.m-t--8 {
  margin-top: -8px !important;
}

.m-t--10 {
  margin-top: -10px !important;
}

.m-t--12 {
  margin-top: -12px !important;
}

.m-t--15 {
  margin-top: -15px !important;
}

.m-t--16 {
  margin-top: -16px !important;
}

.m-t--20 {
  margin-top: -20px !important;
}

.m-t--24 {
  margin-top: -24px !important;
}

.m-t--30 {
  margin-top: -30px !important;
}

.m-t--32 {
  margin-top: -32px !important;
}

.m-t--48 {
  margin-top: -48px !important;
}

.m-r--2 {
  margin-right: -2px !important;
}

.m-r--4 {
  margin-right: -4px !important;
}

.m-r--6 {
  margin-right: -7px !important;
}

.m-r--8 {
  margin-right: -8px !important;
}

.m-r--10 {
  margin-right: -10px !important;
}

.m-r--12 {
  margin-right: -12px !important;
}

.m-r--15 {
  margin-right: -15px !important;
}

.m-r--16 {
  margin-right: -16px !important;
}

.m-r--20 {
  margin-right: -20px !important;
}

.m-r--24 {
  margin-right: -24px !important;
}

.m-r--30 {
  margin-right: -30px !important;
}

.m-r--32 {
  margin-right: -32px !important;
}

.m-r--48 {
  margin-right: -48px !important;
}

.m-b--2 {
  margin-bottom: -2px !important;
}

.m-b--4 {
  margin-bottom: -4px !important;
}

.m-b--6 {
  margin-bottom: -7px !important;
}

.m-b--8 {
  margin-bottom: -8px !important;
}

.m-b--10 {
  margin-bottom: -10px !important;
}

.m-b--12 {
  margin-bottom: -12px !important;
}

.m-b--15 {
  margin-bottom: -15px !important;
}

.m-b--16 {
  margin-bottom: -16px !important;
}

.m-b--20 {
  margin-bottom: -20px !important;
}

.m-b--24 {
  margin-bottom: -24px !important;
}

.m-b--30 {
  margin-bottom: -30px !important;
}

.m-b--32 {
  margin-bottom: -32px !important;
}

.m-b--48 {
  margin-bottom: -48px !important;
}

.m-l--2 {
  margin-left: -2px !important;
}

.m-l--4 {
  margin-left: -4px !important;
}

.m-l--6 {
  margin-left: -7px !important;
}

.m-l--8 {
  margin-left: -8px !important;
}

.m-l--10 {
  margin-left: -10px !important;
}

.m-l--12 {
  margin-left: -12px !important;
}

.m-l--15 {
  margin-left: -15px !important;
}

.m-l--16 {
  margin-left: -16px !important;
}

.m-l--20 {
  margin-left: -20px !important;
}

.m-l--24 {
  margin-left: -24px !important;
}

.m-l--30 {
  margin-left: -30px !important;
}

.m-l--32 {
  margin-left: -32px !important;
}

.m-l--48 {
  margin-left: -48px !important;
}

.p-0 {
  padding: 0 !important;
}

.p-2 {
  padding: 2px !important;
}

.p-4 {
  padding: 4px !important;
}

.p-6 {
  padding: 7px !important;
}

.p-8 {
  padding: 8px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-12 {
  padding: 12px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-16 {
  padding: 16px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-24 {
  padding: 24px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-32 {
  padding: 32px !important;
}

.p-48 {
  padding: 48px !important;
}

.p-x-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.p-x-2 {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.p-x-4 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.p-x-6 {
  padding-left: 7px !important;
  padding-right: 7px !important;
}

.p-x-8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.p-x-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.p-x-12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.p-x-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.p-x-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.p-x-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.p-x-24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.p-x-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.p-x-32 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.p-x-48 {
  padding-left: 48px !important;
  padding-right: 48px !important;
}

.p-y-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-y-2 {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.p-y-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.p-y-6 {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.p-y-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.p-y-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.p-y-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.p-y-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.p-y-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.p-y-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.p-y-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.p-y-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.p-y-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.p-y-48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-t-2 {
  padding-top: 2px !important;
}

.p-t-4 {
  padding-top: 4px !important;
}

.p-t-6 {
  padding-top: 7px !important;
}

.p-t-8 {
  padding-top: 8px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-t-12 {
  padding-top: 12px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.p-t-16 {
  padding-top: 16px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-t-24 {
  padding-top: 24px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.p-t-32 {
  padding-top: 32px !important;
}

.p-t-48 {
  padding-top: 48px !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.p-b-2 {
  padding-bottom: 2px !important;
}

.p-b-4 {
  padding-bottom: 4px !important;
}

.p-b-6 {
  padding-bottom: 7px !important;
}

.p-b-8 {
  padding-bottom: 8px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-b-12 {
  padding-bottom: 12px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.p-b-16 {
  padding-bottom: 16px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-b-24 {
  padding-bottom: 24px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.p-b-32 {
  padding-bottom: 32px !important;
}

.p-b-48 {
  padding-bottom: 48px !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-2 {
  gap: 2px !important;
}

.gap-4 {
  gap: 4px !important;
}

.gap-6 {
  gap: 7px !important;
}

.gap-8 {
  gap: 8px !important;
}

.gap-10 {
  gap: 10px !important;
}

.gap-12 {
  gap: 12px !important;
}

.gap-15 {
  gap: 15px !important;
}

.gap-16 {
  gap: 16px !important;
}

.gap-20 {
  gap: 20px !important;
}

.gap-24 {
  gap: 24px !important;
}

.gap-30 {
  gap: 30px !important;
}

.gap-32 {
  gap: 32px !important;
}

.gap-48 {
  gap: 48px !important;
}

.b-0 {
  border: 0 !important;
}

.b-1 {
  border: 1px solid #e5eaef !important;
}

.b-2 {
  border: 2px solid #e5eaef !important;
}

.b-4 {
  border: 4px solid #e5eaef !important;
}

.b-8 {
  border: 8px solid #e5eaef !important;
}

.b-t-0 {
  border-top: 0 !important;
}

.b-t-1 {
  border-top: 1px solid #e5eaef !important;
}

.b-t-2 {
  border-top: 2px solid #e5eaef !important;
}

.b-t-4 {
  border-top: 4px solid #e5eaef !important;
}

.b-t-8 {
  border-top: 8px solid #e5eaef !important;
}

.b-b-0 {
  border-bottom: 0 !important;
}

.b-b-1 {
  border-bottom: 1px solid #e5eaef !important;
}

.b-b-2 {
  border-bottom: 2px solid #e5eaef !important;
}

.b-b-4 {
  border-bottom: 4px solid #e5eaef !important;
}

.b-b-8 {
  border-bottom: 8px solid #e5eaef !important;
}

.b-l-0 {
  border-left: 0 !important;
}

.b-l-1 {
  border-left: 1px solid #e5eaef !important;
}

.b-l-2 {
  border-left: 2px solid #e5eaef !important;
}

.b-l-4 {
  border-left: 4px solid #e5eaef !important;
}

.b-l-8 {
  border-left: 8px solid #e5eaef !important;
}

.b-r-0 {
  border-right: 0 !important;
}

.b-r-1 {
  border-right: 1px solid #e5eaef !important;
}

.b-r-2 {
  border-right: 2px solid #e5eaef !important;
}

.b-r-4 {
  border-right: 4px solid #e5eaef !important;
}

.b-r-8 {
  border-right: 8px solid #e5eaef !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-7 {
  border-radius: 7px !important;
}

.rounded-8 {
  border-radius: 14px !important;
}

.rounded-12 {
  border-radius: 21px !important;
}

.rounded-16 {
  border-radius: 28px !important;
}

.rounded-full {
  border-radius: 9999px !important;
}

.r-t-l-0 {
  border-top-left-radius: 0 !important;
}

.r-t-l-7 {
  border-top-left-radius: 7px !important;
}

.r-t-l-8 {
  border-top-left-radius: 14px !important;
}

.r-t-l-12 {
  border-top-left-radius: 21px !important;
}

.r-t-l-16 {
  border-top-left-radius: 28px !important;
}

.r-t-l-full {
  border-top-left-radius: 9999px !important;
}

.r-t-r-0 {
  border-top-right-radius: 0 !important;
}

.r-t-r-7 {
  border-top-right-radius: 7px !important;
}

.r-t-r-8 {
  border-top-right-radius: 14px !important;
}

.r-t-r-12 {
  border-top-right-radius: 21px !important;
}

.r-t-r-16 {
  border-top-right-radius: 28px !important;
}

.r-t-r-full {
  border-top-right-radius: 9999px !important;
}

.r-b-r-0 {
  border-bottom-right-radius: 0 !important;
}

.r-b-r-7 {
  border-bottom-right-radius: 7px !important;
}

.r-b-r-8 {
  border-bottom-right-radius: 14px !important;
}

.r-b-r-12 {
  border-bottom-right-radius: 21px !important;
}

.r-b-r-16 {
  border-bottom-right-radius: 28px !important;
}

.r-b-r-full {
  border-bottom-right-radius: 9999px !important;
}

.r-b-l-0 {
  border-bottom-left-radius: 0 !important;
}

.r-b-l-7 {
  border-bottom-left-radius: 7px !important;
}

.r-b-l-8 {
  border-bottom-left-radius: 14px !important;
}

.r-b-l-12 {
  border-bottom-left-radius: 21px !important;
}

.r-b-l-16 {
  border-bottom-left-radius: 28px !important;
}

.r-b-l-full {
  border-bottom-left-radius: 9999px !important;
}

.r-t-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.r-t-7 {
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
}

.r-t-8 {
  border-top-left-radius: 14px !important;
  border-top-right-radius: 14px !important;
}

.r-t-12 {
  border-top-left-radius: 21px !important;
  border-top-right-radius: 21px !important;
}

.r-t-16 {
  border-top-left-radius: 28px !important;
  border-top-right-radius: 28px !important;
}

.r-t-full {
  border-top-left-radius: 9999px !important;
  border-top-right-radius: 9999px !important;
}

.r-b-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.r-b-7 {
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
}

.r-b-8 {
  border-bottom-left-radius: 14px !important;
  border-bottom-right-radius: 14px !important;
}

.r-b-12 {
  border-bottom-left-radius: 21px !important;
  border-bottom-right-radius: 21px !important;
}

.r-b-16 {
  border-bottom-left-radius: 28px !important;
  border-bottom-right-radius: 28px !important;
}

.r-b-full {
  border-bottom-left-radius: 9999px !important;
  border-bottom-right-radius: 9999px !important;
}

.r-l-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.r-l-7 {
  border-top-left-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
}

.r-l-8 {
  border-top-left-radius: 14px !important;
  border-bottom-left-radius: 14px !important;
}

.r-l-12 {
  border-top-left-radius: 21px !important;
  border-bottom-left-radius: 21px !important;
}

.r-l-16 {
  border-top-left-radius: 28px !important;
  border-bottom-left-radius: 28px !important;
}

.r-l-full {
  border-top-left-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
}

.r-r-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.r-r-7 {
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
}

.r-r-8 {
  border-top-right-radius: 14px !important;
  border-bottom-right-radius: 14px !important;
}

.r-r-12 {
  border-top-right-radius: 21px !important;
  border-bottom-right-radius: 21px !important;
}

.r-r-16 {
  border-top-right-radius: 28px !important;
  border-bottom-right-radius: 28px !important;
}

.r-r-full {
  border-top-right-radius: 9999px !important;
  border-bottom-right-radius: 9999px !important;
}

.f-s-0 {
  font-size: 0 !important;
}

.f-s-10 {
  font-size: 10px !important;
}

.f-s-12 {
  font-size: 12px !important;
}

.f-s-14 {
  font-size: 14px !important;
}

.f-s-15 {
  font-size: 15px !important;
}

.f-s-16 {
  font-size: 16px !important;
}

.f-s-18 {
  font-size: 18px !important;
}

.f-s-20 {
  font-size: 20px !important;
}

.f-s-24 {
  font-size: 24px !important;
}

.f-s-30 {
  font-size: 30px !important;
}

.f-w-100 {
  font-weight: 100 !important;
}

.f-w-200 {
  font-weight: 200 !important;
}

.f-w-300 {
  font-weight: 300 !important;
}

.f-w-400 {
  font-weight: 400 !important;
}

.f-w-500 {
  font-weight: 500 !important;
}

.f-w-600 {
  font-weight: 600 !important;
}

.f-w-700 {
  font-weight: 700 !important;
}

.f-w-800 {
  font-weight: 800 !important;
}

.f-w-900 {
  font-weight: 900 !important;
}

.font-italic {
  font-style: italic !important;
}

.font-normal {
  font-style: normal !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-none {
  text-decoration: none !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-line-through {
  text-decoration: line-through !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-ellipsis {
  text-overflow: ellipsis !important;
}

.text-reset {
  color: inherit !important;
}

.text-current {
  color: currentColor !important;
}

[dir=ltr] .m-r-0 {
  margin-right: 0 !important;
}

[dir=ltr] .m-r-2 {
  margin-right: 2px !important;
}

[dir=ltr] .m-r-4 {
  margin-right: 4px !important;
}

[dir=ltr] .m-r-6 {
  margin-right: 7px !important;
}

[dir=ltr] .m-r-8 {
  margin-right: 8px !important;
}

[dir=ltr] .m-r-10 {
  margin-right: 10px !important;
}

[dir=ltr] .m-r-12 {
  margin-right: 12px !important;
}

[dir=ltr] .m-r-15 {
  margin-right: 15px !important;
}

[dir=ltr] .m-r-16 {
  margin-right: 16px !important;
}

[dir=ltr] .m-r-20 {
  margin-right: 20px !important;
}

[dir=ltr] .m-r-24 {
  margin-right: 24px !important;
}

[dir=ltr] .m-r-30 {
  margin-right: 30px !important;
}

[dir=ltr] .m-r-32 {
  margin-right: 32px !important;
}

[dir=ltr] .m-r-48 {
  margin-right: 48px !important;
}

[dir=ltr] .m-r-auto {
  margin-right: auto !important;
}

[dir=ltr] .m-l-0 {
  margin-left: 0 !important;
}

[dir=ltr] .m-l-2 {
  margin-left: 2px !important;
}

[dir=ltr] .m-l-4 {
  margin-left: 4px !important;
}

[dir=ltr] .m-l-6 {
  margin-left: 7px !important;
}

[dir=ltr] .m-l-8 {
  margin-left: 8px !important;
}

[dir=ltr] .m-l-10 {
  margin-left: 10px !important;
}

[dir=ltr] .m-l-12 {
  margin-left: 12px !important;
}

[dir=ltr] .m-l-15 {
  margin-left: 15px !important;
}

[dir=ltr] .m-l-16 {
  margin-left: 16px !important;
}

[dir=ltr] .m-l-20 {
  margin-left: 20px !important;
}

[dir=ltr] .m-l-24 {
  margin-left: 24px !important;
}

[dir=ltr] .m-l-30 {
  margin-left: 30px !important;
}

[dir=ltr] .m-l-32 {
  margin-left: 32px !important;
}

[dir=ltr] .m-l-48 {
  margin-left: 48px !important;
}

[dir=ltr] .m-l-auto {
  margin-left: auto !important;
}

[dir=ltr] .p-l-0 {
  padding-left: 0 !important;
}

[dir=ltr] .p-l-2 {
  padding-left: 2px !important;
}

[dir=ltr] .p-l-4 {
  padding-left: 4px !important;
}

[dir=ltr] .p-l-6 {
  padding-left: 7px !important;
}

[dir=ltr] .p-l-8 {
  padding-left: 8px !important;
}

[dir=ltr] .p-l-10 {
  padding-left: 10px !important;
}

[dir=ltr] .p-l-12 {
  padding-left: 12px !important;
}

[dir=ltr] .p-l-15 {
  padding-left: 15px !important;
}

[dir=ltr] .p-l-16 {
  padding-left: 16px !important;
}

[dir=ltr] .p-l-20 {
  padding-left: 20px !important;
}

[dir=ltr] .p-l-24 {
  padding-left: 24px !important;
}

[dir=ltr] .p-l-30 {
  padding-left: 30px !important;
}

[dir=ltr] .p-l-32 {
  padding-left: 32px !important;
}

[dir=ltr] .p-l-48 {
  padding-left: 48px !important;
}

[dir=ltr] .p-r-0 {
  padding-right: 0 !important;
}

[dir=ltr] .p-r-2 {
  padding-right: 2px !important;
}

[dir=ltr] .p-r-4 {
  padding-right: 4px !important;
}

[dir=ltr] .p-r-6 {
  padding-right: 7px !important;
}

[dir=ltr] .p-r-8 {
  padding-right: 8px !important;
}

[dir=ltr] .p-r-10 {
  padding-right: 10px !important;
}

[dir=ltr] .p-r-12 {
  padding-right: 12px !important;
}

[dir=ltr] .p-r-15 {
  padding-right: 15px !important;
}

[dir=ltr] .p-r-16 {
  padding-right: 16px !important;
}

[dir=ltr] .p-r-20 {
  padding-right: 20px !important;
}

[dir=ltr] .p-r-24 {
  padding-right: 24px !important;
}

[dir=ltr] .p-r-30 {
  padding-right: 30px !important;
}

[dir=ltr] .p-r-32 {
  padding-right: 32px !important;
}

[dir=ltr] .p-r-48 {
  padding-right: 48px !important;
}

[dir=rtl] .m-r-0 {
  margin-left: 0 !important;
}

[dir=rtl] .m-r-2 {
  margin-left: 2px !important;
}

[dir=rtl] .m-r-4 {
  margin-left: 4px !important;
}

[dir=rtl] .m-r-6 {
  margin-left: 7px !important;
}

[dir=rtl] .m-r-8 {
  margin-left: 8px !important;
}

[dir=rtl] .m-r-10 {
  margin-left: 10px !important;
}

[dir=rtl] .m-r-12 {
  margin-left: 12px !important;
}

[dir=rtl] .m-r-15 {
  margin-left: 15px !important;
}

[dir=rtl] .m-r-16 {
  margin-left: 16px !important;
}

[dir=rtl] .m-r-20 {
  margin-left: 20px !important;
}

[dir=rtl] .m-r-24 {
  margin-left: 24px !important;
}

[dir=rtl] .m-r-30 {
  margin-left: 30px !important;
}

[dir=rtl] .m-r-32 {
  margin-left: 32px !important;
}

[dir=rtl] .m-r-48 {
  margin-left: 48px !important;
}

[dir=rtl] .m-r-auto {
  margin-left: auto !important;
}

[dir=rtl] .m-l-0 {
  margin-right: 0 !important;
}

[dir=rtl] .m-l-2 {
  margin-right: 2px !important;
}

[dir=rtl] .m-l-4 {
  margin-right: 4px !important;
}

[dir=rtl] .m-l-6 {
  margin-right: 7px !important;
}

[dir=rtl] .m-l-8 {
  margin-right: 8px !important;
}

[dir=rtl] .m-l-10 {
  margin-right: 10px !important;
}

[dir=rtl] .m-l-12 {
  margin-right: 12px !important;
}

[dir=rtl] .m-l-15 {
  margin-right: 15px !important;
}

[dir=rtl] .m-l-16 {
  margin-right: 16px !important;
}

[dir=rtl] .m-l-20 {
  margin-right: 20px !important;
}

[dir=rtl] .m-l-24 {
  margin-right: 24px !important;
}

[dir=rtl] .m-l-30 {
  margin-right: 30px !important;
}

[dir=rtl] .m-l-32 {
  margin-right: 32px !important;
}

[dir=rtl] .m-l-48 {
  margin-right: 48px !important;
}

[dir=rtl] .m-l-auto {
  margin-right: auto !important;
}

[dir=rtl] .p-l-0 {
  padding-right: 0 !important;
}

[dir=rtl] .p-l-2 {
  padding-right: 2px !important;
}

[dir=rtl] .p-l-4 {
  padding-right: 4px !important;
}

[dir=rtl] .p-l-6 {
  padding-right: 7px !important;
}

[dir=rtl] .p-l-8 {
  padding-right: 8px !important;
}

[dir=rtl] .p-l-10 {
  padding-right: 10px !important;
}

[dir=rtl] .p-l-12 {
  padding-right: 12px !important;
}

[dir=rtl] .p-l-15 {
  padding-right: 15px !important;
}

[dir=rtl] .p-l-16 {
  padding-right: 16px !important;
}

[dir=rtl] .p-l-20 {
  padding-right: 20px !important;
}

[dir=rtl] .p-l-24 {
  padding-right: 24px !important;
}

[dir=rtl] .p-l-30 {
  padding-right: 30px !important;
}

[dir=rtl] .p-l-32 {
  padding-right: 32px !important;
}

[dir=rtl] .p-l-48 {
  padding-right: 48px !important;
}

[dir=rtl] .p-r-0 {
  padding-left: 0 !important;
}

[dir=rtl] .p-r-2 {
  padding-left: 2px !important;
}

[dir=rtl] .p-r-4 {
  padding-left: 4px !important;
}

[dir=rtl] .p-r-6 {
  padding-left: 7px !important;
}

[dir=rtl] .p-r-8 {
  padding-left: 8px !important;
}

[dir=rtl] .p-r-10 {
  padding-left: 10px !important;
}

[dir=rtl] .p-r-12 {
  padding-left: 12px !important;
}

[dir=rtl] .p-r-15 {
  padding-left: 15px !important;
}

[dir=rtl] .p-r-16 {
  padding-left: 16px !important;
}

[dir=rtl] .p-r-20 {
  padding-left: 20px !important;
}

[dir=rtl] .p-r-24 {
  padding-left: 24px !important;
}

[dir=rtl] .p-r-30 {
  padding-left: 30px !important;
}

[dir=rtl] .p-r-32 {
  padding-left: 32px !important;
}

[dir=rtl] .p-r-48 {
  padding-left: 48px !important;
}

.sidebarNav-horizontal .childBox {
  background: #ffffff;
}
.sidebarNav-horizontal .horizontal-topbar {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 9px 17.5px;
}
.sidebarNav-horizontal .horizontal-topbar .branding {
  padding-left: 0;
}
.sidebarNav-horizontal .horizontal-topbar .container {
  max-width: 100%;
  display: flex;
  align-items: center;
  width: 100%;
}
.sidebarNav-horizontal .horizontal-navbar {
  position: relative;
  gap: 3px !important;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox {
  position: relative;
  z-index: 5;
  margin: 5px 0;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox.mega-menu {
  position: static;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox.mega-menu:hover > .childBox > .ddmenu {
  display: inline-block;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox.mega-menu > .childBox {
  width: 100%;
  left: 0;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox.mega-menu > .childBox > .ddmenu {
  width: 24%;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox.two-column:hover > .childBox > .ddmenu {
  display: inline-block;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox.two-column > .childBox {
  width: 600px;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox.two-column > .childBox > .ddmenu {
  width: 49%;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox .menuLink {
  padding: 10px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  height: 40px;
  gap: 10px;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox:hover > .menuLink {
  background-color: rgba(0, 0, 0, 0.025);
}
.sidebarNav-horizontal .horizontal-navbar .parentBox:hover > .activeMenu,
.sidebarNav-horizontal .horizontal-navbar .parentBox .activeMenu {
  color: #ffffff;
  background-color: #5d87ff;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox .down-icon .mat-icon {
  width: 18px;
  height: 18px;
  font-size: 18px;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox .childBox {
  border-radius: 7px;
  box-shadow: rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  position: absolute;
  width: 250px;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox .childBox .ddmenu {
  display: none;
  padding: 10px;
  margin: 10px;
  position: relative;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox:hover > .childBox > .ddmenu:hover {
  background-color: rgba(0, 0, 0, 0.025);
}
.sidebarNav-horizontal .horizontal-navbar .parentBox:hover > .childBox > .ddmenu:hover:hover > .childBox > .ddmenu:hover {
  background-color: rgba(0, 0, 0, 0.025);
}
.sidebarNav-horizontal .horizontal-navbar .parentBox:hover > .childBox > .ddmenu:hover > .childBox {
  left: 230px;
  top: 0px;
  z-index: 9;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox:hover > .childBox > .ddmenu:hover > .childBox > .ddmenu:hover > .childBox {
  left: 235px;
  top: 0;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox:hover > .childBox > .ddmenu {
  display: block;
  padding: 0;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox:hover > .childBox > .ddmenu:hover > .childBox > .ddmenu {
  display: block;
  padding: 0;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox:hover > .childBox > .ddmenu:hover > .childBox > .ddmenu:hover > .childBox > .ddmenu {
  display: block;
  padding: 0;
}

.sidebarNav-horizontal .topbar,
.sidebarNav-horizontal .mainWrapper {
  width: 100%;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox.pactive > a, .sidebarNav-horizontal .horizontal-navbar .parentBox.pactive > a:hover, .sidebarNav-horizontal .horizontal-navbar .parentBox.pactive:hover > a {
  color: white !important;
  border-radius: 7px;
}

.ltr .sidebarNav-horizontal .horizontal-navbar .parentBox:last-child:hover > .childBox > .ddmenu:hover > .childBox {
  right: 250px;
  left: unset;
}
.ltr .sidebarNav-horizontal .horizontal-navbar .parentBox:last-child:hover > .childBox > .ddmenu:hover > .childBox > .ddmenu:hover > .childBox {
  right: 250px;
  left: unset;
}

.rtl .sidebarNav-horizontal .horizontal-navbar .parentBox:last-child:hover > .childBox > .ddmenu:hover > .childBox {
  left: 250px;
  right: unset;
}
.rtl .sidebarNav-horizontal .horizontal-navbar .parentBox:last-child:hover > .childBox > .ddmenu:hover > .childBox > .ddmenu:hover > .childBox {
  left: 250px;
  right: unset;
}

.size-image-menu-sidebar {
  max-width: 100px;
  margin-left: 10px;
}

.space-image {
  margin-left: 20px;
}

.drowpdown-state-user .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  width: 150px;
  background-color: white !important;
}

.drowpdown-state-user.mat-mdc-form-field:hover .drowpdown-state-user.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.95);
}

.cal-event-action {
  text-decoration: none;
  margin-left: 5px;
  color: #ffffff;
}

.cal-month-view {
  background-color: transparent !important;
}

.cal-month-view .cal-open-day-events {
  background-color: #333f55 !important;
}

.cal-month-view .cal-day-badge {
  background-color: #5d87ff !important;
}

.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
  color: #5d87ff !important;
}

.max-text {
  max-width: 250px;
  line-height: 1.57;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ngx-pagination {
  margin-bottom: 15px !important;
}
.ngx-pagination .current {
  background: #5d87ff !important;
  border-radius: 7px;
}
.ngx-pagination a {
  border-radius: 7px !important;
}
.ngx-pagination button {
  border-radius: 7px !important;
}

.social-chips img {
  margin-left: -9px;
  border: 2px solid #ffffff;
}
.social-chips img:first-child {
  margin-left: 0;
}

.minus-img {
  margin-bottom: -65px !important;
}

.theme-select {
  width: 125px;
  height: 36px;
}
.theme-select .mat-form-field-infix {
  padding: 6px 0 1px !important;
}
.theme-select .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.theme-select .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-color: #e5eaef;
}
.theme-select .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  border-color: #e5eaef;
}
.theme-select .mat-mdc-form-field-flex {
  height: 36px;
  align-items: center;
}

.welcome-img {
  margin-bottom: -65px;
  margin-top: -15px;
}

.timeline {
  position: relative;
}
.timeline .timeline-item {
  position: relative;
  height: 70px;
}
.timeline .timeline-item .time {
  padding: 6px 16px 6px 0;
  min-width: 90px;
  flex-shrink: 0;
}
.timeline .timeline-item .desc {
  padding: 6px 16px;
}
.timeline .timeline-item .timline-border {
  width: 1px;
  height: 100%;
  background-color: #e5eaef;
  flex-shrink: 0;
}
.timeline .timeline-item .point {
  flex-direction: column;
}
.timeline .timeline-item .point .timeline-badge {
  width: 12px;
  height: 12px;
  border-radius: 50px;
  background-color: transparent;
  flex-shrink: 0;
}
.timeline .timeline-item .point .timeline-badge.border-primary {
  border: 2px solid #5d87ff;
}
.timeline .timeline-item .point .timeline-badge.border-accent {
  border: 2px solid #49beff;
}
.timeline .timeline-item .point .timeline-badge.border-success {
  border: 2px solid #13deb9;
}
.timeline .timeline-item .point .timeline-badge.border-warning {
  border: 2px solid #ffae1f;
}
.timeline .timeline-item .point .timeline-badge.border-error {
  border: 2px solid #fa896b;
}
.timeline .timeline-item .point .timeline-border {
  width: 1px;
  height: 100%;
  background-color: #e5eaef;
  flex-shrink: 0;
}
.timeline .timeline-item:last-child .timeline-border {
  display: none !important;
}

.most-visit-chart .apexcharts-bar-series.apexcharts-plot-series .apexcharts-series path {
  clip-path: inset(0 0 5% 0 round 20px);
}

.blank-layout-container {
  height: 100vh;
  display: flex;
  background-size: cover;
  align-items: center;
}

.auth-title {
  font-size: 36px;
  line-height: 44px;
}

.bg-gredient::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.9;
  background-image: url("../../images/backgrounds/background-login.jpg");
  animation: 80s ease 0s infinite normal none running gradient;
  z-index: -1;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 50% 0%;
  }
}
.img-height {
  height: calc(100vh - 83px);
}

.custom-row.row {
  margin-right: -8px;
  margin-left: -8px;
}
.custom-row.row .col-2,
.custom-row.row .col-12 {
  padding-right: 8px;
  padding-left: 8px;
}

.or-border {
  position: relative;
  text-align: center;
}
.or-border:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100px;
  top: 50%;
  height: 1px;
  background: #e5eaef;
}
.or-border:after {
  content: "";
  position: absolute;
  right: 0;
  width: 100px;
  top: 50%;
  height: 1px;
  background: #e5eaef;
}

.boxed-auth {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
}

.max-width-form-size-lg {
  max-width: 450px;
}

.max-width-form-size-md {
  max-width: 450px;
  height: 200px;
}

.mat-mdc-form-field.mat-form-field-invalid .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-form-field-invalid .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-form-field-invalid .mdc-notched-outline__trailing {
  border-color: #fa896b !important;
}

.error-msg {
  position: absolute;
  bottom: -19px;
  left: -10px;
  top: 60px;
  margin-bottom: 10px;
  width: fit-content;
}

.container {
  max-width: 1200px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
}
.container.full-width {
  display: flex;
  align-items: center;
  width: 100%;
}

.demos-dd {
  max-width: 1200px !important;
}

.img-overlay {
  overflow: auto;
  position: relative;
}
.img-overlay .overlay-content {
  display: none;
}
.img-overlay:hover:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 8;
  background-color: rgba(55, 114, 255, 0.2);
}
.img-overlay:hover .overlay-content {
  display: block;
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  right: 50%;
  top: 50%;
  min-width: 115px;
  z-index: 9;
}

.topbar-xl {
  height: 80px;
  z-index: 1;
}

@media (min-width: 900px) {
  .banner-title {
    font-size: 54px !important;
    line-height: 60px !important;
  }
}
@media (max-width: 1200px) {
  .banner-section {
    padding-top: 60px;
  }
}
.banner-title {
  font-size: 36px;
  line-height: 44px;
}

.banner-subtitle {
  line-height: 25px;
}

.btn-custom {
  height: 56px !important;
  padding: 0 52px !important;
  font-size: 16px;
}
.btn-custom.sm {
  padding: 0 34px !important;
}

@media (max-width: 767px) {
  .btn-custom {
    padding: 0 36px !important;
  }
}
.banner-slider {
  background-color: #ecf2ff;
  min-width: 2000px;
  height: calc(100vh - 100px);
  max-height: 790px;
  border-radius: 7px;
  overflow: hidden;
}

.slider-img {
  animation: slideup 35s linear infinite;
}

.slider-img2 {
  animation: slidedown 35s linear infinite;
}

@keyframes slideup {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0px, -100%, 0px);
  }
}
@keyframes slidedown {
  0% {
    transform: translate3d(0, -100%, 0);
  }
  100% {
    transform: translate3d(0px, 0, 0px);
  }
}
.spacer {
  padding: 80px 0 100px;
}

.section-title {
  font-size: 28px;
  line-height: 32px;
}

@media (min-width: 991px) {
  .section-title {
    font-size: 36px;
    line-height: 43px;
  }
}
.bg-extra-light {
  background-color: #f6f9fc;
}

.demo-slider {
  margin-top: 40px;
}
.demo-slider .demo-slide {
  animation: slide 45s linear infinite;
}

@keyframes slide {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
.lh-base {
  line-height: 1.75;
}

.shape-card {
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
}

.spacer-sm {
  padding-top: 60px;
  padding-bottom: 30px;
}

.spacer-bottom {
  padding-bottom: 60px;
}

.section-title2 {
  font-size: 30px;
  line-height: 36px;
  font-weight: 700;
}

.c2a-card {
  margin-top: -70px;
  background-repeat: no-repeat;
  background-position: center;
}

.border-white {
  border: 1px solid #ffffff !important;
}

.border-accent {
  border: 1px solid #49beff !important;
}

.border-primary {
  border: 1px solid #5d87ff !important;
}

.hstack {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

@media (max-width: 767px) {
  .hstack {
    flex-direction: column;
  }
  .bg-size-cover {
    background-size: cover;
  }
}
[dir=rtl] .upgrade-bg {
  left: 0;
  right: unset;
  transform: scaleX(-1);
}
[dir=rtl] .customizerBtn {
  left: 30px;
  right: unset;
}
[dir=rtl] .branding img {
  transform: scaleX(-1);
}
[dir=rtl] .breadcrumb-icon {
  left: 19px;
  right: unset;
}
[dir=rtl] .breadcrumb-item:first-child {
  margin-left: 0;
  margin-right: -20px;
}
[dir=rtl] .breadcrumb-item.active {
  margin-left: 0;
  margin-right: 20px;
}
[dir=rtl] .sidebar-list.mdc-list .menu-list-item .mdc-list-item__start {
  margin-right: 0 !important;
  margin-left: 14px !important;
}
[dir=rtl].sidebarNav-mini .contentWrapper {
  margin-right: 80px !important;
  margin-left: 0 !important;
  transition: width 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
}
[dir=rtl].sidebarNav-mini .sidebarNav .sidebar-list .menu-list-item .mdc-list-item__start {
  margin-right: 8px !important;
  margin-left: 7px !important;
}
[dir=rtl].sidebarNav-mini .sidebarNav:hover .sidebar-list .menu-list-item .mdc-list-item__start {
  margin-right: 0 !important;
  margin-left: 16px !important;
}