.breadcrumb-icon {
  position: absolute;
  top: -20px;
  right: 19px;
}

.breadcrumb-item {
  &:first-child {
    margin-left: -20px;
  }
  &.active {
    margin-left: 20px;
  }
  a {
    text-decoration: none;
    color: $textPrimary;
    font-size: 14px;
  }
}

.cursor-pointer{
  cursor: pointer;
}
