/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


// .successSnackBar.mat-mdc-snack-bar-container .mdc-snackbar__surface{
//     background-color: #389b9e!important;
//     margin-top: 150px;

//   }
  
// .successSnackBar.mat-mdc-snack-bar-container {
//     --mat-mdc-snack-bar-button-color: #fdfdfd!important;
//     --mdc-snackbar-container-color: #333333;
//     --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
// }

// .errorSnackBar.mat-mdc-snack-bar-container .mdc-snackbar__surface{
//     background-color: #9d1e43!important;
//     margin-top: 150px;

// }

// .errorSnackBar.mat-mdc-snack-bar-container {
//     --mat-mdc-snack-bar-button-color: #9d1e43!important;
//     --mdc-snackbar-container-color: #333333;
//     --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
// }